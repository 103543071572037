import {NeptingPaymentMethodObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import React, {useMemo, useState, useEffect} from 'react';
import {useLocation} from 'react-router';

import mastercard from 'src/assets/images/icons/mastercard-icon.svg';
import universal from 'src/assets/images/icons/universal-icon.svg';
import visa from 'src/assets/images/icons/visa-icon.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {DeletePaymentMethodModal} from 'src/components/Modal/CustomModals/DeletePaymentMethodModal';
import {useImage} from 'src/hooks/image.hook';

interface SavedPaymentMethodProps {
	item: NeptingPaymentMethodObject;
	checked?: boolean;
	onChange?: () => void;
	deleteCard: () => void;
}

export const SavedPaymentMethod = observer((props: SavedPaymentMethodProps) => {
	const {item, onChange, deleteCard, checked} = props;
	const {translate} = useTranslate();
	const location = useLocation();
	const validityDate = moment(new Date(item.endOfValidity)).format('MM/YY');
	const [deleteCardModalIsOpen, setDeleteCardModalIsOpen] = useState<boolean>(false);
	const [cardImage, setCardImage] = useState(universal);
	const cardType = item.maskedPan.split('')[0];

	useEffect(() => {
		if (cardType === '5') {
			setCardImage(mastercard);
		} else if (cardType === '4') {
			setCardImage(visa);
		} else {
			setCardImage(universal);
		}
	}, [cardType]);

	const {image} = useImage(cardImage);

	const isProfilePage = useMemo(() => {
		return location.pathname === '/profile';
	}, [location]);

	return (
		<div className={`payment-method${checked ? ' checked' : ''}`}>
			<div className="payment-method__container">
				<figure className="payment-method__figure">
					<img src={image} alt="card" className="payment-method__img" />
				</figure>
				<div className="payment-method__content">
					<p className="payment-method__description txt-bold txt-s">{'**** **** **** ' + item.maskedPan.slice(-4)}</p>
					<p className="payment-method__description txt-s">
						{translate('PaymentMethod.validity')}
						{validityDate}
					</p>
					{!isProfilePage && (
						<div>
							<label className="payment-method__label txt-bold txt-s">
								{translate('Form.radio_button')}
							</label>
							<input
								type="radio"
								checked={checked}
								name="choose-card"
								onChange={onChange}
								className={`radio-button${checked ? ' checked' : ''}`}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="payment-method__top">
				<button
					className="btn-round__secondary basket-item__delete"
					theme-icon=""
					type="button"
					onClick={() => setDeleteCardModalIsOpen(true)}
				/>
			</div>
			<DeletePaymentMethodModal
				open={deleteCardModalIsOpen}
				onClose={() => setDeleteCardModalIsOpen(false)}
				deleteCard={deleteCard}
			/>
		</div>
	);
});
