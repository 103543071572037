import { observer } from 'mobx-react-lite';
import React, {useMemo} from 'react';

import { useStore } from 'src/contexts/store.context';

import { BasketEmpty } from './BasketEmpty';
import { BasketFooter } from './BasketFooter';
import {BasketHeader} from './BasketHeader'
import { BasketList } from './BasketList';


export const Basket = observer(() => {
  const {basket: {isBasketEmpty}} = useStore();

  const basketContent = useMemo(() => {
    return isBasketEmpty ? <BasketEmpty /> : <BasketList />
  }, [isBasketEmpty])

  return (
    <div className="basket">
      <div>
        <BasketHeader />
        {basketContent}
      </div>
      {!isBasketEmpty && <div>
        <BasketFooter />
      </div>}
    </div>
  );
})
