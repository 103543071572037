import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';
import {useStore} from 'src/contexts/store.context';
import {useImage} from 'src/hooks/image.hook';
import {formatAddress} from 'src/utils/format-address';

export const CurrentRestaurantModal = observer(({open, onClose}: CustomModalProps) => {
	const {translate} = useTranslate();

	const {
		restaurant: {currentRestaurant, isUniqueRestaurant},
	} = useStore();

	const {image} = useImage(currentRestaurant?.previewUrl);

	const modalLink = useMemo(() => {
		return isUniqueRestaurant ? (
			<Link
				className="current-restaurant--link"
				to={`/restaurants?openInfo=${currentRestaurant?.id}`}
				onClick={onClose}>
				<button className="home-main__btn--full btn__secondary">
					{translate(`CurrentRestaurant.btn_details`)}
				</button>
			</Link>
		) : (
			<Link className="current-restaurant--link" to="/restaurants" onClick={onClose}>
				<button className="home-main__btn--full btn__secondary">
					{translate(`CurrentRestaurant.btn_change`)}
				</button>
			</Link>
		);
	}, [currentRestaurant, isUniqueRestaurant, translate, onClose]);

	return (
		<Modal open={open} onClose={onClose}>
			{currentRestaurant ? (
				<div className="current-restaurant">
					<div className="close" onClick={onClose}></div>
					<div className="current-restaurant__wrap-img">
						<img
							src={image}
							alt={currentRestaurant.name}
							className="current-restaurant__wrap-img__image"
						/>
					</div>
					<div className="current-restaurant__paragraph">
						<p className="current-restaurant__paragraph--text txt-m txt-bold">
							{currentRestaurant.name}
						</p>
						<p className="current-restaurant__paragraph--text txt-s">
							{formatAddress(currentRestaurant.address)}
						</p>
					</div>
					{modalLink}
				</div>
			) : (
				<div className="current-restaurant unselected">
					<p className=".unselected--text txt-bold txt-center">
						{translate(`CurrentRestaurant.no_restaurant`)}
					</p>
					<Link className="current-restaurant--link" to="/restaurants" onClick={onClose}>
						<button className="home-main__btn--full btn__secondary">
							{translate(`CurrentRestaurant.btn_choose`)}
						</button>
					</Link>
				</div>
			)}
		</Modal>
	);
});
