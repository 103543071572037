import { OrderItemObject } from "@acrelec-cloud/apico-sdk";

import { useTranslate } from "src/components/Languages/translate.hook";

export const usePrettifyOrderProducts = (
  product: OrderItemObject
): { productItemList: string[] } => {
  const { translateProduct } = useTranslate();
  const productItemList: string[] = [];

  const getProduct = (itemList: OrderItemObject[]) =>
    itemList.forEach((child: OrderItemObject) => {
      const product = child.productPart?.product || child.productSnapshot;
      const defaultQuantity = child.productPart?.defaultQuantity || 0;
      const modifiedItem = product && child.quantity !== defaultQuantity;

      if (modifiedItem) {
        const quantity = child.quantity === 0 ? "⊘" : child.quantity;
        const productName = translateProduct(child.productSnapshot).name;

        productItemList.push(`${quantity} ${productName}`);
      }

      const itemHasChildren = child.children.length > 0;
      const itemIsSelected = child.quantity > 0;

      itemHasChildren && itemIsSelected && getProduct(child.children);
    });

  getProduct(product.children);

  return { productItemList };
};
