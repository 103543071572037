import {
  CustomizationStoreType,
  RequiredPartsType
} from '@acrelec-cloud/apico-cdk';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslate } from 'src/components/Languages/translate.hook';
import { ComponentNameTypes, PopoverTypes } from 'src/components/Popover/custom-popovers.config';
import { usePopover } from 'src/components/Popover/popover.hook';
import {
  BubbleMessage,
  useBubbleMessage,
} from 'src/components/Products/AddToCart/bubble-message.hook';
import { useStore } from 'src/contexts/store.context';
import { useMemoCompare } from 'src/hooks/memo-compare.hook';
import { useOpeningHours } from 'src/hooks/opening-hours.hook';
import { rootStore } from 'src/stores/root.store';

export enum ActionType {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
};

export interface AddToCartButtonProps {
  code: number;
  quantity: number;
  wide?: boolean;
  action: ActionType;
  basketId?: string | null;
  partsRequired: RequiredPartsModel;
}

export const AddToCartButton = observer((props: AddToCartButtonProps) => {
  const { wide, action, basketId, partsRequired } = props;
  const { translate } = useTranslate();
  const [disabled, setDisabled] = useState<boolean>(true);
  const { openPopover } = usePopover();
  const { isOpen } = useOpeningHours();

  const {
    customization: { currentProduct, getFinalPrice },
    basket: { addBasketProduct, updateProduct },
    restaurant: { orderingDisabled, currentRestaurant },
  } = useStore();

  const productPrice = getFinalPrice()
    ? getFinalPrice()
    : currentProduct && currentProduct.price
      ? currentProduct.price.defaultUnitPrice
      : 0;


  const restaurantIsOpen = useMemo(
    () => currentRestaurant && isOpen(currentRestaurant),
    [currentRestaurant, isOpen],
  );

  const requiredParts = useCallback(
    () => partsRequired.filter((part: RequiredPartsType) => part.partRequired),
    [partsRequired],
  );

  const { warningType, translatedMessage, bubbleMessage, setBubbleMessage } = useBubbleMessage({
    restaurantIsOpen,
    orderingDisabled,
    hasRequiredParts: requiredParts().length > 0,
  });

  const comparedRequired = useMemoCompare(
    () => toJS(partsRequired),
    partsRequired,
    (a: RequiredPartsType[], b: RequiredPartsType[]) =>
      a.length === b.length &&
      a.every((item: RequiredPartsType, index: number) => {
        const otherItem = b[index];
        return item.qty === otherItem.qty;
      }),
  );

  useEffect(() => {
    setDisabled(true);
    if (!restaurantIsOpen) {
      setDisabled(true);
    } else {
      setDisabled(requiredParts().length > 0 ? true : false);
    }
  }, [
    comparedRequired,
    setDisabled,
    orderingDisabled,
    currentRestaurant,
    restaurantIsOpen,
    requiredParts,
  ]);

  useEffect(() => {
    if (orderingDisabled) {
      setDisabled(true);
    }
  }, [setDisabled, orderingDisabled]);

  const onAdd = useCallback(() => {
    const currentCustomization: CustomizationStoreType = getSnapshot(rootStore['customization']);
    window.fbq('track', 'AddToCart', {
      currency: "GBP",
      value: productPrice,
      content_ids: currentCustomization.currentProduct?.code,
      content_name: currentCustomization.currentProduct?.name,
      quantity: currentCustomization.quantity,
      restaurantId: currentRestaurant?.id
    });
    if (action === ActionType.ADD) {
      const maxQtyProduct = 99999; // Unlimited quantity
      addBasketProduct(currentCustomization, maxQtyProduct)
    } else {
      updateProduct(basketId, currentCustomization);
    }
    openPopover(PopoverTypes.RIGHT, ComponentNameTypes.Basket);
  }, [action, addBasketProduct, basketId, openPopover, updateProduct, productPrice, currentRestaurant]);

  return (
    <div
      className="add-cart"
      {...(disabled && {
        onMouseEnter: () => setBubbleMessage(warningType()),
        onMouseLeave: () => setBubbleMessage(BubbleMessage.NULL),
      })}>
      {disabled ? (
        <>
          <div
            className={`add-cart__button add-cart__button--disabled btn__primary btn__primary--icon-before btn__primary--icon-fas${!wide ? ' btn-padding' : ''
              }`}
            theme-icon="">
            {translate(`App.${action === ActionType.ADD ? 'add_cart_btn' : 'edit_cart_btn'}`)}
          </div>
          {!!bubbleMessage && (
            <div className="add-cart__warning">{translatedMessage[bubbleMessage]}</div>
          )}
        </>
      ) : (
        <button
          className={`add-cart__button btn__primary btn__primary--icon-before btn__primary--icon-fas${!wide ? ' btn-padding' : ''
            }`}
          theme-icon=""
          disabled={disabled}
          onClick={() => onAdd()}>
          {translate(`App.${action === ActionType.ADD ? 'add_cart_btn' : 'edit_cart_btn'}`)}
        </button>
      )}
    </div>
  );
});
