import {OrderObject} from '@acrelec-cloud/apico-shared';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {RatingStar} from 'src/components/Order/RatingStar';
import {useStore} from 'src/contexts/store.context';

interface OrderRatingProps {
	order: OrderObject;
	setIsRatingSuccess?: () => void;
	isInModal?: boolean;
	onSetLastRating?: any;
	lastRating?: number;
}

export const OrderRating = observer((props: OrderRatingProps) => {
	const {order, setIsRatingSuccess, isInModal, onSetLastRating, lastRating} = props;
	const {translate} = useTranslate();
	const [rating, setRating] = useState(0);
	const [hoverRating, setHoverRating] = useState(0);
	const history = useHistory();

	const {
		order: {rateOrder},
	} = useStore();

	const onMouseEnter = (index: React.SetStateAction<number>) => {
		setHoverRating(index);
	};

	const onMouseLeave = () => {
		setHoverRating(0);
	};

	const onSaveRating = (index: number) => {
		setRating(index);
		rateOrder(order.id, index).then(() => {
			if (setIsRatingSuccess) {
				if (onSetLastRating) {
					onSetLastRating(index);
				}
				setIsRatingSuccess();
			}
		});
	};

	//TODO find a better way to check rating changes than with lastRating props

	return (
		<>
			<div className={isInModal ? 'order-container__rating--modal' : 'order-container__rating'}>
				<div className="order-container__rating--content">
					{!isInModal && <p className="txt-xs">{translate('OrderRating.message')}</p>}

					<div className="order-container__stars">
						{[1, 2, 3, 4, 5].map((index) => {
							return (
								<RatingStar
									order={order}
									index={index}
									key={index}
									rating={rating}
									hoverRating={hoverRating}
									onMouseEnter={onMouseEnter}
									onMouseLeave={onMouseLeave}
									onSaveRating={onSaveRating}
									lastRating={lastRating}
								/>
							);
						})}
					</div>
					{rating ? (
						<p className="order-rate__msg__apply">{translate('OrderRating.success')}</p>
					) : null}
				</div>

				{!isInModal && !order.reportedAt && (
					<button
						className="order-container__button--problem txt-xs txt-black"
						onClick={() => history.push(`/report-problem/${order.id}`)}>
						{translate('OrderRating.signal')}
					</button>
				)}
				{!isInModal && order.reportedAt && (
					<p className="order-container__reported--desktop">
						{translate('OrderRating.report_sent')}
					</p>
				)}
			</div>

			{!isInModal && !order.reportedAt && (
				<div className="order-container__rating--problem-mobile">
					<button
						className="order-container__button txt-xs txt-black"
						onClick={() => history.push(`/report-problem/${order.id}`)}>
						{translate('OrderRating.signal')}
					</button>
				</div>
			)}

			{!isInModal && order.reportedAt && (
				<div className="order-container__rating--problem-mobile">
					<p className="order-container__reported">{translate('OrderRating.report_sent')}</p>
				</div>
			)}
		</>
	);
});
