import React from 'react';
import {isMobile} from 'react-device-detect';

import {CategoryNavigationDesktop} from 'src/components/Products/Navigation/CategoryNavigationDesktop/CategoryNavigationDesktop';
import {CategoryNavigationMobile} from 'src/components/Products/Navigation/CategoryNavigationMobile/CategoryNavigationMobile';

export const useCategoryNavigation = () => {
	const categoryNavigation = isMobile ? <CategoryNavigationMobile /> : <CategoryNavigationDesktop />;
	return categoryNavigation;
};
