import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';

interface ErrorModalProps {
  open: boolean;
  onClose: () => void;
  errorText: string;
}

export const ErrorTryAgainModal = observer((props: ErrorModalProps) => {
  const {open, onClose, errorText} = props;
	const {translate} = useTranslate();

	return (
		<Modal open={open} onClose={onClose}>
			<div className="profile profile--error">
				<p className="profile__title txt-error">{translate(`Profile.title.problem`)}</p>
				<div className="profile__not-valid"></div>
				<p>{errorText}</p>
			</div>
		</Modal>
	);
});
