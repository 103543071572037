import { DateTime } from "luxon";

import { useTranslate } from "src/components/Languages/translate.hook";
import { useStore } from "src/contexts/store.context";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const date = new Date();

interface UseEasyDateHour {
  currentDayName: string;
  convertHourFromString: (time: string | number) => string | number;
  formatHours: (format: "FROM-TO" | "DASH", value: string) => string;
  defaultDateHour: (date: Date, restaurantTimeZone: string) => string;
  defaultHour: (hour: string, restaurantTimeZone: string) => string;
}

export const useEasyDateHour = (): UseEasyDateHour => {
  const currentDayName = days[date.getDay()].toUpperCase();
  const { translate } = useTranslate();

  const {
    language: { currentLanguage },
  } = useStore();

  /**
   * Convert 24h time to 12h (am/pm) format
   * @param time string | number
   * @returns
   */
  const convertHourFromString = (time: string | number) => {
    // Check correct time format and split it

    if (currentLanguage === "fr") {
      return time;
    }
    let splitedTime = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    const correctFormat = splitedTime.length > 1;

    if (correctFormat) {
      splitedTime = splitedTime.slice(1); // Remove full string match value
      splitedTime[5] = +splitedTime[0] < 12 ? " am" : " pm"; // Add am/pm
      splitedTime[0] = +splitedTime[0] % 12 || 12; // Convert 24h to 12h

      return splitedTime.join("");
    }
    return time;
  };

  const formatHours = (format: "FROM-TO" | "DASH", value: string): string => {
    const hours = value.split("|");
    const openingTime = convertHourFromString(hours[0]);
    const closingTime = convertHourFromString(hours[1]);

    if (format === "DASH") return `${openingTime} - ${closingTime}`;
    if (format === "FROM-TO")
      return `${translate("RestaurantsPage.from")} ${openingTime} ${translate(
        "RestaurantsPage.to"
      )} ${closingTime}`;
    return "Not found";
  };

  const defaultDateHour = (date: Date, restaurantTimeZone = 'Europe/Paris'): string =>
    date.toLocaleTimeString(currentLanguage, {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: restaurantTimeZone,
    });
    
  const defaultHour = (date: string, timezone ?: string): string => {
    const zoneName = timezone ? timezone : DateTime.local().zoneName
    return String(convertHourFromString(DateTime.fromISO(date).setZone(zoneName).toFormat('T'))).toUpperCase();
  }


  return {
    currentDayName,
    convertHourFromString,
    formatHours,
    defaultDateHour,
    defaultHour,
  };
};
