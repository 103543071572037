import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useForm} from 'react-hook-form';

import {emailPattern, phonePattern, stringPattern} from 'src/components/Form/validator-form.config';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {LoadingSpinner} from 'src/components/LoadingSpinner/LoadingSpinner';
import {ConfirmMessageSentModal} from 'src/components/Modal/CustomModals/ConfirmMessageSentModal';
import {useStore} from 'src/contexts/store.context';

export interface StudySurveyFormData {
	name: string;
	email: string;
	phoneNumber: string;
	subject: string;
	message: string;
}

interface StudySurveyFormProps {
	isReportProblem?: boolean;
	orderId: string;
}

export const StudySurveyForm = observer(({isReportProblem, orderId}: StudySurveyFormProps) => {
	const {translate} = useTranslate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [agree, setAgree] = useState<boolean>(false);

	const {
		auth: {profile},
		order: {sendFeedback},
	} = useStore();

  const {executeRecaptcha} = useGoogleReCaptcha();

	const {errors, register, formState, handleSubmit} = useForm({
		mode: 'onChange',
	});
  
	const onSubmit = async (data: StudySurveyFormData) => {
    if (!executeRecaptcha) return;
    const {name, email, phoneNumber, subject, message} = data;
    const captchaToken = await executeRecaptcha();
    setIsLoading(true);
    if (isReportProblem) { // Report - Report an issue about an order
      sendFeedback(orderId, captchaToken, message, subject, phoneNumber, name, email, true)
        .then(() => {
          setModalIsOpen(true); setIsSuccess(true); // Display success modal
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setModalIsOpen(true);
        });
    } else { // Review - User give his opinion about his order
      sendFeedback(orderId, captchaToken, message)
        .then(() => {
          setModalIsOpen(true); setIsSuccess(true); // Display success modal
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setModalIsOpen(true);
        });
		};
	};

	return (
		<div className="checkout-page-left__content-wrapper content-wrapper--padding">
      <div>
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          {isReportProblem && (
            <div className="contact__container">
              <div className="form-input__container form-input__container--column">
                <label className="discount__container__label">
                  {translate('StudySurveyFormPage.name')}
                </label>
                <input
                  className="form-input__border form-input__border--discount txt-s"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Form.errors.required',
                    },
                    pattern: {
                      value: stringPattern,
                      message: 'Form.errors.pattern.lastName',
                    },
                  })}
                  name="name"
                  id="name"
                  type="text"
                  defaultValue={profile && profile.lastName}
                />
              </div>

              {errors.name && (
                <div className="contact-form__error">
                  <span className="txt-error ">{translate(errors.name.message)}</span>
                </div>
              )}
              <div className="form-input__container form-input__container--column">
                <label className="discount__container__label">
                  {translate('StudySurveyFormPage.email')}
                </label>
                <input
                  className="form-input__border form-input__border--discount txt-s"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Form.errors.required',
                    },
                    pattern: {
                      value: emailPattern,
                      message: 'Form.errors.pattern.email',
                    },
                  })}
                  name="email"
                  id="email"
                  type="text"
                  defaultValue={profile && profile.email}
                />
              </div>

              {errors.email && (
                <div className="contact-form__error">
                  <span className="txt-error ">{translate(errors.email.message)}</span>
                </div>
              )}
              <div className="form-input__container form-input__container--column">
                <label className="discount__container__label">
                  {translate('StudySurveyFormPage.phone_number')}
                </label>
                <input
                  className="form-input__border form-input__border--discount txt-s"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Form.errors.required',
                    },
                    pattern: {
                      value: phonePattern,
                      message: 'Form.errors.pattern.phone',
                    },
                  })}
                  name="phoneNumber"
                  id="phoneNumber"
                  type="tel"
                />
              </div>

              {errors.phoneNumber && (
                <div className="contact-form__error">
                  <span className="txt-error ">{translate(errors.phoneNumber.message)}</span>
                </div>
              )}
              <div className="form-input__container form-input__container--column">
                <label className="discount__container__label">
                  {translate('StudySurveyFormPage.subject')}
                </label>
                <input
                  className="form-input__border form-input__border--discount txt-s"
                  ref={register({
                    required: {
                      value: true,
                      message: 'Form.errors.required',
                    },
                  })}
                  name="subject"
                  id="subject"
                  type="text"
                />
              </div>
              {errors.subject && (
                <div className="contact-form__error">
                  <span className="txt-error ">{translate(errors.subject.message)}</span>
                </div>
              )}
            </div>
          )}

          <div className="contact__container">
            <div className="form-input__container form-input__container--column">
              <label className="discount__container__label">
                {translate('StudySurveyFormPage.message')}
              </label>
              <textarea
                className="form-input__border form-input__border--discount txt-s"
                ref={register({
                  required: true,
                })}
                name="message"
                id="message"
              />
            </div>
          </div>

          <div className="contact__container">
            <div className="form-input__action form-agree__container">
              <input
                type="checkbox"
                className={`input-agree__checkbox ${agree && 'checked'}`}
                ref={register({
                  required: true,
                })}
                name="agree-checkbox"
                id="agree-checkbox"
                defaultChecked={agree}
                onChange={() => setAgree(!agree)}
              />
              <label htmlFor="agree-checkbox" className="label-agree__checkbox">
                {translate('StudySurveyFormPage.agree.sentence')}
              </label>
            </div>
          </div>
          <div className="contact__container">
            <div className="form-input__action form-agree__container">
              <p className="label-agree__checkbox captcha-terms">
                {translate('StudySurveyFormPage.recaptcha.sentence.main')}
                <a href="https://policies.google.com/privacy" className="captcha-terms">
                  {' '}
                  {translate('StudySurveyFormPage.recaptcha.policy')}{' '}
                </a>{' '}
                {translate('StudySurveyFormPage.recaptcha.sentence.and')}
                <a href="https://policies.google.com/terms" className="captcha-terms">
                  {' '}
                  {translate('StudySurveyFormPage.recaptcha.terms')}{' '}
                </a>{' '}
                {translate('StudySurveyFormPage.recaptcha.sentence.apply')}.
              </p>
            </div>
          </div>

          <div className="form-input__action discount-button__container">
            <button
              type="submit"
              disabled={!formState.isValid}
              className="form__btn btn__primary discount-button__container__btn">
              {isLoading ? (
                <LoadingSpinner
                  height={'20px'}
                  width={'20px'}
                />
              ) : (
                translate('StudySurveyFormPage.button')
              )}
            </button>
          </div>
        </form>
      </div>
			<ConfirmMessageSentModal
				open={modalIsOpen}
				onClose={() => setModalIsOpen(false)}
				isSuccess={isSuccess}
				isReportProblem={isReportProblem}
			/>
		</div>
	);
});
