import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

interface OrdersPaginationProps {
	ordersLength: number;
	showMore: () => void;
}

export const OrdersPagination = observer(({ordersLength, showMore}: OrdersPaginationProps) => {
	const {translate} = useTranslate();
	const {
		order: {totalOrders},
	} = useStore();

	return (
		<div className="pagination txt-center">
			{ordersLength < totalOrders && (
				<button className="btn__secondary--mini-inverse" onClick={() => showMore()}>
					{translate('Pagination.button')}
				</button>
			)}
			<p className="pagination__text">
				{translate('Pagination.textOne') +
					ordersLength +
					translate('Pagination.textTwo') +
					totalOrders}
			</p>
		</div>
	);
});
