import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';

export const OrderIsCancelled = observer(() => {
	const {translate} = useTranslate();
	const history = useHistory();

	return (
		<div className="current-order__div">
			<p className="current-order__text txt-center txt-error txt-l txt-bold">
				{translate('CancelOrderPage.done')}
			</p>
			<div className="current-order__actions txt-center">
				<button
					className="current-order__button btn__primary"
					onClick={() => history.push('/order')}>
					{translate(`CancelOrderPage.button.newOrder`)}
				</button>
			</div>
		</div>
	);
});
