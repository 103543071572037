import {TransformedParts, BasketProductModel} from '@acrelec-cloud/apico-cdk';
import {ProductType} from '@acrelec-cloud/apico-sdk';
import {toJS} from 'mobx';
import React, {useState, Dispatch, SetStateAction} from 'react';
import {useHistory} from 'react-router-dom';

import discountStar from 'src/assets/images/discount-star.png';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {usePopover} from 'src/components/Popover/popover.hook';
import {useStore} from 'src/contexts/store.context';
import {useImage} from 'src/hooks/image.hook';

import {BasketItemParts} from './BasketItemParts';

export interface BasketItemTopProps {
	id: string;
	calories: number | undefined;
	displayActionButtons: boolean;
	image: string;
	parts: TransformedParts[];
	name: string;
	quantity: number;
	modalState: [boolean, Dispatch<SetStateAction<boolean>>];
	type: string;
}

export const BasketItemTop = ({
	id,
	calories,
	displayActionButtons,
	image: productPicture,
	parts,
	type,
	name,
	quantity,
	modalState: [modalIsOpen, setModalIsOpen],
}: BasketItemTopProps) => {
	const history = useHistory();
	const isCoupon = type === ProductType.COUPON;
	const [open, setOpen] = useState(false);
	const {translate} = useTranslate();
	const {image} = useImage(isCoupon ? discountStar : productPicture);
	const confirmremoveProductByUniqueId = () => {
		setModalIsOpen(true);
	};

	const {
		basket: {products},
	} = useStore();

	const {closePopover} = usePopover();

	const targetProduct = toJS(products).find((product: BasketProductModel) => product.id === id);
  const isCustomizable = targetProduct?.parts?.length > 0;

	const handleOnClick = () => {
		history.push({pathname: `/product/${targetProduct.code}`, state: {basketId: targetProduct.id}});
		closePopover();
	};

	return (
		<div className="basket-item__top">
			{/* LEFT */}
			{image && (
				<figure className="basket-item__figure">
					<img src={image} alt={name} className="basket-item__img" />
				</figure>
			)}
			{/* RIGHT */}
			<div className="basket-item__infos">
				<div>
					<div className="basket-item__infos__top">
						<h2 className="txt-xlight txt-s txt-bold txt-secondary-dark">
							<span className="txt-s">{quantity} x </span>
							{isCoupon ? <span className="txt-s">{translate('DiscountTitle')}</span> : name}
						</h2>
						{/* OPTIONAL 
						{displayActionButtons && (
							<>
								<div className="basket-item__infos__delete">
									<button
										className="btn-round__secondary basket-item__delete"
										theme-icon=""
										onClick={() => confirmremoveProductByUniqueId()}
									/>
								</div>
							</>
						)}*/}
					</div>
					{/* Show / hide product details */}
					{parts.length > 0 && (
						<div className="svg-icon">
							<button
								className="svg-icon__button"
								onClick={() => {
									setOpen(!open);
								}}>
								<i
									className={`fas fa-chevron-circle-down${
										open ? ' svg-icon__svg open' : ' svg-icon__svg'
									}`}></i>
								<span className="svg-icon__text">{translate('Basket.show_details')}</span>
							</button>
						</div>
					)}
				</div>
				{!!calories && <p className="txt-xlight txt-xs">{calories} CAL</p>}
				<div className={open ? 'basket-item__parts show' : 'basket-item__parts hide'}>
					{parts.length > 0 && <BasketItemParts parts={parts} />}
				</div>
				{(type !== ProductType.COUPON && displayActionButtons && isCustomizable) && <div className="svg-icon">
					<button className="svg-icon__button" onClick={handleOnClick}>
						<i className={`fas fa-chevron-circle-down svg-icon__svg link`}></i>
						<span className="svg-icon__text">{translate('Basket.customize')}</span>
					</button>
				</div>}
			</div>
		</div>
	);
};
