import {BasketProductModelType} from '@acrelec-cloud/apico-cdk';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useStore} from 'src/contexts/store.context';

import {BasketItem} from './BasketItem/BasketItem';

export const BasketList = observer(() => {
	const {
		basket: {basketProducts},
	} = useStore();

	return (
		<div>
			{basketProducts.map((product: BasketProductModelType, index: number) => (
				<BasketItem item={product} key={index} />
			))}
		</div>
	);
});
