import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-notifications-component/dist/theme.css';
import 'reflect-metadata';

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import React from 'react';
import ReactDOM from 'react-dom';

import {App} from './App';

// Sentry.init({
// 	dsn: process.env.REACT_APP_SENTRY_KEY,
// 	enabled: process.env.REACT_APP_ENVIRONMENT === 'production',
// 	integrations: [new Integrations.BrowserTracing()],
// 	tracesSampleRate: 1.0,
// });

gsap.registerPlugin(ScrollTrigger);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);
