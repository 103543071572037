import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

import {FormInputConfig} from 'src/components/Form/form.interface';
import {FormInput} from 'src/components/Form/FormInput';
import {useTranslate} from 'src/components/Languages/translate.hook';

import {LoadingSpinner} from '../LoadingSpinner/LoadingSpinner';

interface FormComponentProps {
	onSubmit: (formValue: any) => void;
	inputsConfig: FormInputConfig[];
	value?: any;
	labelSubmitButton?: string;
	labelResetButton?: string;
	displayResetButton?: boolean;
  classNameAction?: string;
	classNameSubmitButton?: string;
	classNameResetButton?: string;
	classNameForm?: string;
	classNameField?: string;
	classNameInput?: string;
	classNameLabel?: string;
	classNameError?: string;
	callbackError?: string;
	isLoading?: boolean;
	buttonDisabled?: boolean;
	onChange?: () => void;
}

export const FormComponent = (props: FormComponentProps) => {
	const {
		onSubmit,
		onChange,
		inputsConfig,
		value,
		labelSubmitButton = 'Form.submit',
		classNameSubmitButton = '',
		labelResetButton = 'Form.reset',
		displayResetButton = false,
    classNameAction = '',
		classNameResetButton = '',
		classNameForm = '',
		classNameField = '',
		classNameInput = '',
		classNameLabel = '',
		classNameError = 'txt-error',
		callbackError,
		isLoading = false,
		buttonDisabled,
	} = props;
	const {translate} = useTranslate();
	const [formError, setFormError] = useState<any>({});
	const {errors, register, formState, reset, getValues} = useForm({
		mode: 'onChange',
	});

	useEffect(() => {
		setFormError(errors);
	}, [errors]);

	useEffect(() => {
		if (!value) return;
		reset(value);
	}, [value, reset]);

	const resetForm = () => {
		reset({});
	};

	const submit = (e:any) => {
    e.preventDefault();
		onSubmit(getValues());
	};

	return (
		<form className={classNameForm} onSubmit={submit}>
      
			{inputsConfig.map((input, index) => {
				return (
					<FormInput
						{...input}
						classNameField={classNameField}
						classNameInput={classNameInput}
						classNameError={classNameError}
						classNameLabel={classNameLabel}
						formError={formError}
						register={register}
						key={index.toString()}
						onChange={onChange}></FormInput>
				);
			})}

      <div className="txt__container-error">
        {callbackError && <p className={'txt-error'}>{translate(callbackError)}</p>}
      </div>

      <div className={classNameAction}>
        <button
          type="submit"
          disabled={!formState.isValid || buttonDisabled}
          className={classNameSubmitButton}>
          {isLoading ? (
            <LoadingSpinner height={'20px'} width={'20px'} />
          ) : (
            translate(labelSubmitButton + '')
          )}
        </button>
      </div>

			{displayResetButton && (
        <div className={classNameAction}>
          <button type="button" onClick={resetForm} className={classNameResetButton}>
            {translate(labelResetButton + '')}
          </button>
        </div>
			)}
		</form>
	);
};
