import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {useStore} from 'src/contexts/store.context';
import {useRestaurantStatusChecks} from 'src/hooks/restaurant-status-checks.hook';
import {GlobalRouterModal} from 'src/routers/GlobalRouter';

interface OrderDisabledModalProps {
	status: GlobalRouterModal;
}

const RESTAURANT_PATH = '/restaurants';

export const OrderDisabledModal = observer(({status}: OrderDisabledModalProps) => {
	const {translate} = useTranslate();
	const history = useHistory();
	const {pathname} = useLocation();
	const {acceptUnavailableRestaurant} = useRestaurantStatusChecks();
	const [open, setOpen] = useState<boolean>(
		status === GlobalRouterModal.UNAVAILABLE_RESTAURANT ? true : false,
	);

	const {
		restaurant: {isUniqueRestaurant},
	} = useStore();

	useEffect(
		() => setOpen(status === GlobalRouterModal.UNAVAILABLE_RESTAURANT ? true : false),
		[status],
	);

	return (
		<Modal className="modal__order-disabled" open={open} onClose={() => {}}>
			<div className="order-disabled">
				<p className="order-disabled__text">{translate('OrderDisabledModal.message')}</p>
				<div className="order-disabled__actions">
					{!isUniqueRestaurant && (
						<button
							className="order-disabled__btn order-disabled__btn--change btn__secondary--inverse"
							onClick={() =>
								pathname === RESTAURANT_PATH ? setOpen(false) : history.push(RESTAURANT_PATH)
							}>
							{translate('OrderDisabledModal.change')}
						</button>
					)}
					<button
						className="order-disabled__btn order-disabled__btn--continue btn__secondary"
						onClick={() => {
							acceptUnavailableRestaurant();
							setOpen(false);
						}}>
						{translate('OrderDisabledModal.continue')}
					</button>
				</div>
			</div>
		</Modal>
	);
});
