import {RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {DateTime} from 'luxon';
import {observer} from 'mobx-react-lite';
import React, {MutableRefObject, useCallback, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {OrderDisabledConfirmationModal} from 'src/components/Modal/CustomModals/OrderDisabledConfirmationModal';
import {RestaurantData, StatusType} from 'src/components/Restaurant/pick-restaurant.model';
import {PickRestaurantModal} from 'src/components/Restaurant/RestaurantItem';
import {useStore} from 'src/contexts/store.context';
import {useRestaurantStatusChecks} from 'src/hooks/restaurant-status-checks.hook';

import {ResetBasketModal} from '../Modal/CustomModals/ResetBasketModal';

interface PickRestaurantButtonProps {
	restaurantInfo: RestaurantObject;
	onDisplayRestaurant?: (e: Event | React.MouseEvent, restaurantId: number) => void;
}

export const PickRestaurantButton = observer(
	({restaurantInfo, onDisplayRestaurant}: PickRestaurantButtonProps) => {
		const {translate} = useTranslate();
		const history = useHistory();
		const {checkRestaurantStatus} = useRestaurantStatusChecks();
		const [modalStatus, setModalStatus] = useState<PickRestaurantModal>(PickRestaurantModal.NULL);

		const {
			restaurant: {currentRestaurant, setCurrentRestaurant},
			basket: {productCount},
		} = useStore();

		const setStatus = useCallback((status) => setModalStatus(status), []);

		const button: MutableRefObject<RestaurantData> = useRef({
			active: {
				class:
					' btn__secondary--icon-after btn__secondary--icon-fas restaurant-item__pick-button restaurant-item__pick-button--active',
				buttonTitle: 'btn_active',
				icon: '',
			},
			choose: {
				class: ' restaurant-item__pick-button',
				buttonTitle: 'btn_choose',
				icon: '',
			},
		});

		const status: StatusType = useMemo(
			() => (currentRestaurant?.id === restaurantInfo.id ? StatusType.ACTIVE : StatusType.CHOOSE),
			[currentRestaurant?.id, restaurantInfo.id],
		);

		const handlePickRestaurant = useCallback(
			(e) => {
				e.stopPropagation();
				e.preventDefault();
				const notSelected = status === StatusType.CHOOSE;

				if (notSelected) {
					const OrderNotEnabled = checkRestaurantStatus(restaurantInfo);
					const basketHasProducts = productCount && productCount > 0;
					const currentTime = DateTime.local();
					const ordersDisabledUntil = DateTime.fromJSDate(
						new Date(restaurantInfo.settings.ordersEnabledAt),
					).setZone(restaurantInfo.timeZone);
					const ordersTempDisabled = currentTime.valueOf() < ordersDisabledUntil.valueOf();

					if (OrderNotEnabled && !ordersTempDisabled) {
						setStatus(PickRestaurantModal.UNAVAILABLE_RESTAURANT);
					} else {
						if (basketHasProducts) {
							setStatus(PickRestaurantModal.RESET_BASKET);
						} else {
							if (restaurantInfo.authenticationRequired) {
								history.push(`/restaurants/${restaurantInfo.slug}/authenticate`);
							} else {
								setCurrentRestaurant(restaurantInfo);
								onDisplayRestaurant && onDisplayRestaurant(e, restaurantInfo.id);
							}
						}
					}
				} else {
          onDisplayRestaurant && onDisplayRestaurant(e, restaurantInfo.id);
        }
			},
			[
				status,
				checkRestaurantStatus,
				restaurantInfo,
				productCount,
				setStatus,
				history,
				setCurrentRestaurant,
				onDisplayRestaurant,
			],
		);

		return (
			<>
				<button
					className={`btn__secondary--mini-inverse${button.current[status].class} btn-padding`}
					theme-icon={button.current[status].icon}
					onClick={(e) => {
						handlePickRestaurant(e);
					}}>
					{translate(`PickRestaurantButton.${button.current[status].buttonTitle}`)}
				</button>
				<ResetBasketModal
					status={modalStatus}
					setStatus={(status: PickRestaurantModal) => setStatus(status)}
					restaurantInfo={restaurantInfo}
				/>
				<OrderDisabledConfirmationModal
					status={modalStatus}
					setStatus={(status: PickRestaurantModal) => setStatus(status)}
					restaurantInfo={restaurantInfo}
				/>
			</>
		);
	},
);
