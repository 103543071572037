import {OrderObject, RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';
import {Redirect, useHistory, useLocation, useParams} from 'react-router-dom';
import store from 'store';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {LoadingSpinner} from 'src/components/LoadingSpinner/LoadingSpinner';
import {CancelOrderContent, PickupTimeOrderContent} from 'src/components/Order/EditOrder/EditOrderContent';
import {GoBack} from 'src/components/Order/GoBack';
import {useStore} from 'src/contexts/store.context';
interface OrderDetailsParams {
	orderId: string;
	guestId?: string;
	accessToken?: string;
}

interface EditOrderPageProps {
	title: string;
	isPickUpModification: boolean;
	isCancellation: boolean;
}

export const EditOrderPage = observer(
	({title, isPickUpModification, isCancellation}: EditOrderPageProps) => {
		const {translate} = useTranslate();
		document.title = `${translate(title)} | ${process.env.REACT_APP_BRAND_NAME}`;

		const history = useHistory();
		const location = useLocation();
		const {orderId, guestId, accessToken} = useParams<OrderDetailsParams>();

		const [currentOrder, setCurrentOrder] = useState<OrderObject | null>(null);
		const [isLoading, setIsLoading] = useState<boolean>(true);
		const [myRestaurant, setMyRestaurant] = useState<RestaurantObject | null>(null);

		const validGuestParams = useMemo(
			(): boolean => (guestId && accessToken ? true : false),
			[accessToken, guestId],
		);

		const {
			auth: {isLogged, guest, profile, logout, authenticateGuestFromEmail},
			order: {findOrder},
			restaurant: {fetchRestaurant, setCurrentRestaurant},
		} = useStore();

		useEffect(() => {
			if (validGuestParams) {
				authenticateGuestFromEmail(Number(guestId), accessToken);
			}
		}, [accessToken, authenticateGuestFromEmail, guestId, validGuestParams]);

		useEffect(() => {
			// require login when no guest params
			if (!validGuestParams && !isLogged) {
				// login will use it to redirect to the previousUrl after authenticated
				store.set('previousUrl', location.pathname);
				history.push('/login');
			} else {
				store.remove('previousUrl');
			}
		}, [history, isLogged, location.pathname, validGuestParams]);

		useEffect(() => {
			// get the order if authenticated
			const userId = isLogged ? profile.accountId : guest ? guest.accountId : null;
			let interval: NodeJS.Timeout;
			const findOrderInterval = () => {
				const openModal = document.getElementById('modal')!;
				const hasOpenModal = openModal.hasChildNodes();
				if (!hasOpenModal) {
					findOrder(Number(orderId))
						.then((response: OrderObject) => {
							response.accountId === userId ? setCurrentOrder(response) : logout();
							setIsLoading(false);
						})
						.catch((error: any) => {
							setCurrentOrder(error);
							setIsLoading(false);
						});
				}
			};
			if (guest || isLogged) {
				findOrderInterval();
				interval = setInterval(
					() => findOrderInterval(),
					Number(process.env.REACT_APP_CHECK_ORDER_INTERVAL),
				);
			}
			return () => clearInterval(interval);
		}, [findOrder, profile, guest, isLogged, orderId, logout]);

		useEffect(() => {
			if (currentOrder && currentOrder.id && !myRestaurant) {
				fetchRestaurant(Number(currentOrder?.restaurantId)).then((response: RestaurantObject) => {
					setMyRestaurant(response);
				});
			}
		}, [currentOrder, fetchRestaurant, myRestaurant, setCurrentRestaurant]);

		useEffect(() => {
			if (myRestaurant) {
				setCurrentRestaurant(myRestaurant);
			}
		}, [myRestaurant, setCurrentRestaurant]);

		return (
			<main className="current-order-main">
				{/* If we are on the cancellation page, display this layout: */}
				{isCancellation && (
					<div className="current-order theme-container">
						<GoBack />
						<h1 className="current-order__title page-title txt-xxl">
							{translate('CancelOrderPage.title')}
						</h1>
						{isLoading ? (
							<div className="current-order__loading loading-div">
								<LoadingSpinner />
							</div>
						) : currentOrder && currentOrder.id ? (
							<CancelOrderContent currentOrder={currentOrder} />
						) : (
							<Redirect to="/404" />
						)}
					</div>
				)}
				{/* If we are on the pick-up time modification page, display this layout: */}
				{isPickUpModification &&
					(isLoading ? (
						<div className="loading-div">
							<LoadingSpinner />
						</div>
					) : currentOrder && currentOrder.id ? (
						<div className="current-order">
							<GoBack />
							<h1 className="current-order__title page-title txt-xxl">
								{translate('ModifyOrderPickupPage.title')}
							</h1>
              <PickupTimeOrderContent currentOrder={currentOrder} />
						</div>
					) : (
						<Redirect to="/404" />
					))}
			</main>
		);
	},
);
