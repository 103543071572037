import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import { OrderContainerModal } from 'src/components/Order/OrderContainer';
import { useStore } from 'src/contexts/store.context';

import { Modal } from '../../Modal';


interface DeleteOrderModalProps {
	orderId: number;
  status: OrderContainerModal;
  setStatus: (status: OrderContainerModal) => void;
}

export const DeleteOrderModal = observer(({orderId, status, setStatus}: DeleteOrderModalProps) => {
	const {translate} = useTranslate();
  const [open, setOpen] = useState<boolean>(
		status === OrderContainerModal.CANCEL_ORDER ? true : false,
	);

  const {order: {cancelOrder}} = useStore();

	useEffect(() => setOpen(status === OrderContainerModal.CANCEL_ORDER ? true : false), [status]);

  const handleCancelOrder = useCallback(() =>               
  cancelOrder(orderId)
  .then(() => setStatus(OrderContainerModal.CANCEL_SUCCESS))
  .catch(() => setStatus(OrderContainerModal.CANCEL_FAIL)),
  [cancelOrder, orderId, setStatus]);

	return (
      <Modal open={open} onClose={() => setStatus(OrderContainerModal.NULL)}>
        <div className="delete-item">
          <div className="close" onClick={() => setStatus(OrderContainerModal.NULL)}></div>
          <p className="delete-item__text">{translate('DeleteOrderModal.text')}</p>
          <div className="delete-item__btn">
            <button
              className="home-main__btn btn__secondary--inverse btn-padding"
              onClick={() => setStatus(OrderContainerModal.NULL)}>
              {translate('App.no')}
            </button>
            <button
              className="home-main__btn btn__secondary btn-padding"
              onClick={() => handleCancelOrder()
              }>
              {translate('App.yes')}
            </button>
          </div>
        </div>
      </Modal>
	);
});
