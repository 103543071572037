import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';

export const ProfileCreatedModal = observer(({open, onClose}: CustomModalProps) => {
	const {translate} = useTranslate();

	return (
		<Modal open={open} onClose={onClose}>
			<div className="profile-modal">
				<div className="profile-modal__close close" onClick={() => onClose()}></div>
				<div className="profile-modal__wrapper">
					<p className="profile-modal__title txt-success txt-uppercase txt-bold text-20">
						{translate('Profile.modal.success.title')}
					</p>
					<div className="profile-modal__icon"></div>
					<p className="profile-modal__content txt-16">
						{translate('Profile.modal.success.content')}
					</p>
				</div>
				<button className="profile-modal__button btn__secondary" onClick={() => onClose()}>
					{translate('Profile.modal.success.button')}
				</button>
			</div>
		</Modal>
	);
});
