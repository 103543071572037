import {OrderItemObject, OrderObject} from '@acrelec-cloud/apico-sdk';
import React, {useMemo} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {SumOrderItem} from 'src/components/Order/SumBasket/SumOrderItem';
import {useMoney} from 'src/hooks/money.hook';
import {getOrderTotal, getOrderTotalBeforeDiscount} from 'src/utils/order-total';

interface SumOrderListProps {
	currency: string;
	order: OrderObject;
	orderItems: any;
	open?: boolean;
}
export const SumOrderList = (props: SumOrderListProps) => {
	const {currency, order, orderItems} = props;
	const {translate} = useTranslate();
	const [formatMoney] = useMoney(currency);

	const isDiscountProduct = orderItems.filter(
		(product: any) => product.productSnapshot.type === 'COUPON',
	);

	const orderTotalPrice = useMemo(() => {
		return getOrderTotal(order.price, order.taxes);
	}, [order]);

	const orderPriceBeforeDiscount = useMemo(() => {
		return getOrderTotalBeforeDiscount(orderItems);
	}, [orderItems]);

	return (
		<div>
			{orderItems.map((product: OrderItemObject, index: number) => (
				<SumOrderItem item={product} key={index} />
			))}
			<div className="order-totals">
				{/* <p className="txt-secondary txt-bold txt-right order-totals__text">
					{translate('Basket.subtotal')}: {formatMoney(order.price)}
				</p>
				<p className="txt-secondary txt-bold txt-right order-totals__text">
					{translate('Basket.taxes')}: {formatMoney(order.taxes)}
				</p> */}
				{isDiscountProduct.length > 0 && (
					<p className="txt-primary txt-s txt-bold txt-right order-totals__text">
						{translate('Basket.total')}: {formatMoney(orderPriceBeforeDiscount)}
					</p>
				)}
				<p className="txt-primary txt-m txt-bold txt-right order-totals__text">
					{translate('Basket.total_discounted')}: {formatMoney(orderTotalPrice)}
				</p>
			</div>
		</div>
	);
};
