import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import {useStore} from 'src/contexts/store.context';

import {useTranslate} from '../Languages/translate.hook';
import {FilterItem} from './FilterItem';

export const Filters = observer(() => {
	const {
		navigation: {
			currentNavigationFilters,
			resetSelectedFilters,
			updateSelectedFilters,
			hasSelectedFilters,
			isFilterSelected,
		},
	} = useStore();
	const {translate} = useTranslate();

	const isAllActive = useMemo(() => {
		return !hasSelectedFilters ? true : false;
	}, [hasSelectedFilters]);

	return (
			<ul className="filter-list">
				<FilterItem
					name={translate('App.all')}
					onClick={() => resetSelectedFilters()}
					buttonClassName={isAllActive ? 'filter-item__button active' : 'filter-item__button'}
				/>
				{currentNavigationFilters.map((filter: string, index: number) => (
					<FilterItem
						onClick={() => updateSelectedFilters(filter)}
						name={filter}
						buttonClassName={
							isFilterSelected(filter) ? 'filter-item__button active' : 'filter-item__button'
						}
						key={index}
					/>
				))}
			</ul>
	);
});
