import { ComponentNameTypes, CustomizationStore, PopoverTypes } from '@acrelec-cloud/apico-cdk';
import { RestaurantOutageProductsObject } from '@acrelec-cloud/apico-shared';
import { observer } from 'mobx-react-lite';
import { applySnapshot, getEnv, getSnapshot, Instance } from 'mobx-state-tree';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';

import drink_big from 'src/assets/images/icons/drink_big.svg';
import drink_small from 'src/assets/images/icons/drink_small.svg';
import { useTranslate } from 'src/components/Languages/translate.hook';
import { usePopover } from 'src/components/Popover/popover.hook';
import { useStore } from 'src/contexts/store.context';

interface ProductChoiceProps {
  mealCode: number;
  itemCode: number;
}

export const useAddToCart = (productCode: number) => {
  const history = useHistory();
  const store = useStore();
  const { openPopover } = usePopover();

  const {
    restaurant: { currentRestaurant },
    basket: { addBasketProduct },
  } = useStore();

  const productUnavailable = !currentRestaurant || (
    currentRestaurant.outageProducts &&
    currentRestaurant.outageProducts.find(
      (outage: RestaurantOutageProductsObject) => outage.productCode === productCode,
    ));


  const [customization, setCustomization] = useState<Instance<typeof CustomizationStore>>();

  useEffect(() => {
    const loadCustomization = async () => {
      const tempStore = CustomizationStore.create(
        {
          quantity: 1,
        },
        {
          ...getEnv(store),
          root: store,
        },
      );

      await tempStore.fetchProduct(productCode);
      await tempStore.fetchProductParts(productCode);
      tempStore.setInitialCustomization();

      setCustomization(tempStore);
    };
    loadCustomization().catch(console.error);
  }, [store, productCode]);

  const onAddToCart = useCallback(async () => {
    if (!customization || customization.required.length > 0) {
      history.push(`/product/${productCode}`);
      return;
    }
    const currentCustomization = getSnapshot(customization);

    const maxQtyProduct = 99999; // Unlimited quantity
    applySnapshot(store.customization, currentCustomization);
    addBasketProduct(currentCustomization, maxQtyProduct);

    openPopover(PopoverTypes.RIGHT, ComponentNameTypes.Basket);
  }, [customization, addBasketProduct, openPopover, history, productCode, store.customization]);

  return {
    onAddToCart,
    productUnavailable
  }
}

export const ProductChoice = observer((props: ProductChoiceProps) => {
  const { mealCode, itemCode } = props;

  const { translate } = useTranslate();

  const itemProduct = useAddToCart(itemCode);
  const mealProduct = useAddToCart(mealCode);

  return (
    <div className="product-item__choice-button">
      <button
        onClick={() => !mealProduct.productUnavailable && mealProduct.onAddToCart()}
        className="choice-button choice-button--top">
        <ReactSVG src={drink_big} className="button-icon button-icon--burger" />
        <span className="button-title">{translate('ProductChoice.item')}</span>
      </button>
      <button
        onClick={() => !itemProduct.productUnavailable && itemProduct.onAddToCart()}
        className="choice-button choice-button--bottom">
        <ReactSVG src={drink_small} className="button-icon button-icon--meal" />
        <span className="button-title">{translate('ProductChoice.meal')}</span>
      </button>
    </div>
  );
});
