import { TransformedParts } from "@acrelec-cloud/apico-cdk";
import { ProductObject } from "@acrelec-cloud/apico-sdk";
import { has } from "lodash";

import { TranslateProduct } from "src/components/Languages/translate.hook";

export const findTranslateProduct = (
  currentLanguage: string,
  productData: ProductObject | TransformedParts
): TranslateProduct => {
  const translations = productData.translations;
  let name: string;
  let description: string | undefined;

  if (translations && translations.length > 0) {
    const findName = translations.find((t) => t.code === "name");
    const findDescription = translations.find((t) => t.code === "description");

    const findNameTranslation = findName?.items.find(
      (t) => t.language === currentLanguage
    );

    const findDescriptionTranslation = findDescription?.items.find(
      (t) => t.language === currentLanguage
    );

    if (findNameTranslation) {
      name = findNameTranslation.value;
      description = findDescriptionTranslation?.value;

      return { name, description };
    }
  }

  name = productData.name;
  
  const hasDescriptionTranslation = has(productData, 'description');
  description = hasDescriptionTranslation ? (productData as ProductObject).description : undefined;

  return { name, description };
};
