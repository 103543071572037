import {
  AvailableLanguages,
  LanguagesModelType,
  TransformedParts,
} from "@acrelec-cloud/apico-cdk";
import { ProductObject } from "@acrelec-cloud/apico-sdk";
import I18n from "i18n-js";

import { findTranslateProduct } from "src/components/Languages/find-translate-product.util";
import { useStore } from "src/contexts/store.context";

export interface TranslateProduct {
  name: string;
  description: string | undefined;
}

export interface UseTranslateProduct {
  translate: (content: string) => string;
  translateProduct: (
    productData: ProductObject | TransformedParts
  ) => TranslateProduct;
  setLanguage: (languageCode: string) => void;
  languages: LanguagesModelType[];
  currentLanguage: string;
}

export const useTranslate = (): UseTranslateProduct => {
  const {
    language: { currentLanguage, setCurrentLanguage, languages },
  } = useStore();

  const setLanguage = (languageCode: AvailableLanguages): void =>
    setCurrentLanguage(languageCode);

  const translate = (content: string) =>
    I18n.t(content, { locale: currentLanguage });

  const translateProduct = (
    productData: ProductObject | TransformedParts
  ): {
    name: string;
    description: string | undefined;
  } => findTranslateProduct(currentLanguage, productData);

  return {
    translate,
    translateProduct,
    setLanguage,
    languages,
    currentLanguage,
  };
};
