import {observer} from 'mobx-react-lite';
import React from 'react';

import WarningIcon from 'src/assets/images/icons/warning.png';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {useMoney} from 'src/hooks/money.hook';

export interface DifferentPriceModalProps {
  callback: () => void;
	open: boolean;
	onClose: () => void;
	previousPrice: number;
	newPrice: number;
}

export const DifferentPriceModal = observer(
	({open, onClose, previousPrice, newPrice, callback}: DifferentPriceModalProps) => {
		const {translate} = useTranslate();
		const [formatMoney] = useMoney();

		return (
			<Modal open={open} onClose={onClose}>
				<div className="profile-modal">
					<div className="profile-modal__close close" onClick={() => onClose()}></div>
					<div className="profile-modal__wrapper">
						<p className="profile-modal__title txt-uppercase txt-bold text-20">
							{translate('DifferentPriceModal.title')}
						</p>
            <div className="profile-modal__warning">
              <img src={WarningIcon} alt="warning icon" />
            </div>
						<p className="profile-modal__content txt-16 txt-bold">{translate('DifferentPriceModal.text')}</p>
						<p className="profile-modal__content txt-16 txt-primary-dark txt-bold">
							{translate('DifferentPriceModal.previous_price')}: {formatMoney(previousPrice)} -{' '}
							{translate('DifferentPriceModal.new_price')}: {formatMoney(newPrice)}
						</p>
					</div>
					<div>
						<button
							className="home-main__btn btn__secondary--inverse btn-padding"
							onClick={onClose}>
							{translate('DifferentPriceModal.cancel')}
						</button>
						<button className="home-main__btn btn__secondary btn-padding" onClick={callback}>
							{translate('DifferentPriceModal.validate')}
						</button>
					</div>
				</div>
			</Modal>
		);
	},
);
