import {useEffect, useState} from 'react';

interface UserPosition {
  latitude: number;
  longitude: number;
}
export const useUserLocation = () => {
  const [userLocation, setUserLocation] = useState<undefined | UserPosition>();
  useEffect(() => {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        const {latitude, longitude} = position.coords;
			  setUserLocation({latitude, longitude});
      });
    }
  }, [])
  return { userLocation };
};
