import React from 'react';

export const ThemeStylesPage = () => {
	document.title = 'HomeMenuPage | Acrelec WOP';
	return (
		<div>
			<header className="header">
				<h1 className="txt-h1 txt-xl txt-bold">Theme Style Sheet</h1>
			</header>
			<main className="home-main txt-center">
				<div className="home-main__warning warning bg-success">
					<p className="warning__text txt-s txt-white txt-bold">
						See the list of reusable elements.
					</p>
				</div>
				<div className="home-main__actions">
					<button
						className="home-main__btn btn__primary btn__primary--icon-before btn__primary--icon-fas btn-padding"
						theme-icon="">
						Primary icon
					</button>
					<button
						className="home-main__btn btn__primary btn__primary--icon-after btn__primary--icon-fas btn-padding"
						theme-icon="">
						Primary icon
					</button>
					<button className="home-main__btn btn__primary btn-padding">Primary</button>
					<button
						className="home-main__btn btn__secondary btn__secondary--icon-before btn__secondary--icon-fas btn-padding"
						theme-icon="">
						Secondary icon
					</button>
					<button
						className="home-main__btn btn__secondary btn__secondary--icon-after btn__secondary--icon-fas btn-padding"
						theme-icon="">
						Secondary icon
					</button>
					<button className="home-main__btn btn__secondary btn-padding">Secondary</button>
					<button
						className="home-main__btn btn__secondary--inverse btn__secondary--icon-before btn__secondary--icon-fas btn-padding"
						theme-icon="">
						Secondary Inverse icon
					</button>
					<button
						className="home-main__btn btn__secondary--inverse btn__secondary--icon-after btn__secondary--icon-fas btn-padding"
						theme-icon="">
						Secondary Inverse icon
					</button>
					<button className="home-main__btn btn__secondary--inverse btn-padding">
						Secondary Inverse
					</button>
					<button
						className="home-main__btn btn__secondary--mini-inverse btn__secondary--icon-before btn__secondary--icon-fas btn-padding"
						theme-icon="">
						Secondary Mini Icon
					</button>
					<button
						className="home-main__btn btn__secondary--mini-inverse btn__secondary--icon-after btn__secondary--icon-fas btn-padding"
						theme-icon="">
						Secondary Mini Icon
					</button>
					<button className="home-main__btn btn__secondary--mini-inverse btn-padding">
						Secondary Mini
					</button>
				</div>
				<div className="home-main__actions--full">
					<button className="home-main__btn--full btn__primary">Primary default</button>
					<button className="home-main__btn--full btn__secondary">Secondary</button>
					<button className="home-main__btn--full btn__secondary--inverse">
						Secondary Inverse
					</button>
					<button className="home-main__btn--full btn__secondary--mini-inverse">
						Secondary Mini
					</button>
				</div>
			</main>
		</div>
	);
};
