import {IdParam} from '@acrelec-cloud/apico-cdk';
import {RestaurantObject, ProductObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';
import {useBrand} from 'src/hooks/brand.hook';

export const RestaurantAuthenticationPage = observer(() => {
	const {translate} = useTranslate();
	const history = useHistory();
	document.title = `${translate('RestaurantsPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

	const {
		basket: {isBasketEmpty, resetBasket},
		restaurant: {authenticateRestaurant, fetchRestaurant, setCurrentRestaurant},
	} = useStore();
	const {slugOrId} = useParams<IdParam>();
	const {darkLogo} = useBrand();

	// If the restaurant is not protected, redirect to the restaurant page
	useEffect(() => {
		fetchRestaurant(slugOrId).then(
			(restaurant: RestaurantObject) =>
				!restaurant.authenticationRequired && history.replace(`/restaurants/${slugOrId}`),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {register, handleSubmit} = useForm();
	const [hasError, setHasError] = useState<boolean>(false);
	const onSubmit = (data: any) => {
		const {authentication_code} = data;
		authenticateRestaurant(slugOrId, process.env.REACT_APP_PROTECTION_CODE, authentication_code)
			.then((result: ProductObject) => {
				if (hasError) {
					setHasError(false);
				}
				return result;
			})
			.then(() => fetchRestaurant(slugOrId))
			.then((restaurant: RestaurantObject) => {
				if (!isBasketEmpty) {
					resetBasket();
				}
				setCurrentRestaurant(restaurant);
				history.replace('/order');
			})
			.catch(() => {
				setHasError(true);
			});
	};

	return (
		<main className="restaurant-authentication">
			<div className="restaurant-authentication-content">
				<div className="restaurant-authentication-content__logo">
					<img src={darkLogo} alt="logo" />
				</div>
				<h1 className="restaurant-authentication-content__title">
					{translate('RestaurantAuthenticationPage.title')}
				</h1>
				<div className="restaurant-authentication-error">
					{hasError && <p>{translate('RestaurantAuthenticationPage.error_message')}</p>}
				</div>
				<form onSubmit={handleSubmit(onSubmit)} className="form">
					<div className="form__field">
						<input
							className="form__input txt-m"
							type="text"
							name="authentication_code"
							placeholder={translate(`RestaurantAuthenticationPage.authentication_code`)}
							ref={register({required: true})}
							id="authentication_code"
						/>
					</div>
					<input
						type="submit"
						className="form__btn btn__primary"
						value={translate(`RestaurantAuthenticationPage.button`)}
					/>
				</form>
				<div className="restaurant-authentication-link">
					<a href={process.env.REACT_APP_BRAND_WEBSITE}>
						{translate('RestaurantAuthenticationPage.redirection_message')}
						{process.env.REACT_APP_BRAND_WEBSITE_SHORTNAME}
					</a>
				</div>
			</div>
		</main>
	);
});
