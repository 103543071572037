import { observer } from 'mobx-react-lite';
import React from 'react';

import {MenuComponentItem} from 'src/components/Header/MenuComponent/MenuComponentItem';
import { useTranslate } from 'src/components/Languages/translate.hook';
import {usePopover} from 'src/components/Popover/popover.hook';
import { useStore } from 'src/contexts/store.context';


import {MenuAuth} from './MenuAuth';
import { MenuLanguage } from './MenuLanguage';

export const MenuComponent = observer(() => {
	const {closePopover} = usePopover();

	const {
		auth: {isLogged, guest},
		restaurant: {isUniqueRestaurant},
  } = useStore();
  const { translate } = useTranslate();

	return (
		<div className="home-menu">
			<MenuAuth />
			<div className="home-menu__links txt-xs" onClick={() => closePopover()}>
				{isLogged && <MenuComponentItem name="profile" link="/profile" />}
				<MenuComponentItem name="order" link="/order" />
				{(isLogged || !!guest) && <MenuComponentItem name="history" link="/order-history" />}
				<MenuComponentItem
					name={`${isUniqueRestaurant ? 'restaurant' : 'restaurants'}`}
					link="/restaurants"
				/>
				{process.env.REACT_APP_ALLERGENS_URL && <li className="menu-item-li">
					<a
						href={process.env.REACT_APP_ALLERGENS_URL}
						className="menu-item-li__link txt-s"
						target="_blank"
						rel="noreferrer"
					>
						{translate(`MenuComponent.allergens`)}
						<div className="round round--right"></div>
					</a>
				</li>}
			</div>
			<MenuLanguage />
		</div>
	);
});
