import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import { useStore } from 'src/contexts/store.context';
import {GlobalRouterModal} from 'src/routers/GlobalRouter';

interface CampaignChangeModalProps {
	status: GlobalRouterModal;
}

export const CampaignChangeModal = observer(({status}: CampaignChangeModalProps) => {
	const {translate} = useTranslate();
	const {order: {emptyConflicts, conflictList}} = useStore();
	const history = useHistory();
	const [open, setOpen] = useState<boolean>(
		status === GlobalRouterModal.CAMPAIGN_CHANGE ? true : false,
	);

	useEffect(() => setOpen(status === GlobalRouterModal.CAMPAIGN_CHANGE ? true : false), [status]);

	const onClose = useCallback(() => {
		history.push('/order');
		emptyConflicts();
		setOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Modal open={open} onClose={() => onClose()}>
			<div className="basket-updated">
				<div className="basket-updated__close close" onClick={() => onClose()}></div>
				<p className="basket-updated__text txt-center txt-bold txt-m">
					{translate('BasketUpdatedModal.message')} 
				</p>
				<ul className="basket-updated__conflicts-list">
					{conflictList.map((productName: string, index: number) => <li className="txt-l" key={index}>{productName}</li>)}
				</ul>
				<button
					className="basket-updated__btn btn__secondary--inverse btn-padding"
					onClick={() => onClose()}>
					{translate('BasketUpdatedModal.button')}
				</button>
			</div>
		</Modal>
	);
});
