import { useStore } from "src/contexts/store.context";

interface HandleChangesProps {
  code: number;
  qty: number;
  parent: number[];
}

export interface RadioButtonProps {
  handleSelect: (item: HandleChangesProps) => void;
}

export const useRadioButton = (): RadioButtonProps => {
  const {
    customization: { setPartOptionQty, setRequiredQty },
  } = useStore();

  const handleSelect = ({ code, qty, parent }: HandleChangesProps): void => {
    setPartOptionQty(code, qty, parent);
    setRequiredQty(parent, qty);
  };

  return { handleSelect };
};
