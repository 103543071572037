import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useQuantityPicker} from 'src/components/UIElements/QuantityPicker/quantity-picker.hook';
import {
	QuantityMessageProps,
	QuantityPicker,
} from 'src/components/UIElements/QuantityPicker/QuantityPicker';
import {WarningMessage} from 'src/components/UIElements/WarningMessage';
import {useStore} from 'src/contexts/store.context';
import {getUniqueId} from 'src/utils/uniqueId';

export const ProductActions = observer(() => {
	const uniqueId = useRef(getUniqueId());
	const {translate} = useTranslate();
	const {handleQuantityChange, maxQuantityReached} = useQuantityPicker();

	const {
		customization: {quantity},
	} = useStore();

	return (
		<div>
			<div className="modifiers__qty-wrapper">
				{maxQuantityReached && <WarningMessage message={translate(`App.messages.qty_max`)} />}
				<QuantityPicker
					id={uniqueId.current}
					value={quantity}
          max={99999} // Unlimited quantity
					onChange={({id, value, min, max, operation}: QuantityMessageProps) =>
						handleQuantityChange({
							item: {id: id!, qty: value!, minimum: min, maximum: max, operation},
							type: 'product',
						})
					}
				/>
			</div>
		</div>
	);
});
