import {BasketItemProps} from '@acrelec-cloud/apico-cdk';
import {ProductType} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {Dispatch, SetStateAction, useCallback, useRef} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {
	HandleQuantityChangeProps,
	useQuantityPicker,
} from 'src/components/UIElements/QuantityPicker/quantity-picker.hook';
import {
	QuantityMessageProps,
	QuantityPicker,
} from 'src/components/UIElements/QuantityPicker/QuantityPicker';
import {WarningMessage} from 'src/components/UIElements/WarningMessage';
import {useMoney} from 'src/hooks/money.hook';
import {getUniqueId} from 'src/utils/uniqueId';

export interface BasketItemBottomProps {
	id: string;
	code: number;
	displayActionButtons: boolean;
	quantity: number;
	totalPrice: number;
	modalState: [boolean, Dispatch<SetStateAction<boolean>>];
	type: string;
	item: BasketItemProps;
}

export const BasketItemBottom = observer((props: BasketItemBottomProps) => {
	const {
		displayActionButtons,
		quantity,
		totalPrice,
		type,
		item,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		modalState: [modalIsOpen, setModalIsOpen],
	} = props;
	const [formatMoney] = useMoney();
	const {translate} = useTranslate();
	const {handleQuantityChange, maxQuantityReached} = useQuantityPicker();
	const uniqueId = useRef(getUniqueId());
	const isCoupon = type === ProductType.COUPON;

	const validateChange = useCallback(
		(result: HandleQuantityChangeProps) => {
			if (result.item.qty > 0) {
				handleQuantityChange(result);
			} else {
				setModalIsOpen(true);
			}
		},
		[handleQuantityChange, setModalIsOpen],
	);

	return (
		<div className="basket-item__bottom">
			{displayActionButtons && !isCoupon && (
				<div className="basket-item__bottom--quantity">
					<h2 className="txt-secondary-dark">{translate('Basket.quantity')}</h2>
					<QuantityPicker
						value={quantity}
						min={0}
            max={99999} // Unlimited quantity
						hideButtonOnQuantityReached={true}
						id={uniqueId.current}
						onChange={({id, value, min, max, operation}: QuantityMessageProps) =>
							validateChange({
								item: {
									id: props.id,
									qty: value!,
									minimum: min,
									maximum: max,
									operation,
								},
								type: 'basket',
							})
						}
					/>
				</div>
			)}
			{maxQuantityReached && <WarningMessage message={translate(`App.messages.qty_max`)} />}
			{isCoupon ? (
				<div className="basket-item__bottom--total txt-bold txt-primary">{item.description}</div>
			) : (
				<div className="basket-item__bottom--total txt-bold txt-primary">
					{formatMoney(totalPrice)}
				</div>
			)}
		</div>
	);
});
