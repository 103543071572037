import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';
import {useStore} from 'src/contexts/store.context';

export const IncompatiblePriceModal = observer(({open, onClose}: CustomModalProps) => {
	const {translate} = useTranslate();
	const history = useHistory();

	const {
		basket: {resetBasket},
	} = useStore();

	const handleCancel = () => {
		resetBasket();
		history.push('/order');
	};

	return (
		<Modal open={open} onClose={onClose}>
			<div className="payment-modal">
				<h1 className="txt-center txt-h1 txt-error">{translate('IncompatiblePriceModal.title')}</h1>
				<div className="payment-modal__check">
					<div className="check check--failed"></div>
				</div>
				<p className="txt-center">{translate('IncompatiblePriceModal.content')}</p>
				<div className="payment-modal__actions">
					<button
						className="payment-modal__btn payment-modal__btn--cancel btn__secondary"
						onClick={() => handleCancel()}>
						{translate('IncompatiblePriceModal.button.cancel')}
					</button>
				</div>
			</div>
		</Modal>
	);
});
