import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import store from 'store';

import {DataProtectionCheckbox} from 'src/components/Form/DataProtectionCheckbox';
import {
	emailPattern,
	stringPattern,
	passwordPattern,
	phonePattern,
} from 'src/components/Form/validator-form.config';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {useFormErrors} from 'src/hooks/form-errors.hook';

export interface FormProfileData {
	confirm_password: string;
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	phoneNumber?: string;
}
interface FormProfileComponentProps {
	onSubmit: (data: FormProfileData) => void;
	protectionPolicy: boolean;
	setProtectionPolicy: (boolean: boolean) => void;
	offersMessage: boolean;
	setOffersMessage: (boolean: boolean) => void;
}

export const FormProfileComponent = (props: FormProfileComponentProps) => {
	const {onSubmit, protectionPolicy, setProtectionPolicy, offersMessage, setOffersMessage} = props;
	const {translate} = useTranslate();
	const {handleErrors, errorClass} = useFormErrors();
	const [passwordShown, setPasswordShown] = useState(false);

	const togglePasswordVisiblity = (e: any) => {
		e.preventDefault();
		setPasswordShown(passwordShown ? false : true);
	};

	const {register, handleSubmit, errors, watch} = useForm<FormProfileData>({
		mode: 'onChange',
	});

	const userEmail = store.get('user') ? store.get('user').email : null;

	const submit = (data: FormProfileData) => {
		onSubmit(data);
	};

	return (
		<form onSubmit={handleSubmit(submit)} className="form">
			<div className="form__field form__email">
				<label htmlFor="email">{translate(`Form.editProfileForm.email`)}</label>
				<input
					className={`form__input${errorClass(errors?.email)}`}
					type="text"
					name="email"
					defaultValue={userEmail}
					placeholder={translate(`Form.editProfileForm.placeholder.email`)}
					ref={register({required: true, pattern: emailPattern})}
					id="email"
				/>
			</div>
			{errors.email && handleErrors(errors.email.type, 'email')}
			<div className="form__field form__first-name">
				<label htmlFor="firstname">{translate(`Form.editProfileForm.first_name`)}</label>
				<input
					className={`form__input${errorClass(errors?.firstName)}`}
					type="text"
					name="firstName"
					placeholder={translate(`Form.editProfileForm.placeholder.first_name`)}
					ref={register({required: true, pattern: stringPattern})}
					id="firstname"
				/>
			</div>
			{errors.firstName && handleErrors(errors.firstName.type, 'firstName')}
			<div className="form__field form__last-name">
				<label htmlFor="lastname">{translate(`Form.editProfileForm.last_name`)}</label>
				<input
					className={`form__input${errorClass(errors?.lastName)}`}
					type="text"
					name="lastName"
					placeholder={translate(`Form.editProfileForm.placeholder.last_name`)}
					ref={register({required: true, pattern: stringPattern})}
					id="lastname"
				/>
				{errors.lastName && handleErrors(errors.lastName.type, 'lastName')}
			</div>
			<div className="form__field form__phone">
				<label htmlFor="lastname">{translate(`Form.editProfileForm.phone_number`)}</label>
				<input
					className={`form__input${errorClass(errors?.phoneNumber)}`}
					type="text"
					name="phoneNumber"
					ref={register({pattern: phonePattern})}
					id="phoneNumber"
				/>
				{errors.phoneNumber && handleErrors(errors.phoneNumber.type, 'phone')}
			</div>
			<div className="form__field form__password">
				<label htmlFor="password">{translate(`Form.editProfileForm.password`)}</label>
				<div className="form__field--eye">
					<input
						className={`form__input${errorClass(errors?.password)}`}
						type={passwordShown ? 'text' : 'password'}
						name="password"
						placeholder={translate(`Form.editProfileForm.placeholder.password`)}
						maxLength={31}
						ref={register({
							required: true,
							minLength: 8,
							maxLength: 30,
							pattern: passwordPattern,
						})}
						id="password"
					/>
					<i
						className={
							passwordShown
								? 'form__input__password_icon--unshow'
								: 'form__input__password_icon--show'
						}
						onClick={togglePasswordVisiblity}
					/>
				</div>
			</div>
			{errors.password && handleErrors(errors.password.type, 'password')}
			<div className="form__field form__confirm-password">
				<label htmlFor="confirmPassword">
					{translate(`Form.editProfileForm.confirm_password`)}
				</label>
				<div className="form__field--eye">
					<input
						className={`form__input${errorClass(errors?.confirm_password)}`}
						type={passwordShown ? 'text' : 'password'}
						name="confirm_password"
						placeholder={translate(`Form.editProfileForm.placeholder.confirm_password`)}
						maxLength={31}
						ref={register({
							required: true,
							validate: (value) => value === watch('password', value),
						})}
						id="confirmPassword"
					/>
					<i
						className={
							passwordShown
								? 'form__input__password_icon--unshow'
								: 'form__input__password_icon--show'
						}
						onClick={togglePasswordVisiblity}
					/>
				</div>
			</div>
			{errors.confirm_password && handleErrors(errors.confirm_password.type, 'confirm_password')}
			<div className="form__terms profile__terms">
				<DataProtectionCheckbox
					protectionPolicy={protectionPolicy}
					setProtectionPolicy={setProtectionPolicy}
					offersMessage={offersMessage}
					setOffersMessage={setOffersMessage}
				/>
			</div>
			<button className="form__btn btn__primary" type="submit">
				{translate(`Profile.confirm`)}
			</button>

			<p className="txt-italic txt-s txt-center">
				{translate('ProfileCreationPage.legal_message')}{' '}
				<span
					onClick={() => window.open(process.env.REACT_APP_TERM_OF_USE_PAGE, '_blank')}
					className="form__terms--link">
					{translate('ProfileCreationPage.terms_of_use')}
				</span>
			</p>
		</form>
	);
};
