import {observer} from 'mobx-react-lite';
import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {OrderContainerModal} from 'src/components/Order/OrderContainer';

interface FailDeleteOrderModalProps {
	status: OrderContainerModal;
	setStatus: (status: OrderContainerModal) => void;
}

export const FailDeleteOrderModal = observer(({status, setStatus}: FailDeleteOrderModalProps) => {
	const {translate} = useTranslate();
	const history = useHistory();
	const [open, setOpen] = useState<boolean>(
		status === OrderContainerModal.CANCEL_FAIL ? true : false,
	);

	useEffect(() => setOpen(status === OrderContainerModal.CANCEL_FAIL ? true : false), [status]);

	const onCloseModal = useCallback(() => {
		setStatus(OrderContainerModal.NULL);
		setTimeout(() => history.push('/order'), 100);
	}, [history, setStatus]);

	return (
		<Modal open={open} onClose={() => onCloseModal()}>
			<div className="payment-modal txt-center">
				<h1 className={`payment-modal__tite txt-center txt-h1 txt-error`}>
					{translate(`DeleteOrderModal.fail_title`)}
				</h1>
				<div className="payment-modal__check">
					<div className="check check--failed"></div>
				</div>
				<p className="payment-modal__content txt-center">
					{translate(`DeleteOrderModal.fail_text`)}
				</p>
				<button
					className="payment-modal__btn payment-modal__btn--continue btn__secondary bg-secondary"
					onClick={() => {
						onCloseModal();
					}}>
					{translate(`CancelOrderPage.button.newOrder`)}
				</button>
			</div>
		</Modal>
	);
});
