import {OrderObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {TypeOfChange} from 'src/components/Order/EditOrder/EditOrderContent';
import {OrderContainer} from 'src/components/Order/OrderContainer';

export interface OrderReadyToEditProps {
	currentOrder: OrderObject;
  EditType: TypeOfChange;
}

export const OrderReadyToEdit = observer(({currentOrder, EditType}: OrderReadyToEditProps) => {
	const {translate} = useTranslate();

	return (
		<div className="current-order__div">
			{EditType === TypeOfChange.CANCEL_ORDER && 
        <p className="current-order__text txt-xleft">{translate('CancelOrderPage.text')}</p>}
			<div className="current-order__container">
				<OrderContainer order={currentOrder} 
          cancellation={EditType === TypeOfChange.CANCEL_ORDER ? true : false} 
          isPickUpModification={EditType === TypeOfChange.PICKUP_TIME ? true : false} 
        />
			</div>
		</div>
	);
});
