import {
  HandleChangesProps,
  RequiredPartsType,
} from "@acrelec-cloud/apico-cdk";
import { useState } from "react";

import { findCurrentPartByParent } from "src/components/Products/Parts/utils/find-current-part.util";
import { useStore } from "src/contexts/store.context";

export interface HandleQuantityChangeProps {
  /**
   * If type = 'product' or 'basket', the code param is required
   * @param item = id, qty, minimum, maximum, code?
   * @param type = "basket" | "product" | "part" | undefined;
   */
  item: HandleChangesProps;
  type: "basket" | "product" | "part" | undefined;
}
export interface UseQuantityPicker {
  handleQuantityChange: ({ item, type }: HandleQuantityChangeProps) => void;
  maxQuantityReached: boolean;
  resetMaxQuantityMessage: () => void;
}

export const useQuantityPicker = (): UseQuantityPicker => {
  const [maxQuantityReached, setMaxQuantityReached] = useState<boolean>(false);

  const {
    basket: { updateProductQuantity },
    customization: {
      setPartQty,
      setProductQty,
      setRequiredQty,
      parts,
      required,
    },
  } = useStore();

  const resetMaxQuantityMessage = () => setMaxQuantityReached(false);

  const currentParentQty = (
    parent: number[],
    qty: number,
    operation: "remove" | "add"
  ) => {
    const parentOfParent = parent.slice(0);
    const partCode = parentOfParent.pop();
    const currentParent = findCurrentPartByParent(required, {
      code: partCode,
      parent: parentOfParent,
    });

    if (currentParent) {
      if (operation === "add") return currentParent.qty + 1;
      if (operation === "remove") return currentParent.qty - 1;
    }

    return qty;
  };

  const basketQtyChange = (item: HandleChangesProps): void => {
    item.qty > 0 && updateProductQuantity(item.id!, item.qty);
  };

  const productQtyChange = (item: HandleChangesProps): void => {
    (item.qty || item.qty === 0) && setProductQty(item.qty);
  };

  const partQtyChange = (item: HandleChangesProps): void => {
    if (item.qty >= 0) {
      setPartQty(item.code!, item.qty, item.parent!);
      const parentQty = currentParentQty(
        item.parent!,
        item.qty,
        item.operation
      );
      const requiredParts = required.filter(
        (part: RequiredPartsType) => part.partRequired
      );
      const partIsRequired = requiredParts.filter(
        ({ code }: RequiredPartsType) =>
          item.parent.includes(code) || item.code === code
      );
      partIsRequired.length > 0 && setRequiredQty(item.parent!, parentQty);
    }
  };

  const qtyChangeHandler = {
    basket: (item: HandleChangesProps) => basketQtyChange(item),
    product: (item: HandleChangesProps) => productQtyChange(item),
    part: (item: HandleChangesProps) => partQtyChange(item),
  };

  const handleQuantityChange = ({
    item,
    type,
  }: HandleQuantityChangeProps): void => {
    // 1. set quantities
    type && qtyChangeHandler[type](item);

    // 2. display warning message
    const currentPart = findCurrentPartByParent(parts, {
      code: item.code,
      parent: item.parent,
    });
    // Only displays the warning message when achieve the current part limit
    const maxReached = currentPart
      ? item.qty === currentPart?.max
      : item.qty === item.maximum;

    setMaxQuantityReached(maxReached ? true : false);
  };

  return { handleQuantityChange, maxQuantityReached, resetMaxQuantityMessage };
};
