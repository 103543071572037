import {BasketProductModelType} from '@acrelec-cloud/apico-cdk';
import {
	OrderItemObject,
	OrderObject,
	ProductType,
	RestaurantOutageProductsObject,
} from '@acrelec-cloud/apico-shared';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';

import {useStore} from 'src/contexts/store.context';

import {useTranslate} from '../Languages/translate.hook';
import {LoadingSpinner} from '../LoadingSpinner/LoadingSpinner';
import {DifferentPriceModal} from '../Modal/CustomModals/ReorderModal/DifferentPriceModal';
import {DifferentRestaurantModal} from '../Modal/CustomModals/ReorderModal/DifferentRestaurantModal';
import {NoAvailableProductsModal} from '../Modal/CustomModals/ReorderModal/NoAvailableProductsModal';
import {ReorderSuccessModal} from '../Modal/CustomModals/ReorderModal/ReorderSuccessModal';
import {UnavailableProductModal} from '../Modal/CustomModals/ReorderModal/UnavailableProductModal';

enum ModalsTypes {
	NO_PRODUCTS = 'NO_PRODUCTS',
	DIFFERENT_RESTAURANT = 'DIFFERENT_RESTAURANT',
	DIFFERENT_PRICE = 'DIFFERENT_PRICE',
	SUCCESS = 'SUCCESS',
	UNAVAILABLE_PRODUCT = 'UNAVAILABLE_PRODUCT',
}

interface ReorderButtonProps {
	order: OrderObject;
}
export const ReorderButton = observer((props: ReorderButtonProps) => {
	const {order} = props;
	const {id, restaurantId} = order;
	const {translate} = useTranslate();
	const {
		basket: {addReorderProduct},
		customization: {fetchProduct},
		order: {fetchOrderItems, transformOrderProducts},
		restaurant: {currentRestaurant},
	} = useStore();

	const selectedOrderItemParts: {code: number; quantity: number; parents: number[]}[] = [];

	const [modalOpen, setModalOpen] = useState<ModalsTypes | null>(null);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	let [unavailableProducts, setUnavailableProducts] = useState<OrderItemObject[]>([]);
	let availableProducts: OrderItemObject[] = [];
	const [newBasketProducts, setNewBasketProducts] = useState<BasketProductModelType[]>([]);
	const [prices, setPrices] = useState({
		newOrderTotal: 0,
		newBasketTotal: 0,
	});

	// Start reordering : Check if the current restaurant is the same
	// If not, display a modal to inform the user that he has to change the restaurant
	// If yes, fetch order items and get all products parts then check their availability
	const handleReorder = () => {
		setIsButtonLoading(true);
		if (restaurantId !== currentRestaurant.id) {
			setIsButtonLoading(false);
			return setModalOpen(ModalsTypes.DIFFERENT_RESTAURANT);
		}
		fetchOrderItems(id).then((orderItems: OrderItemObject[]) => {
			orderItems.forEach((orderItem: OrderItemObject) =>
				getOrderItemParts(orderItem.children, [orderItem.productCode]),
			);
			return fetchProductsAvailability(orderItems);
		});
	};

	// Change order parts to simple object array to be able to check if there is outages inside
	const getOrderItemParts = (orderChildren: OrderItemObject[], parents: number[]) => {
		orderChildren.forEach((orderChild) => {
			const newSelectOrderItem = {
				parents,
				code: orderChild.productCode,
				quantity: orderChild.quantity,
			};
			selectedOrderItemParts.push(newSelectOrderItem);
			// Get a kind of tree of parents to be able to find the good product
			if (orderChild.children) {
				getOrderItemParts(orderChild.children, [...parents, orderChild.productCode]);
			}
		});
	};

	// Check if products are available or not
	const fetchProductsAvailability = async (orderItems: OrderItemObject[]) => {
		await Promise.all(
			orderItems.map((orderItem: OrderItemObject) => {
				const isProductUnavailable = currentRestaurant.outageProducts.some(
					(outage: RestaurantOutageProductsObject) => outage.productCode === orderItem.productCode,
				);
				return isProductUnavailable
					? unavailableProducts.push(orderItem)
					: availableProducts.push(orderItem);
			}),
		);

		checkPrices();
	};

	// Check if prices of the same to be able to inform the user
	const checkPrices = async () => {
		return await Promise.all(
			availableProducts.map(async (availableProduct: OrderItemObject) => {
				return await manageTransformOrderProducts(availableProduct);
			}),
		).then((products: BasketProductModelType[]) => {
			if (availableProducts.length < 1) {
				setIsButtonLoading(false);
				return setModalOpen(ModalsTypes.NO_PRODUCTS);
			}
			if (currentRestaurant.campaignId !== order.campaignId) {
				const newOrderTotal = availableProducts.reduce(
					(previousValue, currentValue) => previousValue + currentValue.price,
					0,
				);
				const newBasketTotal = products.reduce(
					(previousValue: number, currentValue: BasketProductModelType) =>
						previousValue + currentValue.price * currentValue.quantity,
					0,
				);

				setPrices({
					newOrderTotal,
					newBasketTotal,
				});

				if (unavailableProducts.length > 0) {
					setIsButtonLoading(false);
					return setModalOpen(ModalsTypes.UNAVAILABLE_PRODUCT);
				} else if (newBasketTotal !== newOrderTotal) {
					setIsButtonLoading(false);
					return setModalOpen(ModalsTypes.DIFFERENT_PRICE);
				} else {
					return reorder();
				}
			}
		});
	};

	// Change Order product to Basket product
	const manageTransformOrderProducts = async (product: OrderItemObject) => {
		const currentProduct = await fetchProduct(product.productCode, false).catch(() => {
			availableProducts = availableProducts.filter(
				(availableProduct) => availableProduct.productCode !== product.productCode,
			);
			unavailableProducts.push(product);
		});
		if (!currentProduct) return;

		const transformedProduct = await transformOrderProducts(
			product,
			currentProduct,
			selectedOrderItemParts,
		);
		newBasketProducts.push(transformedProduct);
		return transformedProduct;
	};

	const reorder = () => {
		newBasketProducts.forEach((newBasketProduct: BasketProductModelType) => {
			if (newBasketProduct.type !== ProductType.COUPON) {
        const maxQtyProduct = Number(process.env.REACT_APP_MAX_BASKET_PRODUCT_QTY);
				addReorderProduct(newBasketProduct, maxQtyProduct);
			}
		});
		setModalOpen(ModalsTypes.SUCCESS);
		setIsButtonLoading(false);
		setNewBasketProducts([]);
		setUnavailableProducts([]);
	};

	// Reset everything when close a modal
	const onCloseModal = () => {
		setModalOpen(null);
		setNewBasketProducts([]);
		setUnavailableProducts([]);
	};

	return (
		<>
			<button className="btn__secondary order-reorder" onClick={handleReorder}>
				{!isButtonLoading ? (
					translate('OrderPage.reorder')
				) : (
					<LoadingSpinner height={'14px'} width={'14px'} />
				)}
			</button>
			<NoAvailableProductsModal
				open={modalOpen === ModalsTypes.NO_PRODUCTS}
				onClose={onCloseModal}
			/>
			<DifferentRestaurantModal
				open={modalOpen === ModalsTypes.DIFFERENT_RESTAURANT}
				onClose={onCloseModal}
			/>
			<DifferentPriceModal
				open={modalOpen === ModalsTypes.DIFFERENT_PRICE}
				onClose={onCloseModal}
				newPrice={prices.newBasketTotal}
				previousPrice={prices.newOrderTotal}
				callback={() => reorder()}
			/>
			<ReorderSuccessModal open={modalOpen === ModalsTypes.SUCCESS} onClose={onCloseModal} />
			<UnavailableProductModal
				open={modalOpen === ModalsTypes.UNAVAILABLE_PRODUCT}
				onClose={onCloseModal}
				callback={() => reorder()}
				unavailable={unavailableProducts}
			/>
		</>
	);
});
