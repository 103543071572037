import I18n from "i18n-js";

import { LanguagesModelType } from "src/models/language.model";

import { en } from "./locales/en";
import { fr } from "./locales/fr";

I18n.fallbacks = true;
I18n.translations = { en, fr };
export const availableLanguages: LanguagesModelType[] = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "fr",
    name: "Français",
  },
];

export default I18n;
