import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';

import {useStore} from 'src/contexts/store.context';

import {useTranslate} from '../Languages/translate.hook';

export const ProfileRecap = observer(() => {
	const {translate} = useTranslate();
	const {
		auth: {profile},
	} = useStore();

	return (
		<div className="user-profile">
			<h2 className="txt-h2">{translate('Profile.title.profile_page')}</h2>
			<Link to="/profile/update">
				<button
					className="btn-round__primary"
					theme-icon=""
					aria-label={translate(`ProfilePage.edit_profile`)}
				/>
			</Link>
			<p>
				<span className="txt-bold txt-secondary">{translate(`ProfilePage.first_name`)} : </span>
				{profile?.firstName}
			</p>
			<p>
				<span className="txt-bold txt-secondary">{translate(`ProfilePage.last_name`)} : </span>
				{profile?.lastName}
			</p>
			<p>
				<span className="txt-bold txt-secondary">{translate(`ProfilePage.email`)} : </span>
				{profile?.email}
			</p>
			{profile?.phoneNumber && (
				<p>
					<span className="txt-bold txt-secondary">{translate(`ProfilePage.phone_number`)} : </span>
					{profile?.phoneNumber}
				</p>
			)}
		</div>
	);
});
