import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router';

import {useTranslate} from 'src/components/Languages/translate.hook';

export const PaymentRefused = observer(() => {
	const {translate} = useTranslate();
	const history = useHistory();

	return (
		<main className="payment-status-page">
			<div>
				<h1 className="txt-center txt-h1 txt-error txt-xxl">{translate('PaymentRefused.title')}</h1>
				<p className="txt-center txt-l">{translate('PaymentRefused.message')}</p>
				<button
					className="home-main__btn--full btn__primary payment-status-page__button"
					onClick={() => history.replace('/checkout')}>
					{translate('PaymentRefused.button')}
				</button>
			</div>
		</main>
	);
});
