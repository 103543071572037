import {NavigationPageObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React from 'react';
import Dropdown, {Option} from 'react-dropdown';
import {useHistory} from 'react-router';

import {useStore} from 'src/contexts/store.context';

export const CategoryNavigationMobile = observer(() => {
	const history = useHistory();
	const {
		navigation: {currentNavigation, navigation},
	} = useStore();

	const availableOptions: Option[] = navigation
		? navigation?.map((category: NavigationPageObject) => ({
				value: category.id.toString(),
				label: category.name,
		  }))
		: [];

	const handleChange = (options: Option) => {
		history.push(`/order/${options.value}`);
	};

	const defaultOption = availableOptions.find(
		(option) => option.value === currentNavigation?.toString(),
	);

	return (
		<div className="navigation__container">
			{navigation && (
				<Dropdown
					options={availableOptions}
					onChange={(t: Option) => handleChange(t)}
					value={defaultOption}
					className="navigation__dropdown"
					controlClassName="navigation__control"
					placeholderClassName="navigation__placeholder"
					menuClassName="navigation__menu"
					arrowClosed={<span className="arrow arrow--closed" />}
					arrowOpen={<span className="arrow arrow--open" />}
				/>
			)}
		</div>
	);
});
