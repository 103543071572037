import { OrderItemObject, ProductType } from "@acrelec-cloud/apico-sdk";
import { sumBy } from "lodash";

export const getOrderTotal = (price: number, taxes: number) => {
  const totalPrice = price + taxes;
  return Math.round(totalPrice * 100) / 100;
};

export const getOrderTotalBeforeDiscount = (items: OrderItemObject[]): number => {
  return sumBy(items, (item) => {
    if (item.productSnapshot.type === ProductType.REGULAR || item.productSnapshot.type === ProductType.COMBO) {
      return item.price + getOrderTotalBeforeDiscount(item.children) * item.quantity;
    }
    return item.price + getOrderTotalBeforeDiscount(item.children);
  });
};
