import {observer} from 'mobx-react-lite';
import React from 'react';
import {useParams} from 'react-router-dom';

import {StudySurveyForm} from 'src/components/Form/StudySurveyForm';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {GoBack} from 'src/components/Order/GoBack';

export interface ReportProblemPageParams {
	orderId: string;
}

export const ReportProblemPage = observer(() => {
	const {translate} = useTranslate();
	const {orderId} = useParams<ReportProblemPageParams>();
	document.title = `${translate('StudySurveyFormPage.title')} | ${
		process.env.REACT_APP_BRAND_NAME
	}`;

	return (
		<main className="current-order-main">
			<div className="current-order">
				<GoBack />
				<div className="page-title txt-xxl">{translate('StudySurveyFormPage.title')}</div>
				<StudySurveyForm isReportProblem={true} orderId={orderId} />
			</div>
		</main>
	);
});
