import {observer} from 'mobx-react-lite';
import React from 'react';

interface RadioButtonProps {
	value: number;
	onSelect: (event: any) => void;
}
export const RadioButton = observer(({value, onSelect}: RadioButtonProps) => {
	return (
		<div>
			<button
				onClick={(e) => onSelect(e)}
				className={`radio-button${value === 1 ? ' active' : ''}`}
			/>
		</div>
	);
});
