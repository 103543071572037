import {NeptingPaymentObject} from '@acrelec-cloud/apico-sdk';
import Base64 from 'base-64';
import React, {useEffect, useRef} from 'react';

import {useStore} from 'src/contexts/store.context';

export const NeptingForm = (props: {payment: NeptingPaymentObject; paymentEnrolment: boolean}) => {
	const {payment, paymentEnrolment} = props;
	const formRef = useRef<any>();
	const enrolmentValue = paymentEnrolment === true ? 1 : 0;

	const {
		basket: {resetBasket},
	} = useStore();

	useEffect(() => {
		formRef.current.submit();
	}, [resetBasket]);

	return (
		<form
			id="indexForm"
			action={process.env.REACT_APP_NEPTING_URL}
			method="post"
			ref={formRef}>
			<input
				type="text"
				id="nep_MerchantID"
				placeholder="nep_MerchantID"
				name="nep_MerchantID"
				defaultValue={Base64.encode(payment.data.nep_MerchantID)}
			/>

			<input
				type="text"
				id="nep_TransactionID"
				placeholder="nep_TransactionID"
				name="nep_TransactionID"
				defaultValue={Base64.encode(payment.data.nep_TransactionID)}
			/>

			<input
				type="text"
				id="nep_Amount"
				placeholder="Montant"
				name="nep_Amount"
				defaultValue={payment.data.nep_Amount}
			/>

			<input
				type="text"
				id="input3"
				placeholder="Devise"
				name="nep_CurrencyAlpha"
				disabled
				defaultValue="GBP"
			/>

			<input
				type="text"
				name="nep_PartialAmountEnabled"
				id="nep_PartialAmountEnabled"
				placeholder="Montant partiel"
				defaultValue=""
			/>

			<input type="text" id="input3ds" placeholder="3DS" name="nep_3DSMode" defaultValue="0" />

			<input
				type="text"
				id="inputIpnMode"
				placeholder="IPN Mode"
				name="nep_IpnMode"
				defaultValue="4"
			/>

			<input
				type="checkbox"
				id="inputDisplayMerchantLabel"
				name="nep_DisplayMerchantLabel"
				defaultChecked
				defaultValue="1"
			/>

			<input
				type="text"
				id="inputIpnMode"
				placeholder="PayByLink"
				name="nep_PayByLink"
				defaultValue="123456"
			/>

			<input
				type="hidden"
				id="nep_TransactionType"
				name="nep_TransactionType"
				value="ZGViaXRBTGl2cmFpc29u"
			/>

			<input
				type="text"
				id="nep_PaymentUrl"
				placeholder="Payment URL Epay"
				defaultValue="https://qualif.nepting.com/epay/payment"
			/>

			<input
				type="text"
				id="inputCustomPageBackground"
				placeholder="Couleur de fond de la page"
				name="nep_CustomPageBackground"
				defaultValue=""
			/>

			<input
				type="text"
				id="inputCustomButtonBackground"
				placeholder="Couleur de fond du bouton payer"
				name="nep_CustomButtonBackground"
				defaultValue=""
			/>

			<textarea
				id="inputPrivateData"
				placeholder="Private data"
				name="nep_MerchantPrivateData"></textarea>

			<textarea id="inputDesc" placeholder="descriptif du panier" name="nep_Description"></textarea>

			<input
				type="hidden"
				id="nep_MerchantLabel"
				name="nep_MerchantLabel"
				defaultValue="Qm91dGlxdWUgZGUgZGVtbyBOZXB0aW5n"
			/>
			<input type="hidden" id="nep_CurrencyAlpha" name="nep_CurrencyAlpha" defaultValue="null" />
			<input
				type="hidden"
				id="paymentURL"
				name="paymentURL"
				defaultValue="aHR0cHM6Ly9xdWFsaWYubmVwdGluZy5jb20vZXBheS9wYXltZW50"
			/>

			<input
				type="hidden"
				id="nep_MerchantReference"
				name="nep_MerchantReference"
				defaultValue={Base64.encode(payment.data.nep_TransactionID)}
			/>
			<input
				type="hidden"
				id="nep_MerchantDateTime"
				name="nep_MerchantDateTime"
				defaultValue="20210607-123029"
			/>
			<input type="hidden" id="nep_Mode" name="nep_Mode" defaultValue={process.env.REACT_APP_NEPTING_ENV_MODE} />

			<input type="hidden" id="nep_Currency" name="nep_Currency" defaultValue="978" />
			<input type="hidden" id="nep_Fraction" name="nep_Fraction" defaultValue="null" />

			<input type="hidden" id="nep_Enrolment" name="nep_Enrolment" defaultValue={enrolmentValue} />

			<input
				type="hidden"
				id="nep_Email"
				name="Xnep_Email"
				defaultValue="dGVzdEBuZXB0aW5nLmNvbW1l"
			/>
			<input type="hidden" id="nep_Name" name="Xnep_Name" defaultValue="Tmljb2xhcw==" />
			<input
				type="hidden"
				id="nep_Address"
				name="Xnep_Address"
				defaultValue="MTggcnVlIEF1Z3VzdGUgQ29tdGU="
			/>
			<input
				type="hidden"
				id="nep_UrlSuccess"
				name="nep_UrlSuccess"
				defaultValue={Base64.encode(payment.data.nep_UrlSuccess)}
			/>
			<input
				type="hidden"
				id="nep_UrlError"
				name="nep_UrlError"
				defaultValue={Base64.encode(payment.data.nep_UrlError)}
			/>
			<input
				type="hidden"
				id="nep_UrlRefused"
				name="nep_UrlRefused"
				defaultValue={Base64.encode(payment.data.nep_UrlRefused)}
			/>
			<input
				type="hidden"
				id="nep_UrlCancel"
				name="nep_UrlCancel"
				defaultValue={Base64.encode(payment.data.nep_UrlCancel)}
			/>
			<input
				id="nep_UrlStatus"
				name="nep_UrlStatus"
				defaultValue={Base64.encode(payment.data.nep_UrlStatus)}
			/>
			<input type="hidden" id="nep_APIVersion" name="nep_APIVersion" defaultValue="MS4w" />
			<input type="hidden" id="nep_Mode" name="nep_Mode" defaultValue={process.env.REACT_APP_NEPTING_ENV_MODE} />
			<input type="hidden" id="nep_Sign" name="nep_Sign" defaultValue={payment.signature} />
		</form>
	);
};
