import React from 'react';

import {useImage} from 'src/hooks/image.hook';

interface LoadingSpinnerProps {
	src: string;
}

export const SliderImage = (props: LoadingSpinnerProps) => {
	const {src} = props;
	const {image} = useImage(src);
	return <img src={image} alt="banner" className="swiper-slide__image" />;
};
