import {IdParam, RequiredPartsModel} from '@acrelec-cloud/apico-cdk';
import {ProductObject, ProductType, RestaurantOutageProductsObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Portal} from 'react-portal';
import {useHistory, useParams} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {ActionType, AddToCartButton} from 'src/components/Products/AddToCart/AddToCartButton';
import {useCategoryNavigation} from 'src/components/Products/Navigation/category-navigation.hook';
import {PartsContainer} from 'src/components/Products/Parts/PartsContainer';
import {emptyProduct} from 'src/components/Products/Product/empty-product.confg';
import {ProductInfo} from 'src/components/Products/Product/ProductInfo';
import {useStore} from 'src/contexts/store.context';
import {useMoney} from 'src/hooks/money.hook';
import {usePageFound} from 'src/hooks/page-found.hooks';
import {rootSnapshot} from 'src/stores/root.store';

import {LoadingSpinner} from '../components/LoadingSpinner/LoadingSpinner';

export const ProductPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate(`ProductPage.title`)} | ${process.env.REACT_APP_BRAND_NAME}`;

	const {id} = useParams<IdParam>();
	const [pageFound] = usePageFound();
	const [formatMoney] = useMoney();
	const categoryNavigation = useCategoryNavigation();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [partsRequired, setPartsRequired] = useState<Array<RequiredPartsModel> | undefined>();
	const {basketId}: any = history.location.state || {};

	const idParam = id ? Number(id) : 0;

	const {
		customization: {
			currentProduct,
			currentProductParts,
			fetchProduct,
			fetchProductParts,
			getFinalPrice,
			quantity,
			setInitialCustomization,
			setBasketCustomization,
			required,
		},
		restaurant: {currentRestaurant},
	} = useStore();

	useEffect(
		() => {
			const isDifferentProduct = currentProduct === undefined || currentProduct.code !== idParam;
			const productUnavailable =
				currentRestaurant.outageProducts &&
				currentRestaurant.outageProducts.find(
					(outage: RestaurantOutageProductsObject) => outage.productCode === idParam,
				);
			if (isDifferentProduct) {
				if (basketId) return setBasketCustomization(basketId);
				else {
					fetchProduct(idParam)
						.then((product: ProductObject) => {
							if (product.type === ProductType.COUPON) throw new Error();
						})
						.then(() => fetchProductParts(idParam))
						.then(() => setInitialCustomization())
						.then(() => {
							rootSnapshot('customization').saveInitialState();
						})
						.then(() => {

							setPartsRequired(required);
							setIsLoading(false);
						})
						.catch((error: any) => {
							history.replace('/404');
						});
				}
			} else {
				if (currentProduct.type === ProductType.COUPON || productUnavailable) {
					history.replace('/404');
				}
				setIsLoading(false);
        if (required) {
          setPartsRequired(required);
        }
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentProduct, idParam],
	);

	// const productPrice = useMemo(() => {
	// 	return finalPrice ? finalPrice : currentProduct && currentProduct.price ? currentProduct.price.defaultUnitPrice : 0;
	// }, [finalPrice, currentProduct]);

	const productPrice = getFinalPrice()
		? getFinalPrice()
		: currentProduct && currentProduct.price
		? currentProduct.price.defaultUnitPrice
		: 0;

	return (
		<main className="product">
			{isLoading ? (
				<div className="loading-div">
					<LoadingSpinner />
				</div>
			) : (
				<div className="product__container theme-container">
					{categoryNavigation}
					{pageFound('PRODUCT', idParam) && (
						<>
							<ProductInfo price={productPrice}/>
							{currentProductParts && currentProductParts.length > 0 && <PartsContainer />}
							<Portal>
								<div className="product__actions product-actions product-actions--sticky">
									<div className="product-actions__container theme-container">
										{productPrice && (
											<p className="product-actions__total product__total txt-l txt-lemibold txt-secondary-dark">
												{'Total: '}
												{formatMoney(productPrice)}
											</p>
										)}
										{partsRequired === undefined ? (
											<LoadingSpinner height={'40px'} width={'40px'} />
										) : (
											<AddToCartButton
												code={currentProduct ? currentProduct.code : emptyProduct.code}
												quantity={
													currentProduct ? (quantity ? quantity : 1) : emptyProduct.quantity
												}
												action={basketId ? ActionType.UPDATE : ActionType.ADD}
												basketId={basketId}
												partsRequired={partsRequired}
											/>
										)}
									</div>
								</div>
							</Portal>
						</>
					)}
				</div>
			)}
		</main>
	);
});
