import React from 'react';
import Loader from 'react-loader-spinner';

// If you want to change the Loader type, please refers to the documentation here :
// https://github.com/mhnpd/react-loader-spinner

type LoadingSpinnerType = "ThreeDots" | "Watch" | "Oval" | "TailSpin";

interface LoadingSpinnerProps {
  height?: string;
  width?: string;
}

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const {
    height,
    width,
  } = props;
  const loaderType = (process.env.REACT_APP_LOADING_SPINNER_TYPE as LoadingSpinnerType) || "TailSpin";

  return (
    <Loader
      type={loaderType}
      color={process.env.REACT_APP_LOADING_SPINNER_COLOR}
      height={height}
      width={width}
    />
  )
}
