import { ProductObject, RestaurantOutageProductsObject } from '@acrelec-cloud/apico-shared';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useTranslate } from 'src/components/Languages/translate.hook';
import { Modal } from 'src/components/Modal/Modal';
import { useStore } from 'src/contexts/store.context';
import {useImage} from 'src/hooks/image.hook';
import { GlobalRouterModal } from 'src/routers/GlobalRouter';

const ORDER_PATH = '/order';

interface OrderUnavailableProductProps {
	status: GlobalRouterModal;
}

export const OrderUnavailableProduct = observer(({status}: OrderUnavailableProductProps) => {
	const {translate} = useTranslate();
	const history = useHistory();
	const {pathname} = useLocation();
	const [open, setOpen] = useState<boolean>(
		status === GlobalRouterModal.UNAVAILABLE_PRODUCT ? true : false,
	);

	const {
		restaurant: {currentRestaurant},
    basket: {products, removeProductByCode, resetBasket},
    customization: {fetchProduct},
	} = useStore();

  const [unavailableProducts, setUnavailableProducts]: any = useState([]);

  useEffect(() => {
    const basketContent = toJS(products); const lastOutagesList: any = [];
    basketContent.forEach((product: ProductObject) => {
      const productUnavailable = 
        (currentRestaurant.outageProducts && 
          currentRestaurant.outageProducts.find((outage: RestaurantOutageProductsObject) => outage.productCode === product.code)) ? true : false;
      if(productUnavailable) {
        fetchProduct(product.code, false)
          .then((res: any) => {
            setUnavailableProducts([...lastOutagesList, res]);
            lastOutagesList.push(res);
          })
          .catch((err: any) => console.error(err));
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

	useEffect(() => {
    setOpen(status === GlobalRouterModal.UNAVAILABLE_PRODUCT ? true : false);
  }, [status]);

	return (
		<Modal className="modal__product-unavailable" open={open} onClose={() => {}}>
			<div className="product-unavailable">
				<p className="product-unavailable__title">{translate('ProductUnavailable.title')}</p>
        {(unavailableProducts && unavailableProducts.length > 0) && ( // Display unavailable products
          <div className="product-unavailable__tiles">
            {unavailableProducts.map((product: ProductObject, key: number) => (
              <ProductTile product={product} key={key} />
            ))}
          </div>
        )}
				<div className="product-unavailable__actions">
					<button // Button - Replace the product
						className="product-unavailable__btn product-unavailable__btn--continue product-unavailable__replace btn__secondary"
						onClick={() => {
              unavailableProducts.map((product: ProductObject) => (
                removeProductByCode(product.code) // Remove the product from basket
              ))
              pathname === ORDER_PATH ? setOpen(false) : history.push(ORDER_PATH)
							setOpen(false);
						}}>
						{translate('ProductUnavailable.buttons.replace')}
					</button>
          <button // Button - Clear the basket
            className="product-unavailable__btn product-unavailable__btn--change btn__secondary--inverse"
            onClick={() => {
              resetBasket();
              setOpen(false);
            }}>
            {translate('ProductUnavailable.buttons.delete')}
          </button>
          <button // Button - Remove only this product from my basket
            className="product-unavailable__btn product-unavailable__btn--change btn__secondary--inverse"
            onClick={() => {
              unavailableProducts.map((product: ProductObject) => (
                removeProductByCode(product.code) // Remove the product from basket
              ))
							setOpen(false);
            }}>
            {translate('ProductUnavailable.buttons.continue')}
          </button>
				</div>
			</div>
		</Modal>
	);
});

interface ProductTileProps {
  product: ProductObject;
}

const ProductTile = ({product}: ProductTileProps) => {
	const {image} = useImage(product.imageUrl);
  
  return (
    <div className="product-unavailable__visual-list">
      <div className="product-unavailable__image-container">
        <img src={image} alt="{translateProduct(item).name}" className="product-item__image" />
      </div>
      <span className="product-unavailable__product-name">{product.name}</span>
    </div>
  );
};
