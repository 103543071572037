import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';

import {LoginComponent} from 'src/components/Auth/Login/LoginComponent';
import {useTranslate} from 'src/components/Languages/translate.hook';

const LoginPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate('LoginPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

	const [displayError, setDisplayError] = useState<boolean>(false);

	return (
		<main className="auth-main">
			{displayError && <p>{translate(`Profile.error`)}</p>}
			<LoginComponent onError={setDisplayError}></LoginComponent>
		</main>
	);
});

export default LoginPage;
