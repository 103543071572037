import {observer} from 'mobx-react-lite';
import React from 'react';
import {useParams} from 'react-router-dom';

import {PasswordRequest} from 'src/components/Auth/ResetPassword/PasswordRequest';
import {AuthStatus, StatusComponent} from 'src/components/Auth/StatusComponent';
import {useTranslate} from 'src/components/Languages/translate.hook';

enum ForgotPasswordRedirect {
	sent = '/profile/forgot-password/request',
	success = '/login',
	fail = '/profile/forgot-password/request',
}

export enum ForgotPasswordAction {
	request = 'request',
	sent = 'sent',
	success = 'success',
	fail = 'fail',
}

export interface ForgotPasswordActionParam {
	action: ForgotPasswordAction;
}

export const ForgotPasswordPage = observer(() => {
	const {translate} = useTranslate();
	const {action} = useParams<ForgotPasswordActionParam>();

	document.title = `${translate(`ForgotPassword.title.require`)} | ${
		process.env.REACT_APP_BRAND_NAME
	}`;

	return (
		<main className="auth-main">
			{action === ForgotPasswordAction.request ? (
				<PasswordRequest />
			) : (
				<StatusComponent
					title={`ForgotPassword.title.${action}`}
					redirect={ForgotPasswordRedirect[action]}
					redirectTitle={`ForgotPassword.link.${action}`}
					status={AuthStatus[action]}
				/>
			)}
		</main>
	);
});
