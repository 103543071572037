import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link, useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {usePopover} from 'src/components/Popover/popover.hook';
import {useStore} from 'src/contexts/store.context';

export const MenuAuth = observer(() => {
	const {closePopover} = usePopover();
	const history = useHistory();

	const {
		auth: {isLogged, logout, profile},
		order: {emptyOrders},
	} = useStore();
	const {translate} = useTranslate();

	const handleLogout = () => {
		logout();
		emptyOrders();
		closePopover();
		history.push('/');
	};

	return (
		<div className="home-menu__profile">
			{isLogged && (
				<>
					<p>
						{profile?.firstName} {profile?.lastName}
					</p>
					<button className="home-menu__profile__btn" onClick={() => handleLogout()}>
						<div className="home-menu__profile__btn__icon"></div>

						<p className="txt-l">{translate('Profile.logout')}</p>
					</button>
				</>
			)}
			{!isLogged && (
				<Link to="/login">
					<button className="home-menu__profile__btn" onClick={() => closePopover()}>
						<div className="home-menu__profile__btn__icon"></div>

						<p className="txt-l">{translate('Profile.login')}</p>
					</button>
				</Link>
			)}
		</div>
	);
});
