import {
  ComponentNameTypes,
  DisplayTypes,
  PopoverTypes,
} from "src/components/Popover/custom-popovers.config";
import { useStore } from "src/contexts/store.context";

export interface UsePopover {
  openPopover: (type: PopoverTypes, componentName: ComponentNameTypes) => void;
  closePopover: () => void;
}

export const usePopover = (): UsePopover => {
  const {
    popover: { setPopover, resetPopover },
  } = useStore();

  const openPopover = (
    type: PopoverTypes,
    componentName: ComponentNameTypes
  ): void => {
    setPopover(DisplayTypes.OPEN, type, componentName);
  };

  const closePopover = (): void => resetPopover();

  return { openPopover, closePopover };
};
