import {OrderObject, OrderState} from '@acrelec-cloud/apico-sdk';
import {DateTime} from 'luxon';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {GoBack} from 'src/components/Order/GoBack';
import {OrderContainer} from 'src/components/Order/OrderContainer';
import {OrderTitle} from 'src/components/Order/OrderTitle';
import {useStore} from 'src/contexts/store.context';

import {LoadingSpinner} from '../components/LoadingSpinner/LoadingSpinner';

export interface CurrentOrderPageParams {
	orderConflictId: string;
}

export const CurrentOrderPage = observer(() => {
	const history = useHistory();
	const {translate} = useTranslate();
	document.title = `${translate('CurrentOrderPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const {orderConflictId} = useParams<CurrentOrderPageParams>();
	const [orderConflicted, setOrderConflicted] = useState<any>();
	const checkupTimeInterval = Number(process.env.REACT_APP_CHECK_ORDER_INTERVAL);

	const {
		order: {fetchCurrentOrders, findOrder, emptyOrders, orders, emptyPendingOrders},
		auth: {isLogged, guest, setAccount},
	} = useStore();

	useEffect(() => {
		if (orderConflictId) {
			// Get order conflicted
			(async function anyNameFunction() {
				const conflictOrder = await findOrder(orderConflictId);
				conflictOrder.state === OrderState.CONFLICTED && setOrderConflicted(conflictOrder);
			})();
		}
		let interval: any;
		let lastUpdatedAt = String(DateTime.now().minus({milliseconds: checkupTimeInterval}));
		const fetchOrdersInterval = (onlyRecentsDate?: string) => {
			fetchCurrentOrders(20, 0, process.env.REACT_APP_CURRENT_ORDER_FILTERS, onlyRecentsDate)
				.then(() => {
					setIsLoading(false);
				})
				.catch((error: any) => {
					if (error?.response?.status === 401) {
						setAccount();
					}
					setIsLoading(false);
				});
		};
		if (isLogged || !!guest) {
			fetchOrdersInterval();
			interval = setInterval(() => {
				fetchOrdersInterval(lastUpdatedAt);
				lastUpdatedAt = String(DateTime.now().minus({milliseconds: checkupTimeInterval}));
			}, checkupTimeInterval);
		} else if (!isLogged) {
			emptyOrders();
			emptyPendingOrders();
			setIsLoading(false);
		}
		return () => {
			emptyOrders();
			clearInterval(interval);
		};
	}, [
		emptyPendingOrders,
		emptyOrders,
		fetchCurrentOrders,
		findOrder,
		guest,
		isLogged,
		orderConflictId,
		checkupTimeInterval,
		history,
		setAccount,
	]);

	return (
		<main className="current-order-main">
			{isLoading ? (
				<div className="loading-div">
					<LoadingSpinner />
				</div>
			) : (
				<>
					<div className="current-order">
						<GoBack />
						<OrderTitle />
						{orders && orders.length === 0 && !orderConflicted && (
							<p>{translate('CurrentOrderPage.no_orders')}</p>
						)}
						{orderConflicted ? (
							<div className="current-order__container">
								<OrderContainer order={orderConflicted} cancellation={true} />
							</div>
						) : (
							orders.map((order: OrderObject, index: number) => {
								return (
									<div className="current-order__container" key={index}>
										<OrderContainer order={order} />
									</div>
								);
							})
						)}
					</div>
				</>
			)}
		</main>
	);
});
