import {ProductClassificationObject, ProductPartObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {FirstItem, useGroupParts} from 'src/components/Products/Parts/hooks/group-parts.hook';

import {PartRenderer} from './PartRenderer';

interface PartsDispatcherProps {
	// TODO: change any
	currentPart: any;
	parent?: any;
	firstExtraItem?: FirstItem | undefined;
	firstIncludedItem?: FirstItem | undefined;
	firstRequiredItem?: FirstItem | undefined;
	onToggleCustomization?: (parts: ProductPartObject[] | undefined) => void;
}

export const PartsDispatcher = observer(
	({
		currentPart,
		parent,
		firstExtraItem,
		firstIncludedItem,
		firstRequiredItem,
		onToggleCustomization,
	}: PartsDispatcherProps) => {
		const {groupPartsByType, displayGroupTitle} = useGroupParts();
		const firstLevel = currentPart.parentArray.length === 1;
		const currentProduct = currentPart.product!;

		const optionsGrouped =
			currentProduct.options &&
			currentProduct.options.length > 0 &&
			groupPartsByType(currentProduct.options);

		return (
			<div
				className={`dispatcher--open dispatcher${firstLevel ? ' dispatcher--first-level' : ''}`}
				key={currentProduct.id}>
				{displayGroupTitle(currentProduct.code, currentPart.parentArray, {
					required: firstRequiredItem,
					extra: firstExtraItem,
					included: firstIncludedItem,
				})}
				<PartRenderer
					part={currentPart}
					parent={parent}
					onToggleCustomization={(parts) => {
						onToggleCustomization && onToggleCustomization(parts);
					}}
				/>

				{optionsGrouped &&
					optionsGrouped.itemsSorted.map((option: ProductClassificationObject) => {
						return (
							<PartsDispatcher
								key={option.product!.id}
								currentPart={option}
								parent={currentPart}
								onToggleCustomization={(parts) => {
									onToggleCustomization && onToggleCustomization(parts);
								}}
							/>
						);
					})}
			</div>
		);
	},
);
