import axios from 'axios';
import {useEffect} from 'react'
import { useHistory, useLocation } from 'react-router-dom';

export const TechnicalIssueController = () => {
  const {replace} = useHistory();
  const {pathname} = useLocation();

  // Check if API is healthy/failed, redirect to the TechnicalIssuePage
  useEffect(() => {
    if(process.env.REACT_APP_API_URL) {
			axios.get(process.env.REACT_APP_API_URL)
				.catch((error) => {
					if(error.response && error.response.status >= 500 && !pathname.includes('contact')) {
            replace('technical-issue', {errorCode: error.response.status});
					}
				})
      }
  }, [pathname, replace]);

  return null;
}
