import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';

import {FormComponent} from 'src/components/Form/FormComponent';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {DeleteUserAccountModal} from 'src/components/Profile/DeleteUserAccountModal';
import {editProfileData} from 'src/components/Profile/edit-profile-data.config';
import {useStore} from 'src/contexts/store.context';

interface EditProfileProps {
	onSubmit: (e: any) => void;
}

export const EditProfileComponent = observer(({onSubmit}: EditProfileProps) => {
	const {translate} = useTranslate();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

	const confirm = (data: any) => {
		onSubmit(data);
	};

	const confirmDeleteAccount = () => {
		setModalIsOpen(true);
	};

	const {
		auth: {profile},
	} = useStore();

	return (
		<div className="profile">
			<p className="profile__title txt-xl">{translate(`Profile.title.update`)}</p>
			<FormComponent
				labelSubmitButton={'Profile.confirm'}
				classNameSubmitButton={'form__btn btn__primary'}
				classNameForm={'form'}
				classNameField={'form__field'}
				classNameInput={'form__input'}
				onSubmit={confirm}
				value={profile}
				inputsConfig={editProfileData}></FormComponent>
			<button className="reset-account__btn txt-bold" onClick={() => confirmDeleteAccount()}>
				{translate(`ProfilePage.delete_account`)}
			</button>
			<DeleteUserAccountModal open={modalIsOpen} onClose={() => setModalIsOpen(false)} />
		</div>
	);
});
