import { useMemo, useRef } from "react";

export type CompareMethod<T> = (next: T, previous: T) => boolean;

/**
 * Use memo with custom compare method.
 *
 * @param factory Memo factory function.
 * @param value Base value.
 * @param compare Compare method.
 */
export function useMemoCompare<T>(
  factory: () => T,
  value: T,
  compare: CompareMethod<T>
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValue = useMemo(() => factory(), []);
  const previousRef = useRef<T>(defaultValue);
  const previous = previousRef.current;

  const isEqual = compare(value, previous);

  if (!isEqual) {
    previousRef.current = factory();
  }

  return isEqual ? previous : previousRef.current;
}
