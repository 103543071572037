import {observer} from 'mobx-react-lite';
import React, {ReactNode, useMemo} from 'react';

import {CampaignChangeModal} from 'src/components/Modal/CustomModals/CampaignChangeModal';
import {OrderDisabledModal} from 'src/components/Modal/CustomModals/OrderDisabledModal';
import {OrderEnableModal} from 'src/components/Modal/CustomModals/OrderEnableModal';
import {OrderUnavailableProduct} from 'src/components/Modal/CustomModals/OrderUnavailableProduct';
import {GlobalRouterModal} from 'src/routers/GlobalRouter';

interface ModalModals {
	[key: string]: ReactNode;
}

interface GlobalModalProps {
	status: GlobalRouterModal;
}

export const GlobalModal = observer(({status}: GlobalModalProps) => {
	const modal: ModalModals = useMemo(
		() => ({
			AVAILABLE_RESTAURANT: <OrderEnableModal status={status} />,
			UNAVAILABLE_RESTAURANT: <OrderDisabledModal status={status} />,
			CAMPAIGN_CHANGE: <CampaignChangeModal status={status} />,
      UNAVAILABLE_PRODUCT: <OrderUnavailableProduct status={status} />,
		}),
		[status],
	);

	return <>{!!status && modal[status]}</>;
});
