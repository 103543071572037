import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {PartRegular} from 'src/components/Products/Parts/PartRegular';
import {getUniqueId} from 'src/utils/uniqueId';

export interface ProductCustomizationModalProps {
	open: boolean;
	parts: any;
	onClose: () => void;
}

export const ProductCustomizationModal = observer(
	({open, parts, onClose}: ProductCustomizationModalProps) => {
		const {translate} = useTranslate();

		return (
			<Modal open={open} onClose={onClose}>
				<div className="modal__product-customization">
					<div className="customization-modal__close close" onClick={() => onClose()}></div>
					<p className="product-customization__title">
						{translate('ProductCustomizationModal.title')}
					</p>
					<div className="customization-center__position">
						<div className="customization-modal__wrapper">
							{parts &&
								parts.map((part: any) => (
									<PartRegular
										key={getUniqueId()}
										part={part.product}
										min={part.minQuantity}
										max={part.maxQuantity}
										qty={part.includedQuantity || 0}
										defaultQuantity={part.defaultQuantity || 0}
										parent={part.parentArray}
									/>
								))}
						</div>
					</div>
					<div className="customization-modal__buttons">
						<button
							className="btn__secondary customization-modal__button"
							onClick={() => onClose()}>
							{translate('ProductCustomizationModal.confirm')}
						</button>
					</div>
				</div>
			</Modal>
		);
	},
);
// temp
