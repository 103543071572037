import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from 'react';

import {CheckoutFormStripeContent} from './CheckoutFormStripeContent';

export const CheckoutFormStripe = () => {
	const stripePromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
		? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
		: null;

	return (
		<Elements stripe={stripePromise}>
			<CheckoutFormStripeContent />
		</Elements>
	);
};
