import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';

import {ErrorTryAgainModal} from 'src/components/Auth/ErrorTryAgainModal';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {CreateProfileComponent} from 'src/components/Profile/CreateProfileComponent';

export enum ErrorType {
  ALREADY_EXIST = 1,
  UNKNOWN_ERROR = 2,
}

export const ProfileCreationPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate('ProfileCreationPage.title')} | ${
		process.env.REACT_APP_BRAND_NAME
	}`;

	const [displayError, setDisplayError] = useState<boolean>(false);
  const [error, setError] = useState<string>(translate(`Profile.try_again`));

  const onErrorOccurred = (haveError : boolean, errorType : number) => {
    if(errorType === ErrorType.ALREADY_EXIST) setError(translate(`Profile.already_exist`));
    setDisplayError(haveError);
  }

	return (
		<main className="auth-main">
			<CreateProfileComponent
				displayTitle={!displayError}
				onError={onErrorOccurred}></CreateProfileComponent>
			<ErrorTryAgainModal open={displayError} errorText={error} onClose={() => setDisplayError(false)} />
		</main>
	);
});
