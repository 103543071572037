import {ProductObject, ProductPartObject} from '@acrelec-cloud/apico-sdk';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {
	PartType,
	SimplePart,
	useRequiredLeftover,
} from 'src/components/Products/Parts/hooks/required-leftover.hook';
import {ToggleButton} from 'src/components/Products/Parts/ToggleButton';
import {useStore} from 'src/contexts/store.context';
import {useMemoCompare} from 'src/hooks/memo-compare.hook';

interface PartTitleProps {
	name: string;
	product?: ProductObject;
	hasParts?: boolean;
	isOption?: boolean;
	step?: string;
	className?: string;
	warnignMessageDisplay: boolean;
	part?: SimplePart;
	onToggleCustomization?: (parts: ProductPartObject[] | undefined) => void;
}

export const PartTitle = observer(
	({
		product,
		hasParts,
		isOption,
		step,
		className,
		warnignMessageDisplay,
		part,
		onToggleCustomization,
		name,
	}: PartTitleProps) => {
		const {translate} = useTranslate();
		const [warnignMessageStatus, setWarnignMessageStatus] =
			useState<boolean>(warnignMessageDisplay);
		const {checkRequiredLeftover, countRequiredChildren, countRequiredSiblings} =
			useRequiredLeftover();

		const {
			customization: {required},
		} = useStore();

		// TODO: change any (RequiredParts)
		const comparedRequired = useMemoCompare(
			() => toJS(required),
			required,
			(a: any[], b: any[]) =>
				a.length === b.length &&
				a.every((item: any, index: number) => {
					const otherItem = b[index] as any;
					return item.qty === otherItem.qty;
				}),
		);

		useEffect(() => {
			const isRequiredGroup =
				className === 'parts__item--group' &&
				name === translate(`Parts.group_title.required`) &&
				part;

			if (isRequiredGroup) {
				let partType: string;

				if (countRequiredChildren(part!) > 0) {
					partType = PartType.hasChildren;
				} else if (countRequiredSiblings(part!) > 1) {
					partType = PartType.hasSiblings;
				} else {
					partType = PartType.isSingle;
				}

				const currentLeftover = checkRequiredLeftover[partType](part!);
				const minRequiredIsSelected = currentLeftover <= 0;
				setWarnignMessageStatus(minRequiredIsSelected ? false : true);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [comparedRequired, setWarnignMessageStatus, required, className]);

		const onSelected = () => {
			if (product && hasParts && onToggleCustomization) {
				onToggleCustomization(product.parts);
			}
		};

		return (
			<div className={`parts__item${className ? ` ${className}` : ''}`} onClick={onSelected}>
				<div
					className={`parts__title-wrap${
						warnignMessageStatus ? ' parts__title-wrap--required' : ''
					}`}>
					<p className="parts__name txt-s txt-bold txt-secondary-dark">{name}</p>
					<ToggleButton
						display={isOption !== undefined}
						isOption={isOption ? isOption : false}
						step={step}
            disabled
					/>
					{warnignMessageStatus && (
						<i className="parts__warning txt-warning fas fa-exclamation-triangle"></i>
					)}
				</div>
			</div>
		);
	},
);
