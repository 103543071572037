import {RestaurantObject, OrderState} from '@acrelec-cloud/apico-sdk';
import {DateTime} from 'luxon';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {ReactSVG} from 'react-svg';

import time from 'src/assets/images/icons/time.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {useEasyDateHour} from 'src/hooks/easy-date-hour.hook';

interface OrderRestaurantInfoProps {
	pickUpTime: string;
	orderInjectionTime?: string;
	ordersInjectionDelay: number;
	restaurant: RestaurantObject;
	posId: number;
	orderState: string;
}

export const OrderRestaurantInfo = observer((props: OrderRestaurantInfoProps) => {
	const {pickUpTime, ordersInjectionDelay, posId, restaurant, orderState, orderInjectionTime} =
		props;
	const [orderHasBeenSentToKitchen, setOrderHasBeenSentToKitchen] = useState<
		'has_been_sent' | 'will_be_send' | 'in_progress' | 'error' | 'unknown_error' | 'is_cancelled' | 'conflicted'
	>();

	const {translate} = useTranslate();
	const {defaultHour} = useEasyDateHour();

	const estimatedInjectionDate = DateTime.fromISO(pickUpTime)
		.setZone(restaurant.timeZone)
		.minus({minutes: ordersInjectionDelay});

	useEffect(() => {
		const sent = () => {
			const currentDate = DateTime.local().setZone(restaurant.timeZone);
			const timeDiff = currentDate.diff(estimatedInjectionDate, 'minutes').toObject().minutes || 0;
			const pickUpDate = DateTime.fromISO(pickUpTime).setZone(restaurant.timeZone);
			const pickUpDateDiff = currentDate.diff(pickUpDate, 'minutes').toObject().minutes || 0;

      if (orderState === OrderState.CONFLICTED) {
				return 'conflicted';
			} else if (timeDiff < 0 && orderState !== OrderState.CANCELLED) {
				return 'will_be_send';
			} else if (orderState === OrderState.PAYMENT_ERROR || orderState === OrderState.INJECTION_ERROR || orderState === OrderState.CANCELLED) {
				return 'error';
			} else if (!posId && timeDiff >= 0 && pickUpDateDiff < 2) {
				return 'in_progress';
			} else if (posId && timeDiff >= 0) {
				return 'has_been_sent';
			} else {
				return 'unknown_error';
			}
		};
		setOrderHasBeenSentToKitchen(sent());
		if (orderHasBeenSentToKitchen !== 'has_been_sent') {
			const checkOrderState = setInterval(() => {
				const hasBeenSent = sent();
				setOrderHasBeenSentToKitchen(hasBeenSent);
			}, 300);
			return () => clearInterval(checkOrderState);
		}
	}, [
		estimatedInjectionDate,
		orderHasBeenSentToKitchen,
		orderInjectionTime,
		orderState,
		pickUpTime,
		posId,
		restaurant.timeZone,
	]);

	return (
		<div className="order-success">
			<ReactSVG className="order-success__time" src={time} width={30} height={30} />
			{orderHasBeenSentToKitchen === 'in_progress' && (
				<p className="txt-xs">{translate('CurrentOrderPage.order_restaurant_info.in_progress')}</p>
			)}
			{(orderHasBeenSentToKitchen === 'has_been_sent' ||
				orderHasBeenSentToKitchen === 'will_be_send') && (
				<p className="txt-xs">
					{translate(`CurrentOrderPage.order_restaurant_info.${orderHasBeenSentToKitchen}`)}
					<span className="txt-xs txt-bold">
						{' '}
						{defaultHour(
							orderInjectionTime || estimatedInjectionDate.toString(),
							restaurant.timeZone,
						)}
					</span>
				</p>
			)}

			{(orderHasBeenSentToKitchen === 'error' || orderHasBeenSentToKitchen === 'conflicted') && (
				<p className="txt-error txt-s">{translate(`CurrentOrderPage.error.${orderState}`)}</p>
			)}

			{orderHasBeenSentToKitchen === 'unknown_error' && (
				<p className="txt-error txt-s">{translate(`CurrentOrderPage.error.unknown_error`)}</p>
			)}
		</div>
	);
});
