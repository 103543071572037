import { PageType } from "@acrelec-cloud/apico-cdk";
import { NavigationPageObject } from "@acrelec-cloud/apico-sdk";

import { useStore } from "src/contexts/store.context";

export const usePageFound: any = () => {
  const {
    navigation: { navigation },
    customization: { currentProduct }
  } = useStore();

  const pageFound = (content: PageType, id: number): boolean => {
    if (content === "CATEGORY") {
      return (
        (navigation &&
          navigation.filter(
            (item: NavigationPageObject) => item.id === id
          ).length > 0) ||
        id === 0
      );
    }
    if (content === "PRODUCT") {
      return currentProduct?.code === id;
    }
    return false;
  };

  return [pageFound];
};
