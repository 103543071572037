import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

// The observer is necessary here for the translation
export const ContactPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate('ContactPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

	return (
		<main className="contact">
			<div className="contact__container">
				<h1 className="contact__title txt-primary">{translate('ContactPage.title')}</h1>
				<div className="contact__body">
					<p className="txt-xl">{translate('ContactPage.text')}</p>
					<p className="txt-xl">
						{translate('ContactPage.contact_us')}
						<a
							className="contact__link"
							target="blank"
							href={process.env.REACT_APP_CONTACT_PAGE_URL}>
							{translate('ContactPage.link_text')}
						</a>
					</p>
					<p className="txt-xl">:)</p>
				</div>
			</div>
		</main>
	);
});
