import {ReactNode} from 'react';

import {useStore} from 'src/contexts/store.context';

export const useMoney: any = (currency?: string) => {
	const {
		restaurant: {
			currentRestaurant,
		},
		language: {currentLanguage},
	} = useStore();

	const formatMoney = (value: number): ReactNode => {
		const currencyDisplay = currency ? currency : currentRestaurant ? currentRestaurant.currency : 'eur';
		return new Intl.NumberFormat(currentLanguage, {style: 'currency', currency: currencyDisplay}).format(value);
	};

	return [formatMoney];
};
