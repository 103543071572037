import {OrderObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {ReactSVG} from 'react-svg';

import stars from 'src/assets/images/icons/stars.svg';

interface RatingStarProps {
	order: OrderObject;
	index: number;
	rating: number;
	hoverRating: number;
	onMouseEnter: any;
	onMouseLeave: any;
	onSaveRating: any;
	lastRating: any;
}

export const RatingStar = observer((props: RatingStarProps) => {
	const {order, index, rating, hoverRating, onMouseEnter, onMouseLeave, onSaveRating, lastRating} =
		props;

	const filledIcon = useMemo(() => {
		if (hoverRating >= index || order.rating >= index || lastRating >= index) {
			return 'filled';
		} else if (!hoverRating && rating >= index) {
			return 'filled';
		}
		return 'unfilled';
	}, [rating, hoverRating, index, order.rating, lastRating]);

	return (
		<>
			{order.rating === null && !lastRating && rating === 0 ? (
				<div
					onMouseEnter={() => onMouseEnter(index)}
					onMouseLeave={() => onMouseLeave()}
					onClick={() => onSaveRating(index)}>
					<ReactSVG src={stars} className={filledIcon} />
				</div>
			) : (
				<div>
					<ReactSVG src={stars} className={filledIcon + ' disabled'} />
				</div>
			)}
		</>
	);
});
