import { FormInputConfig } from "src/components/Form/form.interface";

export const loginDataConfig: FormInputConfig[] = [
  {
    name: "email",
    label: "Form.loginForm.email",
    type: "text",
    placeholder: "Form.loginForm.placeholder.email",
    displayLabel: false,
    validator: {
      required: "Form.errors.required_login.email",
    },
  },
  {
    name: "password",
    label: "Form.loginForm.password",
    type: "password",
    placeholder: "Form.loginForm.placeholder.password",
    displayLabel: false,
    validator: {
      required: "Form.errors.required_login.password",
      maxLength: {
        value: 30,
        message: "Form.errors.maxLength.password" 
      },
    },
  },
];
