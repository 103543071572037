import {ResetPasswordRequest} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import store from 'store';

import {emailPattern, passwordPattern} from 'src/components/Form/validator-form.config';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';
import {useFormErrors} from 'src/hooks/form-errors.hook';

export interface FormResetPasswordData {
	email: string;
	password: string;
	confirm_password: string;
}

export interface ResetPasswordPageParam {
	token: string;
}

export const ResetPasswordPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate(`ForgotPassword.reset.title`)} | ${
		process.env.REACT_APP_BRAND_NAME
	}`;
	const {token} = useParams<ResetPasswordPageParam>();
	const history = useHistory();
	const {handleErrors, errorClass} = useFormErrors();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = (e:any) => {
    e.preventDefault();
    setPasswordShown(passwordShown ? false : true);
  };

	const {
		auth: {resetPassword},
	} = useStore();

	const userEmail = useMemo(() => (store.get('user') ? store.get('user').email : null), []);

	const {register, handleSubmit, errors, watch} = useForm<FormResetPasswordData>({
		mode: 'onChange',
	});

	const onSubmit = (data: FormResetPasswordData) => {
		const resetPasswordRequest: ResetPasswordRequest = {
			resetPasswordToken: token,
			email: data.email,
			password: data.password,
		};

		resetPassword(resetPasswordRequest)
			.then(() => {
				history.push('/profile/forgot-password/success');
			})
			.catch(() => {
				history.push('/profile/forgot-password/fail');
			});
	};

	return (
		<main className="auth-main">
			<div>
				<p className="profile__title txt-xl">{translate(`ForgotPassword.reset.title`)}</p>
				<p className="profile__paragraph txt-l">{translate(`ForgotPassword.reset.description`)}</p>
			</div>

			<form onSubmit={handleSubmit(onSubmit)} className="form">
				<div className="form__field form__email">
					<input
						className={`form__input${errorClass(errors?.email)}`}
						type="text"
						name="email"
						defaultValue={userEmail}
						readOnly={userEmail ? true : false}
						placeholder={translate(`Form.editProfileForm.placeholder.email`)}
						ref={register({required: true, pattern: emailPattern})}
					/>
				</div>
				{errors?.email && handleErrors(errors.email.type, 'email')}

        <div className="form__field form__password">
          <input
            className={`form__input${errorClass(errors?.password)}`}
            type={passwordShown ? "text" : "password"}
            name="password"
            placeholder={translate(`Form.editProfileForm.placeholder.password`)}
            maxLength={31}
            ref={register({
              required: true,
							minLength: 8,
							maxLength: 30,
							pattern: passwordPattern,
            })}
          />
          <i 
            className={passwordShown ? "form__input__password_icon--unshow" : "form__input__password_icon--show"}
            onClick={togglePasswordVisiblity}
          />
        </div>
        {errors?.password && handleErrors(errors.password.type, 'password')}
        <div className="form__field form_cofirm-password">
          <input
            className={`form__input${errorClass(errors?.confirm_password)}`}
            type={passwordShown ? "text" : "password"}
            name="confirm_password"
            placeholder={translate(`Form.editProfileForm.placeholder.confirm_password`)}
            maxLength={31}
            ref={register({
              required: true,
              validate: (value) => value === watch('password', value),
            })}
          />
          <i
            className={passwordShown ? "form__input__password_icon--unshow" : "form__input__password_icon--show"}
            onClick={togglePasswordVisiblity}
          />
        </div>
        {errors.confirm_password && handleErrors(errors.confirm_password.type, 'confirm_password')}
        
        <button className="form__btn btn__primary" type="submit">
          {translate(`ForgotPassword.reset.button`)}
        </button>
			</form>
		</main>
	);
});
