import { FormInputConfig } from "src/components/Form/form.interface";

export const createProfileData: FormInputConfig[] = [
  {
    name: "email",
    label: "Form.loginForm.email",
    type: "text",
    placeholder: "Form.loginForm.placeholder.email",
    displayLabel: false,
    validator: {
      required: "Form.errors.required",
      pattern: {
        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        message: "Form.errors.pattern.email",
      },
    },
  },
];
