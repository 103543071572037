import { RestaurantObject } from '@acrelec-cloud/apico-sdk';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from 'src/contexts/store.context';

import { handleDistance } from '../distance.util';
import { PickRestaurantButton } from '../PickRestaurantButton';



interface InfoWindowDataProps {
	restaurant: RestaurantObject;
	userLocation: any;
	distanceFormat: any;
}
export const InfoWindowData = observer((props: InfoWindowDataProps) => {
	const {restaurant, userLocation, distanceFormat} = props;
  const { address, geolocation, name } = restaurant;

	const {
		restaurant: {isUniqueRestaurant},
	} = useStore();

	return (
		<div className="map-container__tooltip">
			<div className="map-container__tooltip__title">
				<p>{name}{address.street && ','}</p>
				<p>{address.street && address.street}</p>
			</div>
			{!isUniqueRestaurant && (
				<div className="map-container__tooltip__links">
					<div className="tooltip-details">
						<span className="tooltip-details__distance txt-xs">
							{userLocation &&
								distanceFormat &&
								restaurant.geolocation.longitude &&
								restaurant.geolocation.latitude &&
								` ${handleDistance(userLocation, geolocation, distanceFormat)}`}
						</span>
						{/* <Link to={`/restaurants?openInfo=${restaurantId}`} className="tooltip-details__info">
						{`${translate('RestaurantItem.hours')} & ${translate(`RestaurantItem.infos`)}`}
					</Link> */}
					</div>
					<div className="pick-restaurant__container">
						{/* TODO: add onDisplayRestaurant */}
						<PickRestaurantButton restaurantInfo={restaurant} />
					</div>
				</div>
			)}
		</div>
	);
});
