import React, {useState} from 'react';
import {FieldErrors} from 'react-hook-form';

import {FormInputConfig} from 'src/components/Form/form.interface';
import {useTranslate} from 'src/components/Languages/translate.hook';

export interface FormInputProps extends FormInputConfig {
	formError?: FieldErrors<any>;
	register: any;
	classNameField?: string;
	classNameInput?: string;
	classNameLabel?: string;
	classNameError?: string;
	hidden?: boolean;
	onChange?: () => void;
}

export const FormInput = ({
	name,
	label,
	type = 'text',
	placeholder = '',
	displayLabel = true,
	hidden = false,
	validator,
	formError,
	register,
	onChange,
	classNameField = '',
	classNameInput = '',
	classNameLabel = '',
	classNameError = '',
}: FormInputProps) => {
	const {translate} = useTranslate();

	const [passwordShown, setPasswordShown] = useState(false);

	const togglePasswordVisiblity = (e: any) => {
		e.preventDefault();
		setPasswordShown(passwordShown ? false : true);
	};

	return (
    <>
      <div className={classNameField} hidden={hidden}>
        {displayLabel && <label className={classNameLabel}>{translate(label + '')}</label>}
        {validator ? (
          <>
            <input
              className={classNameInput}
              ref={register(validator)}
              name={name}
              id={name}
              placeholder={placeholder && translate(placeholder + '')}
              type={passwordShown ? 'text' : type}
              onChange={onChange}
              maxLength={31}
            />
            {type === 'password' && (
              <button
                type="button"
                className={
                  passwordShown
                    ? 'form__input__password_icon--unshow'
                    : 'form__input__password_icon--show'
                }
                onClick={togglePasswordVisiblity}
              />
            )}
          </>
        ) : (
          <input
            className={classNameInput}
            ref={register}
            name={name}
            id={name}
            placeholder={placeholder && translate(placeholder + '')}
            type={type}
          />
        )}
      </div>
      <div className="form__input__container--error">
        {formError && formError[name] && (
          <p className={classNameError}>{translate(formError[name].message + '')}</p>
        )}
      </div>
    </>
	);
};
