import {NeptingPaymentMethodObject, AuthenticatedAccountObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {GoBack} from 'src/components/Order/GoBack';
import {SavedPaymentMethod} from 'src/components/Payment/Nepting/SavedPaymentMethod';
import {ProfileRecap} from 'src/components/Profile/ProfileRecap';
import {rootStore} from 'src/stores/root.store';

export const ProfilePage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate(`ProfilePage.title`)} | ${process.env.REACT_APP_BRAND_NAME}`;
	const [paymentMethods, setPaymentMethods] = useState<NeptingPaymentMethodObject[]>([]);

	const {
		auth: {currentAuthenticated},
		order: {findNeptingMethodPayment, deleteNeptingPaymentMethod},
	} = rootStore;

	const findPaymentMethod = useCallback(() => {
		findNeptingMethodPayment()
			.then((response: NeptingPaymentMethodObject[]) => {
				setPaymentMethods(response);
			})
			.catch((error: any) => console.error(error));
	}, [findNeptingMethodPayment]);

	useEffect(() => {
    currentAuthenticated().then((res: AuthenticatedAccountObject) => {
      if(res !== undefined) {
        findPaymentMethod();
      }
    });
  }, [currentAuthenticated, findNeptingMethodPayment, findPaymentMethod]);

	const deleteCard = (id: number) => {
		deleteNeptingPaymentMethod(id)
			.then(() => {
				findPaymentMethod();
			})
			.catch((error: any) => console.log(error));
	};

	return (
		<main>
			<div className="profile__container theme-container">
				<GoBack />
				<h1 className="page-title txt-xxl">{translate('ProfilePage.title')}</h1>
				<div className="profile-content">
					<ProfileRecap />
					{paymentMethods.length > 0 && (
						<div className="user-profile">
							<h2 className="txt-h2">{translate('ProfilePage.payment_method_title')}</h2>
							<div>
								{paymentMethods.map((paymentMethod: NeptingPaymentMethodObject) => (
									<SavedPaymentMethod
										item={paymentMethod}
										key={paymentMethod.id}
										deleteCard={() => deleteCard(paymentMethod.id)}
									/>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</main>
	);
});
