import { CustomPartsModelType } from "@acrelec-cloud/apico-cdk";
import _ from "lodash";

export const compareParents = (a: number[], b: number[]): boolean =>
  _.isEqual(a, b);

export const findCurrentPartByParent = (
  parts: CustomPartsModelType[],
  part: CustomPartsModelType
) => (parts.find(
    (item: CustomPartsModelType) =>
      {
        const code = item && item.product ? item.product.code : item.code;
        return code === part.code && compareParents(item.parent, part.parent)
      }
  ));
