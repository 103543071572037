import { getDistance, convertDistance } from "geolib";

import { convertKmToMiles } from "src/utils/format-number";

export type DefaultDistance = string;

export interface GeolocationModel {
  latitude: number;
  longitude: number;
}

export const handleDistance = (
  currentLocation: GeolocationModel,
  destinyLocation: GeolocationModel,
  format: DefaultDistance
) => {
  const distance = (destinyLocation.latitude || destinyLocation.longitude) ? getDistance(currentLocation, destinyLocation) : 0;
  const kmDistance = convertDistance(distance, format);
  if (format === "mi") {
    return convertKmToMiles(kmDistance);
  }
  return `${kmDistance.toFixed(1)} km`;
};
