import {observer} from 'mobx-react-lite';
import React from 'react';
import {ReactNode, useMemo} from 'react';

import {DeleteOrderModal} from 'src/components/Modal/CustomModals/DeleteOrderModal/DeleteOrderModal';
import {FailDeleteOrderModal} from 'src/components/Modal/CustomModals/DeleteOrderModal/FailDeleteOrderModal';
import {OrderContainerModal} from 'src/components/Order/OrderContainer';

import {SuccessDeleteOrderModal} from './SuccessDeleteOrderModal';

interface ModalModals {
	[key: string]: ReactNode;
}

interface CurrentOrderModalProps {
	status: OrderContainerModal;
	orderId: number;
	setStatus: (status: OrderContainerModal) => void;
}

export const CurrentOrderModal = observer(
	({status, orderId, setStatus}: CurrentOrderModalProps) => {
		const modal: ModalModals = useMemo(
			() => ({
				CANCEL_ORDER: (
					<DeleteOrderModal
						orderId={orderId}
						status={status}
						setStatus={(status: OrderContainerModal) => setStatus(status)}
					/>
				),
				CANCEL_FAIL: (
					<FailDeleteOrderModal
						status={status}
						setStatus={(status: OrderContainerModal) => setStatus(status)}
					/>
				),
				CANCEL_SUCCESS: (
					<SuccessDeleteOrderModal
						status={status}
						setStatus={(status: OrderContainerModal) => setStatus(status)}
					/>
				),
			}),
			[status, orderId, setStatus],
		);

		return <>{!!status && modal[status]}</>;
	},
);
