import {LanguagesModelType} from '@acrelec-cloud/apico-cdk';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useCountryFlag} from 'src/hooks/country-flag.hook';

export const MenuLanguage = observer(() => {
	const {setLanguage, languages, currentLanguage} = useTranslate();
	const [getFlag] = useCountryFlag();

	return (
		<div className="home-menu__language">
			<select
				className="home-menu__language-text txt-s"
				onChange={(e) => setLanguage(e.target.value)}
				value={currentLanguage}>
				{languages?.map((option: LanguagesModelType) => (
					<option value={option.code} key={option.code}>
						{option.code.toUpperCase()}
					</option>
				))}
			</select>
			<img className="home-menu__language-flag" src={getFlag(currentLanguage)} alt="" />
		</div>
	);
});
