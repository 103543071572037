import { useEffect, useState } from "react";

export const useBrand = () => {
  // Changer name sur les pages
  const [lightLogo, setLightLogo] = useState();
  const [darkLogo, setDarkLogo] = useState();

  useEffect(() => {
    import(process.env.REACT_APP_BRAND_LOGO_LIGHT).then((logo) => {
      setLightLogo(logo.default);
    });
    import(process.env.REACT_APP_BRAND_LOGO_DARK).then((logo) => {
      setDarkLogo(logo.default);
    });
  }, []);

  return { lightLogo, darkLogo };
};
