import {FormInputConfig} from "src/components/Form/form.interface";

import {phonePattern, stringPattern} from "../Form/validator-form.config";

export const editProfileData: FormInputConfig[] = [
  {
    name: "firstName",
    label: "Form.editProfileForm.first_name",
    type: "text",
    placeholder: "Form.editProfileForm.placeholder.first_name",
    validator: {
      required: "Form.errors.required",
      pattern: {
        value: stringPattern,
        message: "Form.errors.pattern.firstName",
      },
    },
  },
  {
    name: "lastName",
    label: "Form.editProfileForm.last_name",
    type: "text",
    placeholder: "Form.editProfileForm.placeholder.last_name",
    validator: {
      required: "Form.errors.required",
      pattern: {
        value: stringPattern,
        message: "Form.errors.pattern.lastName",
      },
    },
  },
  {
    name: "phoneNumber",
    label: "Form.editProfileForm.phone_number",
    type: "tel",
    placeholder: "",
    validator: {
      pattern: {
        value: phonePattern,
        message: "Form.errors.pattern.phone",
      },
    },
  },
];
