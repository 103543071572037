import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router-dom';
import store from "store";

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';
import {useStore} from 'src/contexts/store.context';

export interface PaymentStatusModalProps extends CustomModalProps {
	success: boolean;
}

export const PaymentStatusModal = observer(({success, open, onClose}: PaymentStatusModalProps) => {
	const {translate} = useTranslate();
	const history = useHistory();
	let paymentStatus: string;
	let checkStatus: string;

	const {
		basket: {resetBasket},
	} = useStore();

	switch (success) {
		case true:
			paymentStatus = 'PaymentSuccessModal';
			checkStatus = 'check--success';
			break;
		default:
			paymentStatus = 'PaymentFailedModal';
			checkStatus = 'check--failed';
			break;
	}

	const handleTrackOrder = () => {
		resetBasket();
		store.remove('newOrder');
		history.push('/current-order');
	};

	return (
		<Modal open={open} onClose={() => (success ? handleTrackOrder() : onClose())}>
			<div className="payment-modal txt-center">
				<h1
					className={`payment-modal__tite txt-center txt-h1 ${
						success ? 'txt-success' : 'txt-error'
					}`}>
					{translate(`${paymentStatus}.title`)}
				</h1>
				<div className="payment-modal__check">
					<div className={`check ${checkStatus}`}></div>
				</div>
				{!success && (
					<p className="payment-modal__content txt-center">
						{translate(`${paymentStatus}.content`)}
					</p>
				)}
				{success && (
					<button
						className="payment-modal__btn payment-modal__btn--continue btn__secondary bg-secondary"
						onClick={() => handleTrackOrder()}>
						{translate(`${paymentStatus}.button`)}
					</button>
				)}
			</div>
		</Modal>
	);
});
