import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';
import {useStore} from 'src/contexts/store.context';

interface DeleteItemModal extends CustomModalProps {
  itemId: string;
}

export const DeleteItemModal = observer(({open, onClose, itemId}: DeleteItemModal) => {
	const {translate} = useTranslate();

	const {
		basket: {removeProductByUniqueId},
	} = useStore();

	const deleteItem = useCallback(() => {
		onClose();
		// TODO: Find a better way to do this not using setTimeout
		setTimeout(() => removeProductByUniqueId(itemId), 100);
	}, [onClose, removeProductByUniqueId, itemId]);

	return (
		<Modal open={open} onClose={onClose}>
			<div className="delete-item">
				<div className="close" onClick={() => onClose()}></div>
				<p className="delete-item__text">{translate('DeleteItemModalComponent.text')}</p>
				<div className="delete-item__btn">
					<button
						className="home-main__btn btn__secondary--inverse btn-padding"
						onClick={() => onClose()}>
						{translate('App.no')}
					</button>
					<button
						className="home-main__btn btn__secondary btn-padding"
						onClick={() => deleteItem()}>
						{translate('App.yes')}
					</button>
				</div>
			</div>
		</Modal>
	);
});
