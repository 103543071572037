import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router-dom';

import WarningIcon from 'src/assets/images/icons/warning.png';
import { useTranslate } from 'src/components/Languages/translate.hook';


import {Modal} from '../../Modal';
import {CustomModalProps} from '../../modal.model';

export const NoAvailableProductsModal = observer(({open, onClose}: CustomModalProps) => {
  const history = useHistory();
  const {translate} = useTranslate();
	return (
		<Modal open={open} onClose={onClose}>
      <div className="no-available-product-modal">
        <div className="no-available-product-modal__warning">
          <img src={WarningIcon} alt="warning icon" />
        </div>
        <p className="txt-center txt-bold">{translate('NoAvailableProductsModal.text')}</p>
        <div className="no-available-product-modal__button">          
          <button className="btn__secondary--inverse" onClick={onClose}>{translate('NoAvailableProductsModal.cancel')}</button>
          <button className="btn__secondary" onClick={() => history.push('order')}>{translate('NoAvailableProductsModal.confirm')}</button>
        </div>
      </div>
		</Modal>
	);
});
