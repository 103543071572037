import {omit} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {FormProfileComponent, FormProfileData} from 'src/components/Profile/FormProfileComponent';

interface CreateDetailsProfileProps {
	onSubmit: (e: any) => void;
	protectionPolicy: boolean;
	setProtectionPolicy: (boolean: boolean) => void;
	offersMessage: boolean;
	setOffersMessage: (boolean: boolean) => void;
}

export const CreateDetailsProfileComponent = observer(
	({
		onSubmit,
		protectionPolicy,
		setProtectionPolicy,
		offersMessage,
		setOffersMessage,
	}: CreateDetailsProfileProps) => {
		const {translate} = useTranslate();

		const confirm = (data: FormProfileData) => {
			onSubmit(omit(data, ['confirm_password']));
		};

		return (
			<div className="profile">
				<p className="profile__title txt-xl">{translate(`Profile.title.account`)}</p>
				<FormProfileComponent
					onSubmit={confirm}
					protectionPolicy={protectionPolicy}
					setProtectionPolicy={setProtectionPolicy}
					offersMessage={offersMessage}
					setOffersMessage={setOffersMessage}
				/>
				<Link to="/restaurants" className="profile__link">
					<p>{translate(`Profile.link.guest`)}</p>
				</Link>
			</div>
		);
	},
);
