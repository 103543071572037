import { observer } from 'mobx-react-lite';
import React from 'react';
import {NavLink} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';

export interface MenuItemProps {
	name: string;
	link: string;
}

export const MenuComponentItem = observer((props: MenuItemProps) => {
	const {name, link} = props;
	const {translate} = useTranslate();

	return (
		<li className="menu-item-li">
			<NavLink className="menu-item-li__link txt-s" to={link}>
				{translate(`MenuComponent.${name}`)}
				<div className="round round--right"></div>
			</NavLink>
		</li>
	);
});
