
import { NavigationPageObject } from "@acrelec-cloud/apico-sdk";
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useStore} from 'src/contexts/store.context';

import {CategoryNavigationItem} from './CategoryItem';

export const CategoryNavigationDesktop = observer(() => {
	const {
		navigation: {navigation},
	} = useStore();

	return (
		<>
			{navigation && (
				<nav>
					<ul className="category-list">
						{navigation.map((nav: NavigationPageObject, index: number) => (
							<CategoryNavigationItem key={nav.id} {...{content: nav, position: index}} />
						))}
					</ul>
				</nav>
			)}
		</>
	);
});
