import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';

enum StatusIcon {
	fail = '',
	success = '',
	sent = '',
}

export enum AuthStatus {
	sent = 'sent',
	success = 'success',
	fail = 'fail',
}

interface StatusComponentProps {
	title: string;
	redirect?: string | undefined;
	redirectTitle?: string | undefined;
	status: AuthStatus;
}

/**
 * @remarks
 * This component is part of the authentication.
 *
 * @param title - Use the translation path to languages object. E.g.: ForgotPassword.title.sent
 * @param status - AuthStatus enum: sent | success | fail
 *
 * To display the link in the bottom, provide both params `redirect` and `redirectTitle`.
 * @param redirect - The href of the link.
 * @param redirectTitle - Use the translation path to languages object. E.g.: ForgotPassword.title.sent
 *
 * @returns HTML Elment with message, icon and link
 */
export const StatusComponent = observer(
	({title, redirect, redirectTitle, status}: StatusComponentProps) => {
		const {translate} = useTranslate();

		return (
			<div className={`auth-status${status === AuthStatus.fail ? ' auth-status--error' : ''}`}>
				<p className="auth-status__title txt-primary txt-center txt-bold txt-uppercase txt-xl">
					{translate(title)}
				</p>
				<div className="auth-status__icon" status-icon={StatusIcon[status]}></div>
				{status === AuthStatus.sent && (
					<p className="auth-status__paragraph txt-m">
						{translate(`Profile.paragraph.email_box`)}
					</p>
				)}
				{redirect && redirectTitle && (
					<Link to={redirect} className="auth-status__link">
						<span className="txt-secondary txt-s txt-underline">{translate(redirectTitle)}</span>
					</Link>
				)}
			</div>
		);
	},
);
