export interface ButtonProperties {
  class: string;
  buttonTitle: string;
  icon: string;
}

export enum StatusType {
  ACTIVE = "active",
  CHOOSE = "choose",
}

export interface RestaurantData {
  [status: string]: ButtonProperties;
}
