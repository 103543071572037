import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';

export const ConfirmMessageSentModal = observer(
	({open, onClose, isSuccess, isReportProblem}: CustomModalProps) => {
		const {translate} = useTranslate();
		const history = useHistory();

		const handleOnClose = () => {
			history.push('/order-history');
			onClose();
		};

		const successText = useMemo(() => {
			return isReportProblem
				? 'ConfirmSentModal.success.text_problem'
				: 'ConfirmSentModal.success.text';
		}, [isReportProblem]);

		return (
			<Modal open={open} onClose={onClose} disableBackgroundClick={true}>
				<div className="profile-modal">
					<div className="profile-modal__close close" onClick={handleOnClose}></div>
					<div className="profile-modal__wrapper">
						<p className="profile-modal__title txt-uppercase txt-bold text-20">
							{translate(
								isSuccess ? 'ConfirmSentModal.success.title' : 'ConfirmSentModal.error.title',
							)}
						</p>
						<div className={isSuccess ? 'profile-modal__icon' : 'profile-modal__icon--error'}></div>
						<p className="profile-modal__content txt-16">
							{translate(isSuccess ? successText : 'ConfirmSentModal.error.text')}
						</p>
					</div>
					<button className="profile-modal__button btn__secondary" onClick={handleOnClose}>
						{translate('ConfirmSentModal.button')}
					</button>
				</div>
			</Modal>
		);
	},
);
