import React, {ReactNode, useMemo} from 'react';
import ReactDom from 'react-dom';

export interface ModalProps {
	children: ReactNode;
	className?: string;
	open: boolean;
	onClose: () => void;
	disableBackgroundClick?: boolean;
}

export const Modal = ({children, className, open, onClose, disableBackgroundClick}: ModalProps) => {
	const customClass = useMemo(() => (className ? ` ${className}` : ''), [className]);
	const modalContainer: HTMLElement = document.getElementById('modal')!;

	if (!open) return null;

	const handleBackgroundClick = () => {
		if (!disableBackgroundClick) {
			onClose();
		}
	};

	return ReactDom.createPortal(
		<div className="modal-show">
			<div className={`modal${customClass}`}>
				<div className="modal__background" onClick={handleBackgroundClick}></div>
				<div className="modal__box">
					<div className="modal__close" onClick={() => onClose()}></div>
					<div className="modal__body">{children}</div>
				</div>
			</div>
		</div>,
		modalContainer,
	);
};
