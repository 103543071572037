import { Basket } from "src/components/Basket/Basket";
import { MenuComponent } from "src/components/Header/MenuComponent/MenuComponent";

export interface PopoverComponents {
  [key: string]: React.FC;
}
export const CustomPopovers: PopoverComponents = {
  Basket: Basket,
  MenuComponent: MenuComponent,
};

export enum ComponentNameTypes {
  Basket = "Basket",
  MenuComponent = "MenuComponent",
}

export const POPOVER_DISPLAY_CLASS = "popover-show";

export enum PopoverTypes {
  DEFAULT = "DEFAULT",
  RIGHT = "RIGHT",
  LEFT = "LEFT",
}

export enum DisplayTypes {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  CLOSING = "CLOSING",
}
