import {IdParam, RestaurantObject} from '@acrelec-cloud/apico-cdk';
import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router';

import {LoadingSpinner} from 'src/components/LoadingSpinner/LoadingSpinner';
import { useStore } from 'src/contexts/store.context';

export const RestaurantDispatcher = () => {
	const {slugOrId} = useParams<IdParam>();
	const history = useHistory();
	const {
		restaurant: {
			fetchRestaurant,
			setCurrentRestaurant,
		},
	} = useStore();

	useEffect(() => {
		if (slugOrId) {
			fetchRestaurant(slugOrId)
				.then((restaurant: RestaurantObject) => {
					if (!restaurant.authenticationRequired) {
						setCurrentRestaurant(restaurant);
						history.push('/order');
					} else {
						history.push(`/restaurants/${slugOrId}/authenticate`);
					}
				})
				.catch(() => history.push('/404'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slugOrId, setCurrentRestaurant, fetchRestaurant]);

	return (
		<main className="restaurant-dispacher">
			<LoadingSpinner />
		</main>
	);
};
