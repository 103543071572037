import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';

interface IssuePageProps {
	is404?: boolean;
}

export const IssuePage = observer(({is404}: IssuePageProps) => {
  const {translate} = useTranslate();
	const location = useLocation<{errorCode: string}>();
	const [servorErrorCode, setServorErrorCode] = useState('');

	is404 && (document.title = `404 | ${process.env.REACT_APP_BRAND_NAME}`);
	servorErrorCode && (document.title = `${servorErrorCode} | ${process.env.REACT_APP_BRAND_NAME}`);

  useEffect(() => {
		if (location.state) {
			setServorErrorCode(location.state.errorCode);
		}
	}, [location.state]);

	return (
		<main className="not-found">
			<div className="title">
				<h2 className="title__text txt-secondary txt-center">
					{translate(`OrderPage.error`)} {is404 ? '404' : servorErrorCode}
				</h2>
			</div>

			<p className="txt-secondary txt-xxl txt-center">
				{is404
					? translate(`OrderPage.not_found_first_message`)
					: translate(`TechnicalIssuePage.title`)}
			</p>

			<p className="txt-secondary txt-xxl txt-center">
				{translate(`OrderPage.not_found_second_message`)}
			</p>

			<div className="link txt-center">
				{is404 ? (
					<span>
						{translate(`OrderPage.redirect_message`)}
						<Link to="/restaurants" className="txt-underline">
							{translate(`OrderPage.not_found_button.store`)}
						</Link>
					</span>
				) : (
					<>
						{translate(`TechnicalIssuePage.message_firstPart`)}
						<Link to="/restaurants" className="txt-underline">
							{translate(`TechnicalIssuePage.redirect`)}
						</Link>
						{translate(`TechnicalIssuePage.message_secondPart`)}
					</>
				)}
			</div>
		</main>
	);
});
