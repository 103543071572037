import {last, pick} from 'lodash';
import {DateTime} from 'luxon';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';
import Dropdown from 'react-dropdown';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';
import {useOpeningHours} from 'src/hooks/opening-hours.hook';
import {formatAddress} from 'src/utils/format-address';
import {getUniqueId} from 'src/utils/uniqueId';

interface TimeRange {
	earliest: string;
	latest: string;
}

interface HoursList {
	label: string;
	value: DateTime;
	key: string;
}
interface SelectHourComponentProps {
	title: string;
	callback: (pickUpTime: DateTime) => void;
	orderRetrievalDate?: DateTime;
}

// TODO: this file is too long. At some point need to split it better.

export const SelectHourComponent = observer(
	({title, callback, orderRetrievalDate}: SelectHourComponentProps) => {
		const {translate} = useTranslate();
		const [hoursList, setHoursList] = useState<HoursList[]>([]);
		const {minDate, maxDate, checkPickupTime} = useOpeningHours();
		const [timeRange, setTimeRange] = useState<TimeRange>();
    
		const {
			basket: {pickUpTime},
			restaurant: {currentRestaurant},
		} = useStore();

    const local = currentRestaurant ? currentRestaurant.timeZone : DateTime.local().zoneName;

		const createSelect = () => {
			const listHours: HoursList[] = [];
			let restaurantMinHour = minDate();
			let restaurantMaxHour = maxDate();
			const firstTimeOption: string = restaurantMinHour.toFormat('T');
			listHours.push({
				label: `${firstTimeOption}`,
				value: restaurantMinHour,
				key: getUniqueId(),
			});

			const restaurantMinHourMinutes = restaurantMinHour.minute;
			if (restaurantMinHourMinutes < 50) {
				const minute = Math.ceil(restaurantMinHour.minute / 10) * 10;
				restaurantMinHour = restaurantMinHour.set({minute});
			} else {
				restaurantMinHour = restaurantMinHour.plus({hour: 1}).set({minute: 0});
			}
			while (restaurantMinHour.toString() < restaurantMaxHour.toString()) {
				if (listHours[0].value.toFormat('T') !== restaurantMinHour.toFormat('T')) {
					listHours.push({
						label: restaurantMinHour.toFormat('T'),
						value: restaurantMinHour,
						key: getUniqueId(),
					});
				}
				restaurantMinHour = restaurantMinHour.plus({minutes: 10});
			}
			setHoursList(listHours);
			setTimeRange({
				earliest: listHours[0].value.toFormat('T'),
				latest: last(listHours)!.value.toFormat('T'),
			});
			return listHours;
		};


		useEffect(() => {
			createSelect();
			checkPickupTime();

      const refreshPickupTask = setInterval(() => {
			  checkPickupTime();
      }, 5000)

      return () => {
        clearInterval(refreshPickupTask);
      }
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentRestaurant]);

		const currentPickUpTime: string = useMemo(() => {
			const time = orderRetrievalDate ? orderRetrievalDate : pickUpTime;

			const pickupTime = DateTime.fromISO(time).setZone(local);
      const ordersDisabledUntil = DateTime.fromISO(currentRestaurant.settings.ordersEnabledAt)
        .setZone(currentRestaurant.timeZone)
        .plus({
          minutes: currentRestaurant.settings.ordersInjectionDelay,
        });

      const initPickupTime = pickupTime.valueOf() < ordersDisabledUntil.valueOf()
        ? ordersDisabledUntil.setZone(local).toFormat('T')
        : pickupTime.setZone(local).toFormat('T');

		  return pickupTime && pickupTime.valueOf() > minDate().valueOf()
        ? initPickupTime
        : `${minDate().setZone(local).toFormat('T')}`;
		}, [orderRetrievalDate, pickUpTime, currentRestaurant, minDate, local]);

		return (
			<>
				{currentRestaurant?.openingHours && (
					<div>
						<h2 className="txt-h2">{translate('SelectHourComponent.title')}</h2>
						<p className="checkout-page__time-details">
							{translate('SelectHourComponent.address')}
							{currentRestaurant.name}
							{currentRestaurant.address.city && ' - '}
							{formatAddress(currentRestaurant.address)}
						</p>
						<p className="checkout-page__time-details">
							{translate('SelectHourComponent.subTitle')}
						</p>
						<div className="checkout-page__time-details txt-bold">
							Min : {timeRange?.earliest} - Max : {timeRange?.latest}
						</div>

						<Dropdown
							// @ts-ignore - ignore the type because we need an object instead of a string
							options={hoursList}
							// We have to set any because the dropdown is not supposed to get other type than string
							onChange={({value}: any) => {
								callback(DateTime.fromISO(value));
							}}
							value={currentPickUpTime}
							className="navigation__dropdown"
							controlClassName="navigation__control"
							placeholderClassName="navigation__placeholder"
							menuClassName="navigation__menu"
							arrowClosed={<span className="arrow arrow--closed" />}
							arrowOpen={<span className="arrow arrow--open" />}
						/>
					</div>
				)}
			</>
		);
	},
);
