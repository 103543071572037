import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

interface PaymentFormInputProps {
	defaultValue?: string;
	errorMessage?: string;
	isRequired?: boolean;
	label: string;
	name: string;
	pattern?: RegExp;
	placeholder?: string;
	type: string;
}

export const PaymentFormInput = observer((props: PaymentFormInputProps) => {
	const {
		defaultValue,
		errorMessage,
		label,
		type,
		name,
		pattern,
		placeholder,
		isRequired = false,
	} = props;
	const {translate} = useTranslate();
	const inputErrorMessage = errorMessage ? translate(errorMessage) : undefined;

	return (
		<div className="form-input__container form-input__container--input">
			<label className="form-input__label" htmlFor={name}>
				{translate(`${label}`)}
			</label>
			<input
				className="form-input__input txt-s"
				name={name}
				type={type}
				placeholder={translate(`${placeholder}`)}
				required={isRequired}
				defaultValue={defaultValue}
				pattern={pattern?.source}
				title={inputErrorMessage}
			/>
		</div>
	);
});
