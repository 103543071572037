import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import location from 'src/assets/images/icons/no-location.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';

export const DifferentRestaurantModal = observer(({open, onClose}: CustomModalProps) => {
	const {translate} = useTranslate();
	const history = useHistory();

	const handleOnChange = () => {
		history.push('/restaurants');
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<div className="profile-modal">
				<div className="profile-modal__close close" onClick={() => onClose()}></div>
				<div className="profile-modal__wrapper">
					<ReactSVG src={location} className={'different-restaurant__icon--warning'} />
					<p className="profile-modal__title txt-uppercase txt-bold text-20">
						{translate('DifferentRestaurantModal.title')}
					</p>
					<p className="profile-modal__content txt-16">
						{translate('DifferentRestaurantModal.text')}
					</p>
				</div>
				<div className="delete-item__btn">
					<button
						className="home-main__btn btn__secondary--inverse btn-padding"
						onClick={handleOnChange}>
						{translate('App.yes')}
					</button>
					<button className="home-main__btn btn__secondary btn-padding" onClick={() => onClose()}>
						{translate('App.no')}
					</button>
				</div>
			</div>
		</Modal>
	);
});
