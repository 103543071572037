import React from 'react';

import {AuthStatus, StatusComponent} from 'src/components/Auth/StatusComponent';

export const AccountCreationMailSentPage = () => {
	return (
		<main className="auth-main">
			<StatusComponent
				title={`Profile.title.email_sent`}
				redirect={'/profile/create'}
				redirectTitle="Profile.link.email"
				status={AuthStatus.sent}
			/>
		</main>
	);
};
