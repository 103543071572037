import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import { useLocation } from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

export const BasketHeader = observer(() => {
	const {translate} = useTranslate();
	const location = useLocation();
	const headerTitle = useMemo(() => {
		return location.pathname.includes('checkout') ? 'CheckoutPageRecap.title' : 'Basket.order_summary';
	}, [location]);
	const {
		basket: {productCount},
	} = useStore();

	return (
		<div className="basket-header">
			<h2
				className={`basket-header__title txt-h2 txt-uppercase ${
					productCount && 'notification notification--left txt-m'
				}`}>
				{translate(headerTitle)}
			</h2>
		</div>
	);
});
