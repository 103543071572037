import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

export const CheckoutNavbar = () => {
	const {translate} = useTranslate();
	return (
		<header className="navbar navbar-disabled">
			<div className="navbar__container theme-container">
				<h1 className="txt-white txt-center txt-uppercase navbar__container--title">
					{translate('Navbar.payment')}
				</h1>
			</div>
		</header>
	);
};
