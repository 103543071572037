import React from 'react';

interface FilterItemProps {
	name: string;
  onClick: () => void;
  buttonClassName: string;
}
export const FilterItem = (props: FilterItemProps) => {
	const {name, onClick, buttonClassName} = props;

	return (
		<li className="filter-item">
			<button onClick={onClick} className={buttonClassName}>{name}</button>
		</li>
	);
};
