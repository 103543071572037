import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useHistory, useParams} from 'react-router-dom';
import store from 'store';

import {ErrorTryAgainModal} from 'src/components/Auth/ErrorTryAgainModal';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {ProfileCreatedModal} from 'src/components/Modal/CustomModals/ProfileCreatedModal';
import {CreateDetailsProfileComponent} from 'src/components/Profile/CreateDetailsProfileComponent';
import {FormProfileData} from 'src/components/Profile/FormProfileComponent';
import {useStore} from 'src/contexts/store.context';

interface TokenParam {
	token: string;
}
interface UserDataLogin {
	email: string;
	password: string;
}

export const ProfileConfirmationPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate('ProfileConfirmationPage.title')} | ${
		process.env.REACT_APP_BRAND_NAME
	}`;

	const history = useHistory();
	const {token} = useParams<TokenParam>();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [protectionPolicy, setProtectionPolicy] = useState(false);
  const [offersMessage, setOffersMessage] = useState(false);
	const [userDataLogin, setUserDataLogin] = useState<UserDataLogin>();

	const {
		auth: {createUserConfirmation, loginUser},
	} = useStore();

  const {executeRecaptcha} = useGoogleReCaptcha();

	const onSubmit = async (userData: Omit<FormProfileData, 'confirm_password'>) => {
    if (!executeRecaptcha) return;
    const captchaToken = await executeRecaptcha();
		// TODO: change any
		createUserConfirmation(token as any, userData, protectionPolicy, offersMessage, captchaToken)
			.then((response: any) => {
				setUserDataLogin(response);
				setModalIsOpen(true);
				store.remove('user');
			})
			.catch((e: any) => {
				console.error(e);
				setDisplayError(true);
			});
	};

	return (
		<main className="auth-main">
			<CreateDetailsProfileComponent
				onSubmit={onSubmit}
				protectionPolicy={protectionPolicy}
				setProtectionPolicy={setProtectionPolicy}
				offersMessage={offersMessage}
				setOffersMessage={setOffersMessage}
			/>
			<ProfileCreatedModal
				open={modalIsOpen}
				onClose={() => {
					userDataLogin && loginUser(userDataLogin.email, userDataLogin.password);
					history.push('/restaurants');
				}}
			/>
			<ErrorTryAgainModal open={displayError} onClose={() => setDisplayError(false)} errorText={''} />
		</main>
	);
});
