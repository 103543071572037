import enFlag from "src/assets/images/languages/en.png";
import frFlag from "src/assets/images/languages/fr.png";

export const useCountryFlag: () => ((countryCode: string) => string)[] = () => {
  const getFlag = (countryCode: string) => {
    switch (countryCode) {
      case "fr":
        return frFlag;
      default:
        return enFlag;
    }
  };

  return [getFlag];
};
