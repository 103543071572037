import {RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {PickRestaurantModal} from 'src/components/Restaurant/RestaurantItem';
import {useStore} from 'src/contexts/store.context';
import {useRestaurantStatusChecks} from 'src/hooks/restaurant-status-checks.hook';

interface OrderDisabledModalProps {
	status: PickRestaurantModal;
	setStatus: (status: PickRestaurantModal) => void;
	restaurantInfo: RestaurantObject;
}

export const OrderDisabledConfirmationModal = observer(
	({status, setStatus, restaurantInfo}: OrderDisabledModalProps) => {
		const {translate} = useTranslate();
		const {acceptUnavailableRestaurant} = useRestaurantStatusChecks();
		const displayThisModal = useMemo(
			() => status === PickRestaurantModal.UNAVAILABLE_RESTAURANT,
			[status],
		);
		const [open, setOpen] = useState<boolean>(displayThisModal ? true : false);

		const {
			restaurant: {setCurrentRestaurant},
			basket: {isBasketEmpty, resetBasket},
		} = useStore();

		useEffect(() => setOpen(displayThisModal ? true : false), [displayThisModal]);

		const handlePickRestaurant = useCallback(() => {
			acceptUnavailableRestaurant();
			setCurrentRestaurant(restaurantInfo);
			setStatus(PickRestaurantModal.NULL);
			if(!isBasketEmpty) {
				resetBasket();
			}
		}, [acceptUnavailableRestaurant, restaurantInfo, setCurrentRestaurant, setStatus, resetBasket, isBasketEmpty]);

		return (
			<Modal className="modal__order-disabled" open={open} onClose={() => {}}>
				<div className="order-disabled">
					<p className="order-disabled__text">
						{translate('OrderDisabledModal.message')}
						{!isBasketEmpty && (
							<p className="order-disabled__text">
								{translate('OrderDisabledModal.basket_not_empty')}
							</p>
						)}
					</p>
					<div className="order-disabled__actions">
						<button
							className="order-disabled__btn order-disabled__btn--change btn__secondary--inverse"
							onClick={() => setStatus(PickRestaurantModal.NULL)}>
							{translate('OrderDisabledModal.cancel')}
						</button>
						<button
							className="order-disabled__btn order-disabled__btn--continue btn__secondary"
							onClick={() => handlePickRestaurant()}>
							{translate('OrderDisabledModal.continue')}
						</button>
					</div>
				</div>
			</Modal>
		);
	},
);
