import {OrderObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {TypeOfChange} from 'src/components/Order/EditOrder/EditOrderContent';
import {OrderContainer} from 'src/components/Order/OrderContainer';

interface UnableEditOrderProps {
  EditType: TypeOfChange;
  currentOrder: OrderObject;
}

export const OrderUnableToEdit = observer(({EditType, currentOrder}: UnableEditOrderProps) => {
	const {translate} = useTranslate();
	const history = useHistory();

	return (
		<div className="current-order__div">
      {EditType === TypeOfChange.PICKUP_TIME ? (
        <>
          <p className="current-order__text txt-xleft">{translate('ModifyOrderPickupPage.already_injected')}</p>
          <div className="current-order__container">
            <OrderContainer order={currentOrder} 
              cancellation={false} 
              isPickUpModification={true} 
            />
          </div>
        </>
      ) : (
        <>
          <p className="current-order__text txt-center txt-error txt-l txt-bold">
            {translate('CancelOrderPage.error')}
          </p>
          <div className="current-order__actions txt-center">
            <button
              className="current-order__button btn__primary"
              onClick={() => history.push('/order')}>
              {translate('CancelOrderPage.button.newOrder')}
            </button>
          </div>
        </>
      )}
		</div>
	);
});
