import {OrderItemObject} from '@acrelec-cloud/apico-shared';
import {observer} from 'mobx-react-lite';
import React from 'react';

import WarningIcon from 'src/assets/images/icons/warning.png';
import {useTranslate} from 'src/components/Languages/translate.hook';

import {Modal} from '../../Modal';
import {CustomModalProps} from '../../modal.model';

interface UnavailableProductModalProps extends CustomModalProps {
	callback: () => void;
	unavailable: OrderItemObject[];
}
export const UnavailableProductModal = observer(
	({callback, unavailable, open, onClose}: UnavailableProductModalProps) => {
		const {translate} = useTranslate();
		return (
			<Modal open={open} onClose={onClose}>
				<div className="unavailable-product-modal">
					<p className="txt-center txt-bold txt-xl">{translate('UnavailableProductModal.title')}</p>
					<div className="unavailable-product-modal__warning">
						<img src={WarningIcon} alt="warning icon" />
					</div>
					<p className="txt-center txt-bold txt-l">{translate('UnavailableProductModal.text')}</p>
					<div className="products-list">
						<ul>
							<li>{translate('UnavailableProductModal.missing_products')}</li>{' '}
							{unavailable.map((item: OrderItemObject, index: number) => (
								<li key={index}>
									{index !== 0 && ', '} {item.productSnapshot.name}
								</li>
							))}
						</ul>
					</div>
					<div className="unavailable-product-modal__button">
						<button onClick={onClose} className="btn__secondary--inverse">
							{translate('UnavailableProductModal.left_button')}
						</button>
						<button onClick={callback} className="btn__secondary">
							{translate('UnavailableProductModal.right_button')}
						</button>
					</div>
				</div>
			</Modal>
		);
	},
);
