import { RestaurantObject } from "@acrelec-cloud/apico-sdk";
import store from "store";

import {GlobalRouterModal} from 'src/routers/GlobalRouter';

interface UseRestaurantStatusChecks {
  defaultInterval: number;
  checkRestaurantStatus: (restaurant?: RestaurantObject) => GlobalRouterModal;
  acceptUnavailableRestaurant: () => void;
  resetUnavailableRestaurant: () => void;
}

enum localStorage {
  ACCPEPTED_UNAVAILABLE = "acceptedUnavailableRestaurant",
  PREVIOUS_STATUS = "restaurantPreviousStatus",
}

export const useRestaurantStatusChecks = (): UseRestaurantStatusChecks => {
  const setLocalStorage = (label: string, value: string | boolean): void => store.set(label, value);
  const resetLocalStorage = (label: string): void => store.remove(label);

  // Convert seconds to miliseconds
  const defaultInterval =
    parseInt(process.env.REACT_APP_CAMPAIGN_CHECK_INTERVAL, 10) * 100;

  const checkRestaurantStatus = (
    restaurant?: RestaurantObject
  ): GlobalRouterModal => {
    if (restaurant) {
      const ordersEnabled = restaurant.settings.ordersEnabled;
      const acceptedPreviously = store.get(localStorage.ACCPEPTED_UNAVAILABLE);
      const previousStatus = store.get(localStorage.PREVIOUS_STATUS);

      // UNAVAILABLE_RESTAURANT
      if (
        !acceptedPreviously &&
        ordersEnabled === false &&
        previousStatus !== ordersEnabled
      ) {
        setLocalStorage(localStorage.PREVIOUS_STATUS, ordersEnabled);
        return GlobalRouterModal.UNAVAILABLE_RESTAURANT;
      }

      // AVAILABLE_RESTAURANT
      if (
        ordersEnabled &&
        previousStatus !== undefined &&
        previousStatus !== ordersEnabled
      ) {
        setLocalStorage(localStorage.PREVIOUS_STATUS, ordersEnabled);
        return GlobalRouterModal.AVAILABLE_RESTAURANT;
      }

      // NULL = Don't open any modal
      if (ordersEnabled) resetUnavailableRestaurant();
    }
    return GlobalRouterModal.NULL;
  };

  const acceptUnavailableRestaurant = (): void => {
    setLocalStorage(localStorage.ACCPEPTED_UNAVAILABLE, true);
  };

  const resetUnavailableRestaurant = (): void => {
    resetLocalStorage(localStorage.ACCPEPTED_UNAVAILABLE);
  };

  return {
    checkRestaurantStatus,
    defaultInterval,
    acceptUnavailableRestaurant,
    resetUnavailableRestaurant,
  };
};
