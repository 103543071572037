import React, {ReactNode} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

interface UseFormErrors {
	handleErrors: (errorType: string, field: string) => ReactNode;
	errorClass: (error: any) => string;
}

export const useFormErrors = (): UseFormErrors => {
	const {translate} = useTranslate();

	const handleErrors = (errorType: string, field: string): ReactNode => {
		const generalError: string[] = ['required'];

		const errorMessagePath = generalError.includes(errorType)
			? `Form.errors.${errorType}`
			: `Form.errors.${errorType}.${field}`;

		return <p className="txt-error">{translate(errorMessagePath)}</p>;
	};

	const errorClass = (error: any): string => (error ? ' form__input--error' : '');

	return {handleErrors, errorClass};
};
