import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {EditProfileComponent} from 'src/components/Profile/EditProfileComponent';
import {useStore} from 'src/contexts/store.context';

export interface EditProfileData {
	firstName: string;
	lastName: string;
	phoneNumber?: string;
}

export const ProfileUpdatePage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate(`ProfilePage.title`)} | ${process.env.REACT_APP_BRAND_NAME}`;

	const history = useHistory();

	const {
		auth: {updateProfile},
	} = useStore();

	const onSubmit = (userData: EditProfileData) => {
		updateProfile(userData);
		history.push('/profile');
	};

	return (
		<main className="auth-main">
			<EditProfileComponent onSubmit={onSubmit} />
		</main>
	);
});
