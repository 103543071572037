import {OrderObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {useStore} from 'src/contexts/store.context';

export interface CheckinOrderModalProps {
	open: boolean;
	onClose: () => void;
	order: OrderObject;
}

export const CheckinOrderModal = observer(({open, onClose, order}: CheckinOrderModalProps) => {
	const {translate} = useTranslate();

	const {
		order: {updateOrder},
		restaurant: {currentRestaurant},
	} = useStore();

	return (
		<Modal open={open} onClose={onClose}>
			<div className="delete-item">
				<div className="close" onClick={() => onClose()}></div>
				<p className="delete-item__text">{translate('CheckinOrderModal.text')}</p>
				<div className="delete-item__btn">
					<button
						className="home-main__btn btn__secondary--inverse btn-padding"
						onClick={() => onClose()}>
						{translate('App.no')}
					</button>
					<button
						className="home-main__btn btn__secondary btn-padding"
						onClick={() => {
							onClose();
							const currentDate = new Date();
							const newRetrievalDate = new Date(
								currentDate.getTime() + currentRestaurant.settings.ordersInjectionDelay * 60000,
							);
							updateOrder(order.id, newRetrievalDate);
						}}>
						{translate('App.yes')}
					</button>
				</div>
			</div>
		</Modal>
	);
});
