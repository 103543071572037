import {OrderObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {OrderRating} from 'src/components/Order/OrderRating';

export interface OrderRatingModalProps {
	open: boolean;
	onClose: () => void;
	order: OrderObject;
	lastRating: number;
	onSetLastRating: any;
}

export const OrderRatingModal = observer(
	({open, onClose, order, lastRating, onSetLastRating}: OrderRatingModalProps) => {
		const {translate} = useTranslate();
		const history = useHistory();

		const handleOnClose = () => {
			onSetLastRating(0);
			onClose();
		};

		const handleSendReport = () => {
			history.push(`/feedback/${order.id}`);
			onSetLastRating(0);
			onClose();
		};

		return (
			<Modal open={open} onClose={onClose}>
				<div className="profile-modal">
					<div className="profile-modal__close close" onClick={() => onClose()}></div>
					<div className="profile-modal__wrapper">
						<p className="profile-modal__title txt-uppercase txt-bold text-20">
							{translate('OrderRatingModal.success.title')}
						</p>
						<div className="profile-modal__rating">
							<div className="order-container">
								<div className="order-container--rating-modal">
									<OrderRating order={order} isInModal={true} lastRating={lastRating} />
								</div>
							</div>
						</div>
						<p className="profile-modal__content txt-16">
							{translate('OrderRatingModal.success.text')}
						</p>
					</div>
					<button className="btn__secondary--inverse profile-modal__button" onClick={handleOnClose}>
						{translate('OrderRatingModal.no')}
					</button>
					<button className="btn__secondary profile-modal__button" onClick={handleSendReport}>
						{translate('OrderRatingModal.yes')}
					</button>
				</div>
			</Modal>
		);
	},
);
