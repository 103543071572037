import React, {useEffect, useState} from 'react';

export interface QuantityMessageProps {
	id?: number | string;
	value: number | null;
	min: number;
	max: number;
	operation: 'remove' | 'add';
}

interface QuantityPickerProps {
	value?: number;
	min?: number;
	max?: number;
	id?: number | string;
	hideButtonOnQuantityReached?: boolean;
	onChange: ({value, min, max, operation}: QuantityMessageProps) => void;
}

export const QuantityPicker = (props: QuantityPickerProps) => {
	// Set default values if no values have been passed to the component
	const {
    value = 0, 
    min = 1, 
    max = Number(process.env.REACT_APP_MAX_BASKET_PRODUCT_QTY), 
    hideButtonOnQuantityReached = true, 
    id, 
    onChange
  } = props;
	const [inputValue, setInputValue] = useState<number>(value ? value : 0);

	const setValue = (value: number, operation: 'remove' | 'add') => {
		if (value >= min && value <= max) {
			value > 0 && setInputValue(value);
			onChange({value, min, max, id, operation});
		}
	};

	const increment = () => {
		const newValue = inputValue + 1;
		setValue(newValue, 'add');
	};
	const decrement = () => {
		const newValue = inputValue - 1;
		setValue(newValue, 'remove');
	};

	useEffect(() => {
		setInputValue(value as number);
	}, [value]);

	return (
		<div className="quantity-picker__container">
			<div className="form-control">
				<button
					className="form-control__btn form-control__btn-minus"
					disabled={value === min && hideButtonOnQuantityReached}
					onClick={decrement}></button>
				<p className="form-control-quantity txt-secondary-dark">{inputValue}</p>
				<button
					className="form-control__btn form-control__btn-more"
					disabled={value === max && hideButtonOnQuantityReached}
					onClick={increment}></button>
			</div>
		</div>
	);
};
