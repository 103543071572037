import { scrollTo } from "src/utils/scroll-to";

const DISPATCHER_OPEN = "dispatcher--open";
const PARTS_ITEM_OPEN = "parts__item--open";

const toggleChildren = (children: any, TOGGLE_CLASS: string): void => {
  if (children.length > 0) {
    children.forEach((child: any) => {
      child.classList.contains(TOGGLE_CLASS)
        ? child.classList.remove(TOGGLE_CLASS)
        : child.classList.add(TOGGLE_CLASS);
    });
  } else {
    children.classList.contains(TOGGLE_CLASS)
      ? children.classList.remove(TOGGLE_CLASS)
      : children.classList.add(TOGGLE_CLASS);
  }
};

// const openChildren = (children: any, TOGGLE_CLASS: string): void => {
//   if (children.length > 0) {
//     children.forEach((child: any) => child.classList.add(TOGGLE_CLASS));
//   } else {
//     children.classList.add(TOGGLE_CLASS);
//   }
// };

const closeChildren = (children: any, TOGGLE_CLASS: string): void => {
  if (children.length > 0) {
    children.forEach((child: any) => child.classList.remove(TOGGLE_CLASS));
  } else {
    children.classList.remove(TOGGLE_CLASS);
  }
};

const getElementsToToggle = (event: any) => {
  const currentPartItem = event.target.closest(".parts__item");
  const siblingChildren = [...currentPartItem.nextSibling.children];
  const siblingHasTitle = siblingChildren.find((e) =>
    e.classList.contains("parts__item")
  );

  const currentPartDispatcher = event.target.closest(".dispatcher");
  const currentPartItemChildren = [...currentPartDispatcher.childNodes].filter(
    (e) => e.classList.value.includes("dispatcher")
  );
  const dispatchersChildren = siblingChildren.filter((child) =>
    child.classList.contains("dispatcher")
  );

  return {
    currentPartItemChildren,
    siblingHasTitle,
    dispatchersChildren,
    currentPartItem,
  };
};

export const toggleDispatchersChildren = (event: any): void => {
  const {
    currentPartItemChildren,
    siblingHasTitle,
    dispatchersChildren,
    currentPartItem,
  } = getElementsToToggle(event);

  toggleChildren(currentPartItemChildren, DISPATCHER_OPEN);
  !siblingHasTitle && toggleChildren(dispatchersChildren, DISPATCHER_OPEN);
  toggleChildren(currentPartItem, PARTS_ITEM_OPEN);
};

export const showDispatchersChildren = (event: any): void => {
  // #1 close all
  const grandParent = event.target.closest(".dispatcher").parentElement;
  const sibligns = [
    ...event.target.closest(".dispatcher").parentElement.children,
  ];
  const dispatcherSiblings = sibligns.filter((dispatcher: any) =>
    dispatcher.classList.contains("dispatcher")
  );

  dispatcherSiblings.forEach((dispatcher: any) => {
    const dispatcherOpen = [
      ...dispatcher.querySelectorAll(`.${DISPATCHER_OPEN}`),
    ];
    const partsItemOpen = [
      ...dispatcher.querySelectorAll(`.${PARTS_ITEM_OPEN}`),
    ];

    dispatcherOpen.length > 0 && closeChildren(dispatcherOpen, DISPATCHER_OPEN);
    partsItemOpen.length > 0 && closeChildren(partsItemOpen, PARTS_ITEM_OPEN);
  });

  // #2 open current
  const {
    currentPartItemChildren,
    siblingHasTitle,
    dispatchersChildren,
    currentPartItem,
  } = getElementsToToggle(event);

  toggleChildren(currentPartItemChildren, DISPATCHER_OPEN);
  !siblingHasTitle && toggleChildren(dispatchersChildren, DISPATCHER_OPEN);
  toggleChildren(currentPartItem, PARTS_ITEM_OPEN);

  scrollTo("top", grandParent);
};
