import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslate } from 'src/components/Languages/translate.hook';
import { PopoverTypes, ComponentNameTypes } from 'src/components/Popover/custom-popovers.config';
import { usePopover } from 'src/components/Popover/popover.hook';

import { Modal } from '../../Modal';
import { CustomModalProps } from '../../modal.model';


export const ReorderSuccessModal = ({open, onClose}: CustomModalProps) => {
  const {translate} = useTranslate();
  const {openPopover} = usePopover();
  const history = useHistory();

  const onOpenBasket = () => {
    openPopover(PopoverTypes.RIGHT, ComponentNameTypes.Basket);
    onClose();
  }

  const onContinueOrder = () => {
    history.push('/order');
    onClose();
  }

  return <Modal open={open} onClose={onClose}>
    <div className="reorder-success">
      <p className="txt-center txt-bold txt-xl">{translate('ReorderSuccessModal.title')}</p>
      <div className="check check--success"></div>
      <p className="txt-center txt-bold txt-l">{translate('ReorderSuccessModal.text')}</p>
      <div className="reorder-success__buttons">
        <button className="btn__secondary--inverse" onClick={onOpenBasket}>{translate('ReorderSuccessModal.open_basket')}</button>
        <button className="btn__secondary" onClick={onContinueOrder}>{translate('ReorderSuccessModal.continue')}</button>
      </div>
    </div>
  </Modal>;
}