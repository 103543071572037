import React from 'react';
import {Redirect, Route} from 'react-router';

interface ProtectedRouteProps {
	// When true, will only match if the path matches the location.pathname exactly.
	exact?: boolean;
	path: string;
	children: React.ReactNode;
	canAccess: boolean;
	redirectLink: string;
}
export const ProtectedRoute = (props: ProtectedRouteProps) => {
	const {exact = false, path, children, canAccess, redirectLink} = props;
	return (
		<Route
			exact={exact}
			path={path}
			render={() => (canAccess ? children : <Redirect to={{pathname: redirectLink}} />)}
		/>
	);
};
