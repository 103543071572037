import { toJS } from "mobx";

import {
  compareParents,
  findCurrentPartByParent,
} from "src/components/Products/Parts/utils/find-current-part.util";
import { useStore } from "src/contexts/store.context";

export interface SimplePart {
  code: number;
  parent: number[];
}

export enum PartType {
  hasChildren = "hasChildren",
  hasSiblings = "hasSiblings",
  isSingle = "isSingle",
}

export interface CheckLeftover {
  [type: string]: (part: SimplePart) => 0 | 1;
}

interface RequiredLeftoverProps {
  checkRequiredLeftover: CheckLeftover;
  countRequiredChildren: (part: SimplePart) => number;
  countRequiredSiblings: (part: SimplePart) => number;
}

export const useRequiredLeftover = (): RequiredLeftoverProps => {
  const {
    customization: { required },
  } = useStore();

  const checkChildrenLeftover = (part: SimplePart) => {
    const directParentCode = part.parent.filter((value) => value).pop();
    const currentRequiredChildren = toJS(required).filter((p) =>
      p.parent.includes(directParentCode!)
    );
    const childrenLeftovers = currentRequiredChildren.map((p) =>
      p.min - p.qty <= 0 ? 0 : 1
    );

    return childrenLeftovers.reduce((acc: any, curr: any) => acc + curr);
  };

  const checkSiblingsLeftover = (part: SimplePart) => {
    const currentSiblings = toJS(required).filter((p) =>
      compareParents(p.parent, part.parent)
    );
    const siblingsLeftovers = currentSiblings.map((p) =>
      p.min - p.qty <= 0 ? 0 : 1
    );
    return siblingsLeftovers.reduce((acc: any, curr: any) => acc + curr);
  };

  const checkSingleLeftover = (part: SimplePart) => {
    const currentRequiredPart = findCurrentPartByParent(toJS(required), {
      code: part?.code,
      parent: part?.parent,
    });
    return currentRequiredPart
      ? currentRequiredPart.min - currentRequiredPart.qty <= 0
        ? 0
        : 1
      : 1;
  };

  const checkRequiredLeftover: CheckLeftover = {
    [PartType.hasChildren]: (part: SimplePart) => checkChildrenLeftover(part),
    [PartType.hasSiblings]: (part: SimplePart) => checkSiblingsLeftover(part),
    [PartType.isSingle]: (part: SimplePart) => checkSingleLeftover(part),
  };

  const countRequiredChildren = (part: SimplePart) =>
    toJS(required).filter((p) => p.parent.includes(part!.code)).length;

  const countRequiredSiblings = (part: SimplePart) =>
    toJS(required).filter((p) => compareParents(p.parent, part!.parent)).length;

  return {
    checkRequiredLeftover,
    countRequiredChildren,
    countRequiredSiblings,
  };
};
