export const scrollTo = (
  direction: "bottom" | "top",
  destinyElement?: HTMLDivElement
) => {
  const destiny = destinyElement
    ? destinyElement
    : document.getElementsByTagName("main")[0];

  const scrollTo = {
    bottom: () =>
      destiny.scrollIntoView({
        behavior: "smooth",
        block: "center",
      }),
    top: () =>
      destiny.scrollIntoView({
        behavior: "smooth",
        block: "start",
      }),
  };
  setTimeout(() => scrollTo[direction](), 200);
};
