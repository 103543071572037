import {BasketItemPartsPros, TransformedParts} from '@acrelec-cloud/apico-cdk';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

export const BasketItemParts = (props: BasketItemPartsPros) => {
	const {parts} = props;
	const {translateProduct} = useTranslate();

	return (
		<ul className="basket-item__parts-list">
			{parts.map((part: TransformedParts, index: number) => (
				<li key={index} className="txt-xlight txt-xs">
					{`${part.quantity} ${translateProduct(part).name}`}
				</li>
			))}
		</ul>
	);
};
