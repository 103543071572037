import { useStore } from 'src/contexts/store.context';
import { rootSnapshot, firstStateOfCustomizationStore } from 'src/stores/root.store';

export const useCustomization: any = () => {
  const {customization: {currentProduct}} = useStore();

  const resetCustomization = (newProductCode: number) => {
    if (!currentProduct) {
      return;
    }
    if (currentProduct.code !== newProductCode) {
      rootSnapshot("customization").reset(firstStateOfCustomizationStore);
      return;
    }
    rootSnapshot("customization").applyInitialState();
  }

  return { resetCustomization };
};
