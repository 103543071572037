import {InfoWindow, Marker} from '@react-google-maps/api';
import {observer} from 'mobx-react-lite';
import React, {Dispatch, SetStateAction} from 'react';

import {InfoWindowData} from 'src/components/Restaurant/Map/InfoWindowData';
import {gMap} from 'src/components/Restaurant/Map/map.config';
import {OpenInfoIndexType} from 'src/components/Restaurant/Map/MapComponent';
import {useStore} from 'src/contexts/store.context';

export interface MapMarkerProps {
	index: number;
	restaurant: any;
	OpenInfoIndexState: [OpenInfoIndexType, Dispatch<SetStateAction<OpenInfoIndexType>>];
	showInfoWindow: boolean;
	userLocation: any;
	distanceFormat: any;
}

export const MapMarker = observer(
	({
		index,
		restaurant,
		OpenInfoIndexState: [openInfoIndex, setOpenInfoIndex],
		showInfoWindow,
		userLocation,
		distanceFormat,
	}: MapMarkerProps) => {
		const {
			restaurant: {currentRestaurant},
		} = useStore();

		const activeMarker = process.env.REACT_APP_MARKER_COLOR_ACTIVE;
		const inactiveMarker = process.env.REACT_APP_MARKER_COLOR_INACTIVE;

		const gmap = gMap();

		return (
			<Marker
				key={index}
				position={{
					lat: restaurant.geolocation.latitude || 0,
					lng: restaurant.geolocation.longitude || 0,
				}}
				onClick={() => {
					setOpenInfoIndex((el) => (el === index ? undefined : index));
				}}
				icon={{
					...gmap.icon,
					fillColor: restaurant.id === currentRestaurant?.id ? activeMarker : inactiveMarker,
					anchor: new google.maps.Point(15, 30),
				}}>
				{showInfoWindow && (
					<InfoWindow
						options={{disableAutoPan: true}}
						onCloseClick={() => {
							setOpenInfoIndex(undefined);
						}}>
						<InfoWindowData
							restaurant={restaurant}
							userLocation={userLocation}
							distanceFormat={distanceFormat}
						/>
					</InfoWindow>
				)}
			</Marker>
		);
	},
);
