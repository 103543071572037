import {OrderObject, PaginatedResponse} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {LoadingSpinner} from 'src/components/LoadingSpinner/LoadingSpinner';
import {GoBack} from 'src/components/Order/GoBack';
import {OrderContainer} from 'src/components/Order/OrderContainer';
import {OrdersPagination} from 'src/components/Pagination/OrdersPagination';
import {useStore} from 'src/contexts/store.context';

export const OrderHistoryPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate('OrderHistoryPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isPaginationLoading, setIsPaginationLoading] = useState<boolean>(false);
	const [orders, setOrders] = useState<OrderObject[]>([]);
	const [scrollTo, setScrollTo] = useState<number>(0);

	const {
		order: {fetchOrderHistory},
	} = useStore();

	const handleShowMore = (): void => {
		setIsPaginationLoading(true);
		setScrollTo(orders.length);
		fetchOrderHistory(
			Number(process.env.REACT_APP_ORDERS_LIMIT),
			orders.length,
			process.env.REACT_APP_ORDER_HISTORY_FILTERS,
		).then((response: PaginatedResponse<OrderObject>) => {
			setOrders([...orders, ...response.items]);
			setIsPaginationLoading(false);
		});
	};

	useEffect(() => {
		fetchOrderHistory(
			Number(process.env.REACT_APP_ORDERS_LIMIT),
			0,
			process.env.REACT_APP_ORDER_HISTORY_FILTERS,
		)
			.then((response: PaginatedResponse<OrderObject>) => {
				setIsLoading(false);
				setOrders(response.items);
			})
			.catch(() => {
				setIsLoading(false);
			});
	}, [fetchOrderHistory]);

	useEffect(() => {
		if (orders.length > Number(process.env.REACT_APP_ORDERS_LIMIT)) {
			document.getElementById('last-order-viewed')?.scrollIntoView({
				behavior: 'smooth',
			});
		}
	}, [orders]);

	const orderPageContent = useMemo(
		() =>
			orders && orders.length > 0 ? (
				<>
					{orders.map((order: OrderObject, index: number) => {
						return (
							<div
								className="current-order__container"
								key={index}
								{...(index + 1 === scrollTo && {
									id: 'last-order-viewed',
								})}>
								<OrderContainer order={order}></OrderContainer>
							</div>
						);
					})}
					{isPaginationLoading ? (
						<div className="loading-div">
							<LoadingSpinner />
						</div>
					) : (
						<OrdersPagination ordersLength={orders.length} showMore={() => handleShowMore()} />
					)}
				</>
			) : (
				<p>{translate('OrderHistoryPage.no_order')}</p>
			),
		// if you add handleShowMore as a dependency it will re-render exhaustively
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[orders, translate],
	);

	return (
		<main className="current-order-main">
			{isLoading ? (
				<div className="loading-div">
					<LoadingSpinner />
				</div>
			) : (
				<div className="current-order">
					<GoBack />
					<div className="page-title txt-xxl">{translate('OrderHistoryPage.title')}</div>
					{orderPageContent}
				</div>
			)}
		</main>
	);
});
