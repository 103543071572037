import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {CustomModalProps} from 'src/components/Modal/modal.model';

interface DeletePaymentMethodModal extends CustomModalProps {
	deleteCard: () => void;
}

export const DeletePaymentMethodModal = observer(
	({open, onClose, deleteCard}: DeletePaymentMethodModal) => {
		const {translate} = useTranslate();

		const deleteItem = useCallback(() => {
			onClose();
			// TODO: Find a better way to do this not using setTimeout
			setTimeout(() => deleteCard(), 100);
		}, [deleteCard, onClose]);

		return (
			<Modal open={open} onClose={onClose}>
				<div className="delete-item">
					<div className="close" onClick={() => onClose()}></div>
					<p className="delete-item__text">{translate('DeletePaymentMethodModal.text')}</p>
					<div className="delete-item__btn">
						<button
							className="home-main__btn btn__secondary--inverse btn-padding"
							onClick={() => onClose()}>
							{translate('App.no')}
						</button>
						<button
							className="home-main__btn btn__secondary btn-padding"
							onClick={() => deleteItem()}>
							{translate('App.yes')}
						</button>
					</div>
				</div>
			</Modal>
		);
	},
);
