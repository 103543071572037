import {OrderObject, OrderState} from '@acrelec-cloud/apico-sdk';
import React, {useMemo} from 'react';

import {OrderIsCancelled} from 'src/components/Order/EditOrder/OrderIsCancelled';
import {OrderReadyToEdit} from 'src/components/Order/EditOrder/OrderReadyToEdit';
import {OrderUnableToEdit} from 'src/components/Order/EditOrder/OrderUnableToEdit';

export enum TypeOfChange {
  CANCEL_ORDER = 'CANCEL_ORDER',
  PICKUP_TIME = 'PICKUP_TIME',
}

interface EditOrderContentProps {
	currentOrder: OrderObject;
}

// (1) - Cancellation of an order
export const CancelOrderContent = ({currentOrder}: EditOrderContentProps) => {
	const contents = useMemo(
		() => ({
      ALREADY_INJECTED: <OrderUnableToEdit currentOrder={currentOrder} EditType={TypeOfChange.CANCEL_ORDER} />,
			ABLE_TO_CHANGE: <OrderReadyToEdit currentOrder={currentOrder} EditType={TypeOfChange.CANCEL_ORDER} />,
			ORDER_CANCELLED: <OrderIsCancelled />,
		}),
		[currentOrder],
	);

  switch (currentOrder.state) {
    case OrderState.VALIDATED:
      return contents['ABLE_TO_CHANGE'];
    case OrderState.CANCELLED:
      return contents['ORDER_CANCELLED'];
    default:
      return contents['ALREADY_INJECTED'];
  }
};

// (2) - Change pick-up time of an order
export const PickupTimeOrderContent = ({currentOrder}: EditOrderContentProps) => {
  const contents = useMemo(
		() => ({
			ALREADY_INJECTED: <OrderUnableToEdit currentOrder={currentOrder} EditType={TypeOfChange.PICKUP_TIME} />,
			ABLE_TO_CHANGE: <OrderReadyToEdit currentOrder={currentOrder} EditType={TypeOfChange.PICKUP_TIME} />,
			ORDER_CANCELLED: <OrderIsCancelled />,
		}),
		[currentOrder],
	);

	if(currentOrder.state !== OrderState.CANCELLED) {
    if(currentOrder.state === OrderState.INJECTION_ERROR || currentOrder.posId)
      return contents['ALREADY_INJECTED'];
    else return contents['ABLE_TO_CHANGE'];
  } else return contents['ORDER_CANCELLED'];
};
