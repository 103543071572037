import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import store from 'store';

import {useStore} from 'src/contexts/store.context';

export const PaymentSuccess = () => {
	const {
		basket: {resetBasket, setPickUpTime},
	} = useStore();

	useEffect(() => {
		resetBasket();
		setPickUpTime(undefined);
		store.remove('newOrder');
	}, [resetBasket, setPickUpTime]);

	return <Redirect to="/current-order" />;
};
