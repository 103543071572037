import {OrderObject, OrderState, RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {DateTime} from 'luxon';
import React, {useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import bag from 'src/assets/images/icons/paper-bag.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {PickUpTimeModal} from 'src/components/Modal/CustomModals/PickupTimeModal';
import {useEasyDateHour} from 'src/hooks/easy-date-hour.hook';

interface OrderEstimatedProps {
	pickUpTime: string;
	order: OrderObject;
	restaurant: RestaurantObject;
	ordersInjectionDelay: number;
}

export const OrderEstimated = (props: OrderEstimatedProps) => {
	const {pickUpTime, order, restaurant, ordersInjectionDelay} = props;
	const {translate} = useTranslate();
	const {defaultHour} = useEasyDateHour();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

	const injectionDate = DateTime.fromISO(pickUpTime)
		.setZone(restaurant.timeZone)
		.minus({minutes: ordersInjectionDelay});
	const currentDate = DateTime.local().setZone(restaurant.timeZone);
	const timeDiff = currentDate.diff(injectionDate, 'minutes').toObject().minutes || 0;

	const pickUpTimeFormated = useMemo(() => {
		return defaultHour(pickUpTime, restaurant.timeZone);
	}, [defaultHour, pickUpTime, restaurant]);

	const currentUrl = useLocation().pathname;
	const isCancelRoute = currentUrl.includes('cancel-order');
	const displayChangePickupButton = useMemo(() => {
		return order.state === OrderState.VALIDATED && !isCancelRoute && timeDiff < 0;
	}, [isCancelRoute, order.state, timeDiff]);
	const displayPickUpTime = useMemo(() => {
		return order.state !== OrderState.INJECTION_ERROR && order.state !== OrderState.PAYMENT_ERROR && order.state !== OrderState.CANCELLED;
	}, [order]);

	return (
		<>
			{displayPickUpTime && (
				<div className="order-container__estimated-time estimated-time">
					<div className="estimated-time__content">
						<ReactSVG src={bag} width={30} height={30} />
						<p className="estimated-time__text txt-xs">
							{translate('OrderContainer.time_estimated')}
							<span className="txt-bold txt-xs"> {pickUpTimeFormated}</span>
						</p>
					</div>
					{displayChangePickupButton && (
						<div className="estimated-time__button-container">
							<button
								className="order-btn__action estimated-time__button btn__primary"
								onClick={() => setModalIsOpen(true)}>
								{translate('OrderContainer.change_pickup')}
							</button>
						</div>
					)}
				</div>
			)}
			<PickUpTimeModal
				orderId={order.id}
				orderRetrievalDate={DateTime.fromISO(pickUpTime, {zone: restaurant.timeZone})}
				open={modalIsOpen}
				onClose={() => setModalIsOpen(false)}
			/>
		</>
	);
};
