import {AxiosError} from 'axios';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import store from 'store';

import {FormComponent} from 'src/components/Form/FormComponent';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {createProfileData} from 'src/components/Profile/create-profile-data.config';
import {useStore} from 'src/contexts/store.context';
import {ErrorType} from 'src/pages/ProfileCreationPage';

interface CreateProfileProps {
	onError: (haveError: boolean, errorType: number) => void;
	displayTitle?: boolean;
}

export const CreateProfileComponent = observer(
	({onError, displayTitle = true}: CreateProfileProps) => {
		const history = useHistory();
		const {translate, currentLanguage} = useTranslate();
		const [isLoading, setIsLoading] = useState<boolean>(false);
		const {
			auth: {createUser},
		} = useStore();

		const signin = (data: any) => {
			setIsLoading(true);
			createUser(data.email, currentLanguage)
				.then((response?: AxiosError) => {
          setIsLoading(false);
					// As the function create user should return void, if it return something, it means that we have a problem.
					if (response && response.response && response.response.status === 409) {
						onError(true, ErrorType.ALREADY_EXIST);
					} else {
            store.set('user', {email: data.email});
            history.push('/profile/mail-sent');
          }
				})
				.catch(() => {
					setIsLoading(false);
					onError(true, ErrorType.UNKNOWN_ERROR);
				});
		};

		return (
			<div className="profile">
				{displayTitle && (
					<div>
						<p className="profile__title txt-xl">{translate(`Profile.title.email`)}</p>
						<p className="profile__paragraph txt-l">{translate(`Profile.paragraph.email`)}</p>
					</div>
				)}

				<FormComponent
					onSubmit={signin}
					classNameForm={'form'}
					classNameField={'form__field'}
					classNameSubmitButton={'form__btn btn__primary'}
					classNameInput={'form__input'}
					inputsConfig={createProfileData}
					isLoading={isLoading}
				/>

				<div>
					<Link to="/restaurants" className="profile__link">
						<p>{translate(`Profile.link.guest`)}</p>
					</Link>
					<p className="profile__account">
						{translate(`Profile.account`)}
						<Link to="/login" className="profile__account__login">
							{translate(`Profile.login`)}
						</Link>
					</p>
				</div>
			</div>
		);
	},
);
