import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

export const BasketEmpty = observer(() => {
	const {translate} = useTranslate();
	return (
		<div>
			<p className="txt-s">{translate('Basket.basket_empty')}</p>
		</div>
	);
});
