import { CustomPartsType } from "@acrelec-cloud/apico-cdk";
import { isEqual } from "lodash";
import { toJS } from "mobx";

import { useStore } from "src/contexts/store.context";
import { useMemoCompare } from "src/hooks/memo-compare.hook";

export const usePartComparator: any = () => {
  const {
    customization: { parts },
  } = useStore();

  const comparedParts = useMemoCompare(
    () => toJS(parts),
    parts,
    (a: CustomPartsType[], b: CustomPartsType[]) =>
      a.length === b.length &&
      a.every((item: CustomPartsType, index: number) => {
        const otherItem = b[index];
        return (
          item.code === otherItem.code &&
          item.qty === otherItem.qty &&
          isEqual(item.parent, otherItem.parent)
        );
      })
  );

  return [comparedParts];
};
