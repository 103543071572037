import {observer} from 'mobx-react-lite';
import React from 'react';

interface WarningMessageProps {
	status?: string;
	hasBackground?: boolean;
	message: string;
}

export const WarningMessage = observer((props: WarningMessageProps) => {
	const {hasBackground = false, message, status = 'warning'} = props;

	return (
		<div className={`warning-message${hasBackground ? ` bg-${status}` : ''}`}>
			<p
				className={`warning-message__text txt-xs txt-bold${
					hasBackground ? 'txt-white' : ` txt-${status}`
				}`}>
				{message}
			</p>
		</div>
	);
});
