import {DateTime} from 'luxon';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {SelectHourComponent} from 'src/components/PickUp/SelectHourComponent';
import {useStore} from 'src/contexts/store.context';

export interface PickUpTimeModalProps {
	open: boolean;
	onClose: () => void;
	orderId: number;
	orderRetrievalDate: DateTime;
}

export const PickUpTimeModal = observer(
	({open, onClose, orderId, orderRetrievalDate}: PickUpTimeModalProps) => {
		const {translate} = useTranslate();
		const [pickUpTime, setPickUpTime] = useState<DateTime>(orderRetrievalDate);
		const {
			order: {updateOrder, fetchCurrentOrders},
		} = useStore();

		return (
			<Modal open={open} onClose={onClose}>
				<div className="change-pickup-time__modal">
					<SelectHourComponent
						title={translate('SelectHourComponent.newTitle')}
						callback={(value: DateTime) => setPickUpTime(value)}
						orderRetrievalDate={orderRetrievalDate}
					/>
					<div className="change-pickup-time__modal__confirm">
						<button
							className="change-pickup-time__modal__confirm__btn btn__primary"
							onClick={(e) => {
								e.preventDefault();
								updateOrder(orderId, pickUpTime);
								fetchCurrentOrders(20, 0, process.env.REACT_APP_CURRENT_ORDER_FILTERS, undefined);
								onClose();
							}}>
							<span>{translate('OrderContainer.confirm')}</span>
						</button>
					</div>
				</div>
			</Modal>
		);
	},
);
