import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import store from 'store';

import {resetPasswordProfileData} from 'src/components/Auth/ResetPassword/reset-password-data.config.';
import {FormComponent} from 'src/components/Form/FormComponent';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

interface ResetLinkForm {
	email: string;
}

export const PasswordRequest = observer(() => {
	const {translate} = useTranslate();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		auth: {requestResetPassword},
	} = useStore();

	const handleSubmit = (data: ResetLinkForm) => {
		setIsLoading(true);
    const dataEmail = data.email.toLowerCase();
		requestResetPassword(dataEmail)
			.then((response: any) => {
				setIsLoading(false);
				history.push('/profile/forgot-password/sent');
				if (response !== 500) {
					store.set('user', {email: dataEmail});
				}
			})
			.catch(() => {
				setIsLoading(false);
				history.push('/profile/forgot-password/fail');
			});
	};

	return (
		<div className="profile">
			<div>
				<p className="profile__title txt-xl">{translate(`Form.resetPasswordForm.reset`)}</p>
				<p className="profile__paragraph txt-l">
					{translate(`Form.resetPasswordForm.reset_email`)}
				</p>
			</div>

			<FormComponent
				onSubmit={handleSubmit}
				classNameForm={'form'}
				classNameField={'form__field'}
				classNameSubmitButton={'form__btn btn__primary'}
				classNameInput={'form__input txt-m'}
				inputsConfig={resetPasswordProfileData}
				isLoading={isLoading}
			/>
		</div>
	);
});
