import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {GlobalRouterModal} from 'src/routers/GlobalRouter';

interface OrderEnableModalProps {
	status: GlobalRouterModal;
}

export const OrderEnableModal = observer(({status}: OrderEnableModalProps) => {
  const {translate} = useTranslate();
  const [open, setOpen] = useState<boolean>(
		status === GlobalRouterModal.AVAILABLE_RESTAURANT ? true : false,
  );
  
  useEffect(
		() => setOpen(status === GlobalRouterModal.AVAILABLE_RESTAURANT ? true : false),
		[status],
	);

	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<div className="profile-modal">
				<div className="profile-modal__close close" onClick={() => setOpen(false)}></div>
				<div className="profile-modal__wrapper">
					<p className="profile-modal__title txt-success txt-uppercase txt-bold text-20">
						{translate('OrderEnable.title')}
					</p>
					<div className="profile-modal__icon"></div>
					<p className="profile-modal__content txt-16">{translate('OrderEnable.content')}</p>
				</div>
				<button className="profile-modal__button btn__secondary" onClick={() => setOpen(false)}>
					{translate('OrderEnable.button')}
				</button>
			</div>
		</Modal>
	);
});
