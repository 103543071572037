import {observer} from 'mobx-react-lite';
import React from 'react';
import {useForm} from 'react-hook-form';

import {useTranslate} from 'src/components/Languages/translate.hook';

interface PhoneFormInputProps {
	label: string;
	name: string;
	pattern?: any;
	placeholder?: string;
	type: string;
}

export const PhoneFormInput = observer((props: PhoneFormInputProps) => {
	const {label, type, name, pattern} = props;
	const {translate} = useTranslate();

	const {errors, register} = useForm({
		mode: 'onChange',
	});

	return (
		<div className="discount__container">
			<div className="form-input__container form-input__container--discount">
				<label className="discount__container__label">{translate(label)}</label>
				<input
					className="form-input__border form-input__border--discount txt-s"
					ref={register({
						pattern: {
							value: pattern,
							message: 'Form.errors.pattern.phone',
						},
					})}
					name={name}
					id={name}
					type={type}
				/>
			</div>

			<div className="form__discount__error">
				{errors.phone_number && (
					<span className="txt-error ">{translate(errors.phone_number.message)}</span>
				)}
			</div>
		</div>
	);
});
