import {CurrentParts, GroupValues} from '@acrelec-cloud/apico-cdk';
import _ from 'lodash';
import React, {ReactNode} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {PartTitle} from 'src/components/Products/Parts/PartTitle';

export interface FirstItem {
	code: number;
	parent: number[];
}

export interface FirstItems {
	required: FirstItem | undefined;
	extra: FirstItem | undefined;
	included: FirstItem | undefined;
}

export interface GroupPartsByType {
	itemsSorted: CurrentParts[];
	firstItems: FirstItems;
}

export interface GroupParts {
	displayGroupTitle: (
		partCode: number,
		partParent: number[],
		firstItems: FirstItems,
	) => ReactNode | undefined;
	groupPartsByType: (parts: CurrentParts[]) => GroupPartsByType;
}

export const useGroupParts = (): GroupParts => {
	const {translate} = useTranslate();

	const groupPartsByType = (parts: CurrentParts[]): GroupPartsByType => {
		// 1. Add itemGroup property on each part
		const groupedItems: CurrentParts[] | undefined = parts?.map((part: CurrentParts) => {
			const hasParts = part.product?.parts && part.product.parts.length > 0;
			const hasOptions = part.product?.options && part.product.options.length > 0;
			const hasChildren = hasParts || hasOptions;
			const isRequired = part.minQuantity > 0;

			if (hasChildren && isRequired) {
				part.itemGroup = GroupValues.required;
			} else if (part.defaultQuantity > 0) {
				part.itemGroup = GroupValues.included;
			} else {
				part.itemGroup = GroupValues.extra;
			}

			return part;
		});

		// 2. sort by: itemGroup ascending > number of options ascending
		const groupedItemsSorted = _.orderBy(
			groupedItems,
			['itemGroup', (item) => item.product?.options?.length],
			['asc', 'asc'],
		);

		// 3. find the first item of each group
		const findFirstItem = (groupId: GroupValues): FirstItem | undefined => {
			const firstItem = groupedItemsSorted.find((item: CurrentParts) => item.itemGroup === groupId);
			const firstItemOptimized = firstItem &&
				firstItem.product && {
					code: firstItem.product.code,
					parent: firstItem.parentArray,
				};

			if (firstItemOptimized) return firstItemOptimized;
			return undefined;
		};

		const firstRequiredItem = findFirstItem(GroupValues.required);
		const firstExtraItem = findFirstItem(GroupValues.extra);
		const firstIncludedItem = findFirstItem(GroupValues.included);

		return {
			itemsSorted: groupedItemsSorted,
			firstItems: {
				required: firstRequiredItem,
				extra: firstExtraItem,
				included: firstIncludedItem,
			},
		};
	};

	const displayGroupTitle = (
		partCode: number,
		partParent: number[],
		firstItems: FirstItems,
	): ReactNode | undefined => {
		let group: string | undefined;

		switch (partCode) {
			case firstItems.included?.code:
				group = 'included';
				break;
			case firstItems.extra?.code:
				group = 'extra';
				break;
			// uncomment to display required options title
			// case firstItems.required?.code:
			// 	group = 'required';
			// 	break;
		}

    if (group === "extra") {
      return;
    }

		if (group) {
			return (
				<PartTitle
					name={translate(`Parts.group_title.${group}`)}
					className="parts__item--group"
					warnignMessageDisplay={false}
					part={{code: partCode, parent: partParent}}
				/>
			);
		}
		return;
	};

	return {groupPartsByType, displayGroupTitle};
};
