import {observer} from 'mobx-react-lite';
import React from 'react';
import {NavLink} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';

export const GoBack = observer(() => {
	const {translate} = useTranslate();
	return (
		<div className="go-back">
			<NavLink to="/order" className="go-back__link">
				<div className="go-back__link__icon"></div>
				<p className="go-back__link__text txt-xs">{translate('CurrentOrderPage.back')}</p>
			</NavLink>
		</div>
	);
});
