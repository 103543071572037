import {useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

export enum BubbleMessage {
	NULL,
	RESTAURANT_CLOSED = 'RESTAURANT_CLOSED',
	ORDERS_UNAVAILABLE = 'ORDERS_UNAVAILABLE',
	REQUIRED_PARTS = 'REQUIRED_PARTS',
}

interface BubbleMessageProps {
	restaurantIsOpen: boolean;
	orderingDisabled: boolean;
	hasRequiredParts: boolean;
}

interface TranslatedMessage {
	RESTAURANT_CLOSED: string;
	ORDERS_UNAVAILABLE: string;
	REQUIRED_PARTS: string;
}

interface BubbleMessageFunctions {
	warningType: () => BubbleMessage;
	translatedMessage: TranslatedMessage;
	bubbleMessage: BubbleMessage;
	setBubbleMessage: (value: BubbleMessage) => void;
}

export const useBubbleMessage = ({
	restaurantIsOpen,
	orderingDisabled,
	hasRequiredParts,
}: BubbleMessageProps): BubbleMessageFunctions => {
	const {translate} = useTranslate();
	const [message, setMessage] = useState<BubbleMessage>(BubbleMessage.NULL);

	const warningType = (): BubbleMessage => {
		if (!restaurantIsOpen) {
			return BubbleMessage.RESTAURANT_CLOSED;
		}
		if (orderingDisabled) {
			return BubbleMessage.ORDERS_UNAVAILABLE;
		}
		if (restaurantIsOpen && hasRequiredParts) {
			return BubbleMessage.REQUIRED_PARTS;
		}
		return BubbleMessage.NULL;
	};

	const translatedMessage = {
		RESTAURANT_CLOSED: translate('ProductPage.addToCartButton.RESTAURANT_CLOSED'),
		ORDERS_UNAVAILABLE: translate('ProductPage.addToCartButton.ORDERS_UNAVAILABLE'),
		REQUIRED_PARTS: translate('ProductPage.addToCartButton.REQUIRED_PARTS'),
	};

	const bubbleMessage = message;
	const setBubbleMessage = (value: BubbleMessage): void => setMessage(value);

	return {warningType, translatedMessage, bubbleMessage, setBubbleMessage};
};
