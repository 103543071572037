export const handleSelectedCategory = (
  currentId: number,
  currentPosition: number,
  currentNavigation?: number
) => {
  const currentClass = { className: "current txt-bold" };

  if (currentNavigation && currentNavigation !== 0) {
    return currentNavigation === currentId && currentClass;
  } else {
    return currentPosition === 0 && currentClass;
  }
};
