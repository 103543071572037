import {observer} from 'mobx-react-lite';
import React from 'react';

interface PaymentCheckboxProps {
	defaultChecked: boolean;
	label: string;
	name: string;
	className: string;
	onChange: () => void;
	isRequired?: boolean;
}

export const PaymentCheckbox = observer((props: PaymentCheckboxProps) => {
	const {defaultChecked, label, className, name, onChange, isRequired} = props;

	return (
		<div className="checkbox-container">
			<label className="form-input__label">
			<input
				type="checkbox"
				className={className}
				name={name}
				defaultChecked={defaultChecked}
				onChange={onChange}
				required={isRequired ? true : false}
			/>
			{label}</label>
		</div>
	);
});
