import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

// import legals from './theoutpost-terms-of-use.json';

// The observer is necessary here for the translation
export const ConsumerGoodsTerms = observer(() => {
	document.title = "Consumer goods, services and digital terms";

	return (
		<main className="legals">
			<div className="legals__container theme-container">
				<h1 className="legals__title">Consumer goods, services and digital terms</h1>
				<div className="legals__body">
					<div>
					<div>
					<h2>Where to find information about us and our products</h2>
					<p>You can find everything you need to know about us, Detroit Deep Pan Limited, and our products on our website or from our sales staff before you order. We also confirm the key information to you in writing before or after you order, either by email or on paper.</p>
					<h2>When you buy from us you are agreeing that:</h2>
					<ul>
						<li>We only accept orders when we've checked them.</li>
						<li>Sometimes we reject orders.</li>
						<li>We charge you when [you order OR we accept your order OR we supply your product].</li>
						<li>We pass on increases in VAT.</li>
						<li>We're not responsible for delays outside our control.</li>
						<li>Products can vary slightly from their pictures.</li>
						<li>You have rights if there is something wrong with your product.</li>
						<li>We can change products and these terms.</li>
						<li>We can suspend supply (and you have rights if we do).</li>
						<li>We don't compensate you for all losses caused by us or our products.</li>
						<li>We use your personal data as set out in our Privacy Notice.</li>
						<li>You have several options for resolving disputes with us.</li>
						<li>Other important terms apply to our contract.</li>
					</ul>
					<h2>We only accept orders when we've checked them</h2>
					<p>We contact you to confirm we've received your order and then we contact you again to confirm we've accepted it.</p>
					<h2>Sometimes we reject orders</h2>
					<p>Sometimes we reject orders, for example, because a product is unexpectedly out of stock or because the product was mispriced by us. When this happens, we let you know as soon as possible and refund any sums you have paid.</p>
					<h2>We charge you when we accept your order</h2>
					<p>You will receive your order once it is ready, and we have received payment in full.</p>
					<h2>We pass on increases in VAT</h2>
					<p>If the rate of VAT changes between your order date and the date we supply the product, we adjust the rate of VAT that you pay, unless you have already paid in full before the change in the rate of VAT takes effect.</p>
					<h2>We're not responsible for delays outside our control </h2>
					<p>If our supply of your product is delayed by an event outside our control we contact you as soon as possible to let you know and do what we can to reduce the delay. As long as we do this, we won't compensate you for the delay, but if the delay is likely to be substantial you can receive a refund for any products you have paid for in advance, but not received.</p>
					<h2>Products can vary slightly from their pictures </h2>
					<p>A product's true colour may not exactly match that shown on your device or in our marketing or its packaging may be slightly different. Because our products are handmade, all sizes, weights, capacities, dimensions and measurements indicated on our website can vary.</p>
					<h2>You have rights if there is something wrong with your product</h2>
					<p>If you think there is something wrong with your product, you must either bring it into the store you have purchased the product from or contact our Customer Service Team: <a href="mailto:hello@littlecaesarsfeedback.co.uk">hello@littlecaesarsfeedback.co.uk</a>. We honour our legal duty to provide you with products that are as described to you on our website and that meet all the requirements imposed by law. These are subject to certain exceptions. For detailed information please visit the Citizens Advice website www.citizensadvice.org.uk. Remember too that You have several options for resolving disputes with us.</p>
					<h2>We can change products and these terms</h2>
					<p>Changes we can always make. We can always change a product:</p>
					<ul>
						<li>to reflect changes in relevant laws and regulatory requirements;</li>
						<li>to make minor technical adjustments and improvements, for example to address a security threat. and</li>
						<li>to update digital content, provided that the digital content always matches the description of it that we provided to you before you bought it.</li>
					</ul>
					<p>Changes we can only make if we give you notice and an option to terminate. We can also make the following types of change to the product or these terms, but if we do so we'll notify you and you can then contact our Customer Service Team: <a href="mailto:hello@littlecaesarsfeedback.co.uk">hello@littlecaesarsfeedback.co.uk</a> to end the contract before the change takes effect and receive a refund for any products you've paid for in advance, but not received.</p>
					<h2>We don't compensate you for all losses caused by us or our products</h2>
					<p>We're responsible for losses you suffer caused by us breaking this contract unless the loss is:</p>
					<ul>
						<li>Unexpected. It was not obvious that it would happen and nothing you said to us before we accepted your order meant we should have expected it (so, in the law, the loss was unforeseeable).</li>
						<li>Caused by a delaying event outside our control. As long as we have taken the steps set out in the section We're not responsible for delays outside our control.</li>
						<li>Avoidable. Something you could have avoided by taking reasonable action.</li>
					</ul>
					<h2>We use your personal data as set out in our Privacy Notice</h2>
					<p>How we use any personal data you give us is set out in our Privacy Notice:</p>
					<h2>You have several options for resolving disputes with us</h2>
					<p><b>Our complaints policy.</b> Our Customer Service Team: <a href="mailto:hello@littlecaesarsfeedback.co.uk">hello@littlecaesarsfeedback.co.uk</a> will do their best to resolve any problems you have with us or our products.</p>
					<p><b>Resolving disputes without going to court.</b> Alternative dispute resolution is an optional process where an independent body considers the facts of a dispute and seeks to resolve it, without you having to go to court.</p>
					<p><b>You can go to court.</b> These terms are governed by English law and wherever you live you can bring claims against us in the English courts. If you live in Wales, Scotland or Northern Ireland, you can also bring claims against us in the courts of the country you live in. We can claim against you in the courts of the country you live in.</p>
					<h2>Other important terms apply to our contract</h2>
					<p><b>Nobody else has any rights under this contract.</b> This contract is between you and us. Nobody else can enforce it and neither of us will need to ask anybody else to sign-off on ending or changing it.</p>
					<p><b>If a court invalidates some of this contract, the rest of it will still apply.</b> If a court or other authority decides that some of these terms are unlawful, the rest will continue to apply.</p>
					</div>
					</div>
				</div>
			</div>
		</main>
	);
});
