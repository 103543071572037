import {NavigationKey} from '@acrelec-cloud/apico-cdk';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {NavLink} from 'react-router-dom';

import {useStore} from 'src/contexts/store.context';

import {handleSelectedCategory} from '../selected-category.util';

export const CategoryNavigationItem = observer((props: NavigationKey) => {
  const {content, position} = props;
  const {
    navigation: {currentNavigation},
  } = useStore();

  return (
    <li className="category-item">
      <NavLink
        to={`/order/${content.id}`}
        {...handleSelectedCategory(content.id, position, currentNavigation && currentNavigation)}
      >
        {content.name}
      </NavLink>
    </li>
  );
});
