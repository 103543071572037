import {RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {Modal} from 'src/components/Modal/Modal';
import {PickRestaurantModal} from 'src/components/Restaurant/RestaurantItem';
import {useStore} from 'src/contexts/store.context';

interface OrderDisabledModalProps {
	status: PickRestaurantModal;
	setStatus: (status: PickRestaurantModal) => void;
	restaurantInfo: RestaurantObject;
}

export const ResetBasketModal = observer(
	({status, setStatus, restaurantInfo}: OrderDisabledModalProps) => {
		const {translate} = useTranslate();
		const history = useHistory();
		const displayThisModal = useMemo(() => status === PickRestaurantModal.RESET_BASKET, [status]);
		const [open, setOpen] = useState<boolean>(displayThisModal ? true : false);

		const {
			restaurant: {setCurrentRestaurant},
			basket: {resetBasket},
		} = useStore();

		useEffect(() => setOpen(displayThisModal ? true : false), [displayThisModal]);

		const clearBasketAndValidRestaurant = (): void => {
			resetBasket();
			setCurrentRestaurant(restaurantInfo);
			setStatus(PickRestaurantModal.NULL);
		};

		const handleValidate = () => {
			return restaurantInfo.authenticationRequired
				? history.push(`/restaurants/${restaurantInfo.slug}/authenticate`)
				: clearBasketAndValidRestaurant();
		};

		return (
			<Modal open={open} onClose={() => setStatus(PickRestaurantModal.NULL)}>
				<div className="reset-basket-modal">
					<p>{translate('ResetBasketModalComponent.text')}</p>
					<div>
						<button
							className="home-main__btn btn__secondary--inverse btn-padding"
							onClick={handleValidate}>
							{translate('App.yes')}
						</button>
						<button
							className="home-main__btn btn__secondary btn-padding"
							onClick={() => setStatus(PickRestaurantModal.NULL)}>
							{translate('App.no')}
						</button>
					</div>
				</div>
			</Modal>
		);
	},
);
